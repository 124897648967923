import { useState } from "react";
import CustomerFinancingCard from "@/components/refix/CustomerFinancingCard";
import DealerLoanApplications from "@/components/DealerLoanApplications";
import { DealerFinancingLocked } from "@/assets/svgs";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import storage from "@l/storage";
import {
  IDealerSummary,
  IDealerLimit,
  IDealerLimitResult,
} from "@/interface/dealer";
import { Button, notification, message } from "antd";
import styled from "styled-components";
import queryString from "query-string";
import {
  DEALER_FINANCE_COUNTRIES,
  LIMIT_REQUEST_STATUS,
} from "@/utils/finance-status";
import tw from "tailwind-styled-components";
import { Loader } from "./auth/styles/loader";
import { useTranslation } from "react-i18next";
import DealerName from "@/components/DealerName";
import FinancingStepTwo from "@/layouts/dealer-financing/FinancingStepTwo";
import FinancingStepOne from "@/layouts/dealer-financing/FinancingStepOne";
import FinancingOptionsCard from "@/layouts/dealer-financing/FinancingOptionsCard";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

const CustomLoading = tw.div`flex items-center justify-center w-full min-h-[70vh] h-auto`;
const CustomButon = styled.div`
  button {
    &:disabled {
      background: #b1b4b5;
    }
  }
`;

const DealerFinancing = () => {
  const user = storage.getUser();
  const navigate = useNavigate();
  const { search } = useLocation();
  const country = storage.getCountry();
  const query: any = queryString.parse(search);
  const queryClient = useQueryClient();
  const [dealerLimit, setDealerLimit] = useState<IDealerLimit>();
  const [dealerSummary, setDealerSummary] = useState<
    IDealerSummary | undefined
  >();
  const [activePage, setActivePage] = useState<IActivePage>({
    currentPage: 1,
    pageSize: 10,
  });
  const [searchParams] = useSearchParams();
  const dealerId = searchParams.get("dealer_id");
  const [fetchDealerLoans, setFetchDealerLoans]: any = useState([]);
  const [value, setValue] = useState<string | number>("loanApplications");
  const [api, contextHolder] = notification.useNotification();
  const [loanLimitData, setLoanLimitData]: any = useState({});
  const { t } = useTranslation("dealer-gamification");
  const isDealerFinancingEnabled = DEALER_FINANCE_COUNTRIES.includes(country);
  const isAssumedUser = storage.getAssumedUser();
  const isFranchise = storage.utilities.isFranchise();
  const limitInitialState = dealerLimit?.results?.length === 0;

  const STATUSES = [
    LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW,
    LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED,
    LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED,
  ];

  const _STATUSES = [
    LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED,
    LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED,
    LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW,
    LIMIT_REQUEST_STATUS.LIMIT_REQUEST_COMPLETED,
    LIMIT_REQUEST_STATUS.LIMIT_REQUEST_NOT_COMPLETED,
  ];

  const hasLimitActivated = dealerLimit?.results?.some(
    (item) => item?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED
  );

  const { isError: isDealerSummaryError } = useQuery({
    queryKey: ["fetch_dealer_summary"],
    queryFn: () => {
      const franchiseId = user?.company?.id;
      return client.get(
        `/v1/franchise/${franchiseId || dealerId}/dealer-tiers/summary`
      );
    },
    onSuccess: (res: any) => {
      const parsedResponseData: IDealerSummary = JSON.parse(res?.data);
      setDealerSummary(parsedResponseData);
    },
    onError: (err: any) => {
      const parsedErr = JSON.parse(err.response.data);
      message.error(parsedErr.message ? parsedErr.message : parsedErr.error);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: dealerLimitLoading, isFetching: dealerLimitFetch } =
    useQuery({
      queryKey: ["fetch_dealer_limit"],
      queryFn: () => {
        return client.get(`/v1/dealer/${user?.company?.id || dealerId}`);
      },
      onSuccess: (res: any) => {
        const parsedResponseData: IDealerLimit = JSON.parse(res?.data);
        setDealerLimit(parsedResponseData);
        queryClient.invalidateQueries({
          queryKey: ["fetch_notifications"],
        });
      },
      retry: false,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    });

  const { isFetching: dealerFinancingFetch } = useQuery({
    queryKey: [
      "fetch_dealer_financing_table_3",
      query,
      user?.company?.id || dealerId,
      value,
    ],
    queryFn: () => {
      const params = new URLSearchParams({
        current_page: activePage.currentPage.toString(),
        page_size: activePage.pageSize.toString(),
        status: value === "loanApplications" ? "" : "DISBURSED",
        dealer_id: isFranchise ? user?.company?.id : dealerId,
        ...query,
      });
      return client.get(`/v1/dealer/loans`, { params });
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setFetchDealerLoans(parsedLoans);
      queryClient.invalidateQueries({
        queryKey: ["fetch_notifications"],
      });
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["loan_limit_utility", user?.company?.id || dealerId],
    queryFn: () => {
      return client.get(
        `/v1/dealer/loan-utility/${user?.company?.id || dealerId}`
      );
    },
    onSuccess: (res: any) => {
      const parsedLoans = JSON.parse(res?.data);
      setLoanLimitData(parsedLoans);
    },
    enabled: !!user?.company?.id || !!dealerId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const resumeApplicationLink = (data: IDealerLimitResult) => {
    return dealerLimit?.results && dealerLimit?.results?.length > 1
      ? `/dealer/loan-limit?loan_limit=${data?.id}&form_type=enhancement&dealer_id=${dealerId}`
      : `/dealer/loan-limit?loan_limit=${data?.id}&dealer_id=${dealerId}`;
  };

  const toLoanLimitForm = (limit: IDealerLimitResult) => {
    if (
      !dealerLimitLoading &&
      limit?.status === "limit_request_not_completed"
    ) {
      navigate(resumeApplicationLink(limit));
    } else {
      navigate(`/dealer/loan-limit?form_type=new&dealer_id=${dealerId}`);
    }
  };

  const toCreditLimit = (dealerLimit: IDealerLimitResult) => {
    navigate(
      `/dealer/credit-limit?limit_id=${dealerLimit?.id}&dealer_id=${dealerId}`
    );
  };

  const renderCreditLimitButton = (dealerLimit: IDealerLimitResult) => {
    return (
      <CustomButon>
        <Button
          onClick={() => toCreditLimit(dealerLimit)}
          className="bg-[#FFB619] py-1 rounded-full border-none flex items-center justify-center min-w-[150px] w-auto px-4 h-[1.895rem]"
        >
          <span className="text-[#30345E] capitalize text-sm lg:text-base font-semibold">
            {t("activate-credit-limit")}
          </span>
        </Button>
      </CustomButon>
    );
  };

  const getApplicationButton = (limit: IDealerLimitResult) => {
    return (
      <CustomButon>
        <Button
          onClick={() => toLoanLimitForm(limit)}
          disabled={!!isAssumedUser}
          className="bg-[#FFB619] px-3 py-1 rounded-full border-none flex items-center justify-center min-w-[180px] w-auto h-[1.995rem] mt-3 sm:mt-0"
        >
          <span className="text-[#30345E] capitalize text-base font-semibold">
            {limit?.status === LIMIT_REQUEST_STATUS.LIMIT_REQUEST_NOT_COMPLETED
              ? t("resume-application")
              : t("start-application")}
          </span>
        </Button>
      </CustomButon>
    );
  };

  const renderLimitIncreaseButton = (dealerLimit: IDealerLimitResult) => {
    return (
      <button
        onClick={() =>
          navigate(
            `/dealer/loan-limit?form_type=enhancement&finance_id=${dealerLimit?.id}&dealer_id=${dealerId}`
          )
        }
        disabled={!!isAssumedUser}
        className="bg-[#FFB619] py-1 text-[#30345E] capitalize text-sm lg:text-base font-semibold rounded-full border-none flex items-center justify-center min-w-[150px] w-auto px-4 h-[1.895rem] disabled:text-black/25 disabled:bg-[#b1b4b5] disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
      >
        {t("Increase Limit")}
      </button>
    );
  };

  const renderButtonContent = (limit: IDealerLimitResult) => {
    const {
      LIMIT_ASSIGNED,
      LIMIT_SIGNED_DOC_UPLOADED,
      LIMIT_REQUEST_COMPLETED,
      LIMIT_ACTIVATED,
    } = LIMIT_REQUEST_STATUS;

    const isDeclinedStatus: any = limit?.loanLimits?.find(
      (item) => item.status === "DECLINED"
    );

    if (
      limit?.status === LIMIT_ACTIVATED &&
      dealerLimit?.results?.length === 1
    ) {
      return renderLimitIncreaseButton(limit);
    } else if (
      limit?.status === LIMIT_ASSIGNED ||
      (limit?.status === LIMIT_SIGNED_DOC_UPLOADED &&
        isDeclinedStatus?.actionRequired === "REUPLOAD") ||
      (limit?.status === LIMIT_SIGNED_DOC_UPLOADED &&
        isDeclinedStatus?.actionRequired === "SIGN")
    ) {
      return renderCreditLimitButton(limit);
    } else if (!STATUSES.includes(limit?.status as LIMIT_REQUEST_STATUS)) {
      if (limit?.status === LIMIT_REQUEST_COMPLETED) {
        return "";
      } else {
        return getApplicationButton(limit);
      }
    }
  };

  const renderEligibilityMessage = (): string => {
    return DEALER_FINANCE_COUNTRIES.includes(country)
      ? t("you-are-eligible")
      : t("you-are-not-eligible");
  };

  const renderFinancingCard = (elm: IDealerLimitResult) => {
    return (
      <div>
        <div className="font-bold custom-font-bold text-xl lg:text-2xl 3xl:text-center lg:text-left text-[#30345E]">
          {t("available-financing-options")}
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-2 grid-rows-auto w-full max-w-full lg:max-w-[100%] my-0 mx-auto lg:gap-x-4 gap-x-2 gap-y-4 mt-4">
          <FinancingOptionsCard
            text={t("import-financing")}
            body={t(
              "worry-less-about-how-to-import-that-dream-car-for-your-customer"
            )}
            link={`/dealer/financing/loan?form_type=new&product_type=import&dealer_id=${dealerId}`}
            dealerLimit={elm}
          />
          <FinancingOptionsCard
            text={t("local-financing")}
            body={t(
              "get-your-customer-that-car-they-handpicked-even-if-you-do-not-own-it"
            )}
            link={`/dealer/financing/loan?form_type=new&product_type=local&dealer_id=${dealerId}`}
            dealerLimit={elm}
          />
          <FinancingOptionsCard
            text={t("duty-financing")}
            body={t("dont-wait-till-you-have-the-funds-to-clear-your-shipment")}
            link={`/dealer/financing/loan?form_type=new&product_type=duty&dealer_id=${dealerId}`}
            dealerLimit={elm}
          />
          {/* <FinancingOptionsCard
            text={t("White Label financing")}
            body={t("dont-wait-till-you-have-the-funds-to-clear-your-shipment")}
            link="/dealer/financing/duty-financing?form_type=new"
            dealerLimit={elm}
          /> */}
          {/* <FinancingOptionsCard
            text={t("repo-financing")}
            body={t("explore-our-inventory-of-used-cars")}
            link="/dealer/all-deals"
            dealerLimit={elm}
          /> */}
        </div>
      </div>
    );
  };

  const renderInitialCustomerCard = () => {
    return (
      <CustomerFinancingCard
        icon={<DealerFinancingLocked />}
        isDealerFinancingEnabled={isDealerFinancingEnabled}
        api={api}
        resultArr={dealerLimit?.results ?? []}
      >
        <div className="px-4 pb-4 pt-1">
          {DEALER_FINANCE_COUNTRIES.includes(country) && (
            <CustomButon>
              <Button
                onClick={() =>
                  navigate(
                    `/dealer/loan-limit?form_type=new&dealer_id=${dealerId}`
                  )
                }
                disabled={!!isAssumedUser}
                className="bg-[#FFB619] px-3 py-1 rounded-full border-none flex items-center justify-center min-w-[180px] w-auto h-[1.995rem] mt-3 sm:mt-0"
              >
                <span className="text-[#30345E] capitalize text-base font-semibold">
                  {t("start-application")}
                </span>
              </Button>
            </CustomButon>
          )}
        </div>
      </CustomerFinancingCard>
    );
  };

  if (dealerLimitFetch) {
    return (
      <CustomLoading>
        <Loader variant={"secondary"} />
      </CustomLoading>
    );
  }

  return (
    <main>
      {contextHolder}

      <div>
        <div className="mb-4 mt-2">
          <DealerName
            props={{
              dealerName: dealerSummary?.dealership! || user?.company?.name,
              activeDate: dealerSummary?.onboardingDate!,
              id: dealerSummary?.id!,
            }}
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <div className="font-outfitBold custom-font-bold font-bold text-xl lg:text-2xl text-[#30345E]">
            {t("my-financing")}
          </div>
          <div>
            <p className="font-semibold text-xs lg:text-base text-[#30345E]">
              {renderEligibilityMessage()}
            </p>
          </div>
        </div>

        {isDealerFinancingEnabled ? (
          <div>
            {limitInitialState ? (
              renderInitialCustomerCard()
            ) : (
              <div className="flex flex-col lg:flex-row gap-4">
                {dealerLimit?.results?.map((elem: IDealerLimitResult) => (
                  <CustomerFinancingCard
                    key={elem?.id}
                    icon={<DealerFinancingLocked />}
                    dealerLimit={elem}
                    dealerSummaryErr={isDealerSummaryError}
                    dealerSummary={dealerSummary}
                    limitStatuses={STATUSES}
                    isDealerFinancingEnabled={isDealerFinancingEnabled}
                    loanLimitData={loanLimitData}
                    api={api}
                    resultArr={dealerLimit?.results}
                  >
                    <div className="px-4 pb-4 pt-1">
                      {DEALER_FINANCE_COUNTRIES.includes(country) &&
                        renderButtonContent(elem)}
                    </div>
                  </CustomerFinancingCard>
                ))}
              </div>
            )}
          </div>
        ) : (
          renderInitialCustomerCard()
        )}

        {isDealerFinancingEnabled && (
          <>
            {fetchDealerLoans && hasLimitActivated && (
              <div className="pt-8 lg:pt-12">
                <DealerLoanApplications
                  activePage={activePage}
                  setActivePage={setActivePage}
                  query={query}
                  fetchDealerLoans={fetchDealerLoans}
                  dealerFinancingLoading={dealerFinancingFetch}
                  value={value}
                  setValue={setValue}
                />
              </div>
            )}
          </>
        )}

        <div className="mt-8">
          {isDealerFinancingEnabled ? (
            <>
              {limitInitialState ? (
                <FinancingStepOne />
              ) : (
                <>
                  {dealerLimit?.results && dealerLimit?.results?.length > 1 ? (
                    <>
                      {dealerLimit?.results?.map((elm: IDealerLimitResult) => (
                        <div key={elm?.id}>
                          {_STATUSES.includes(
                            elm?.status as LIMIT_REQUEST_STATUS
                          ) &&
                          elm?.status ===
                            LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED ? (
                            <FinancingStepTwo />
                          ) : (
                            elm?.status ===
                              LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED &&
                            renderFinancingCard(elm)
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {dealerLimit?.results?.map((elm: IDealerLimitResult) => (
                        <div key={elm?.id}>
                          {_STATUSES.includes(
                            elm?.status as LIMIT_REQUEST_STATUS
                          ) ? (
                            <FinancingStepTwo />
                          ) : (
                            elm?.status ===
                              LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED &&
                            renderFinancingCard(elm)
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <FinancingStepOne />
          )}
        </div>
      </div>
    </main>
  );
};

export default DealerFinancing;
