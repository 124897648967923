import styled from "styled-components";
import { device } from "@/utils/device";

export const GeneralEmptyState = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  .content {
    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media ${device.mobileL} {
      width: 100%;
    }
    img {
      margin: 0 auto;
    }
    .sub-heading {
      color: #6b7280;
      font-weight: 400;
      font-size: 18px;
      @media ${device.mobileL} {
        font-size: 14px;
      }
    }
  }
`;

export const ApplicationLayout = styled.div`
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  /* padding: 16px; */
  min-height: 100vh;
  height: auto;

  /* .loans-header-web {
    display: flex;
  } */
  .inventory-pagination {
    padding: 24px 0 36px;
    text-align: center;
  }
`;

export const CustomDealerLayout = styled.div`
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  /* min-height: 100vh; */
  height: auto;
  @media ${device.mobileL} {
    background: transparent;
    border: none;
  }
  @media ${device.tablet} {
    background: transparent;
    border: none;
  }
  @media ${device.laptop} {
    background: transparent;
    border: none;
  }
  .inventory-pagination {
    padding: 24px 0 36px;
    text-align: center;
  }
`;

export const ParentTabHeader = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  .title {
    color: #000000;
    font-weight: bolder;
    font-size: 20px;
    @media ${device.mobileL} {
      padding: 12px;
    }
  }
  .ant-segmented {
    margin-right: 0px;
  }
`;

export const MobileHeader = styled.div`
  /* padding: 16px; */
  /* border-bottom: 1px solid #e5e7eb; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header {
    color: #000000;
    font-weight: bolder;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  @media ${device.mobileL} {
    /* padding: 12px; */
  }
`;

export const AppTabContainer = styled.div`
  padding: 16px 0px;
  @media ${device.mobileL} {
    padding: 0px 0px;
  }
  .ant-tabs .ant-tabs-tab {
    padding: 12px 16px;
    font-size: 14px;
    color: #7f83a8;
    font-weight: 600;
    @media ${device.mobileL} {
      padding: 12px 6px;
    }
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #262a4b;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: #ffb619;
  }
  .ant-segmented {
    background: #f8fafc;
    border-radius: 40px;
    @media ${device.mobileL} {
      margin-right: 0px;
    }
  }
  .ant-segmented .ant-segmented-item::after {
    border-radius: 24px;
    background: 0 0;
  }
  .ant-segmented .ant-segmented-item-selected {
    background-color: #ffb619;
    border-radius: 24px;
    font-weight: 600;
    font-size: 14px;
    color: #30345e;
  }

  .ant-segmented .ant-segmented-item-label {
    font-weight: 600;
    font-size: 14px;
  }

  .change-car {
    font-size: 14px;
    font-weight: 600;
    color: rgb(48, 52, 94) !important;
    background: rgb(255, 182, 25);
    border-radius: 44px;
    min-width: 136px;
    width: auto;
    height: 32px;
    border-color: rgb(255, 182, 25) !important;
    @media ${device.mobileL} {
      min-width: auto;
      width: 100%;
      height: auto;
      font-size: 12px;
      padding: 5px 5px;
    }
    &:disabled {
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
    }
  }
`;
export const DealerAppTabContainer = styled.div`
  padding: 16px 0px;
  @media ${device.mobileL} {
    padding: 0px 0px;
  }
  .ant-tabs .ant-tabs-tab {
    padding: 12px 150px;
    font-size: 14px;
    color: #7f83a8;
    font-weight: 600;
    @media ${device.mobileL} {
      padding: 12px 6px;
    }
    @media ${device.laptop} {
      padding: 12px 48px;
    }
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #262a4b;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: #ffb619;
  }
  .ant-tabs .ant-tabs-content {
    /* padding: 0px 16px; */
    /* border-bottom: 1px solid #e5e7eb; */
    /* @media ${device.mobileL} {
      padding: 0px 0px;
    } */
  }
  .ant-segmented {
    background: #f8fafc;
    border-radius: 40px;
    /* margin-right: 16px; */
    @media ${device.mobileL} {
      margin-right: 0px;
    }
  }
  .ant-segmented .ant-segmented-item::after {
    border-radius: 24px;
    background: 0 0;
  }
  .ant-segmented .ant-segmented-item-selected {
    background-color: #ffb619;
    border-radius: 24px;
    font-weight: 600;
    font-size: 14px;
    color: #30345e;
  }

  .ant-segmented .ant-segmented-item-label {
    font-weight: 600;
    font-size: 14px;
  }

  .change-car {
    font-size: 14px;
    font-weight: 600;
    color: rgb(48, 52, 94) !important;
    background: rgb(255, 182, 25);
    border-radius: 44px;
    min-width: 136px;
    width: auto;
    height: 32px;
    border: 0;
    @media ${device.mobileL} {
      min-width: auto;
      width: 100%;
      height: auto;
      font-size: 12px;
      padding: 5px 5px;
    }
  }

  .proceed-btn {
    height: 48px;
    background: #ffb619;
    border-radius: 60px;
    border: 0;
    span {
      color: #30345e;
      font-weight: 800;
      font-size: 14px;
      @media ${device.mobileL} {
        font-size: 12px;
      }
    }
    &:hover {
      text-decoration: none;
      background-color: rgb(255, 182, 25);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
        rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    }
  }
`;
export const HistoryTabContainer = styled.div`
  // padding: 16px 0px;
  background-color: #fff;
  @media ${device.mobileL} {
    padding: 0px 0px;
  }
  .ant-tabs-nav-wrap {
    height: 3.5rem;
  }
  .ant-tabs .ant-tabs-tab {
    padding: 12px 16px;
    font-size: 14px;
    color: #7f83a8;
    font-weight: 600;
    letter-spacing: 0.0625rem;
    @media ${device.mobileL} {
      padding: 12px 6px;
    }
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #262a4b;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: #ffb619;
  }
  .ant-tabs .ant-tabs-content {
    /* padding: 0px 16px; */
    /* border-bottom: 1px solid #e5e7eb; */
    /* @media ${device.mobileL} {
      padding: 0px 0px;
    } */
  }
  .ant-segmented {
    background: #f8fafc;
    border-radius: 40px;
    /* margin-right: 16px; */
    @media ${device.mobileL} {
      margin-right: 0px;
    }
  }
  .ant-segmented .ant-segmented-item::after {
    border-radius: 24px;
    background: 0 0;
  }
  .ant-segmented .ant-segmented-item-selected {
    background-color: #ffb619;
    border-radius: 24px;
    font-weight: 600;
    font-size: 14px;
    color: #30345e;
  }

  .ant-segmented .ant-segmented-item-label {
    font-weight: 600;
    font-size: 14px;
  }

  .ant-tabs-content-holder {
    padding: 0 12px 1rem;
  }

  .change-car {
    font-size: 14px;
    font-weight: 600;
    color: rgb(48, 52, 94) !important;
    background: rgb(255, 182, 25);
    border-radius: 44px;
    min-width: 136px;
    width: auto;
    height: 32px;
    border: 0;
    @media ${device.mobileL} {
      min-width: auto;
      width: 100%;
      height: auto;
      font-size: 12px;
      padding: 5px 5px;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e7eb;
  padding: 0px 16px 16px;
  @media ${device.mobileL} {
    padding: 0px 12px 16px;
  }
  .filter-field {
    display: flex;
    align-items: center;
    gap: 18px;
    .mobile-input-field {
      display: block;
      @media ${device.mobileL} {
        display: none;
      }
    }
    .input-field {
      .label {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .ant-input-affix-wrapper {
        border-radius: 4px;
        height: 48px;
        width: 320px;
        background: #f8fafc;
        @media ${device.mobileL} {
          width: 290px;
        }
        @media ${device.mobileM} {
          width: 250px;
        }
      }
      .ant-input {
        background: #f8fafc;
      }
      .ant-picker {
        height: 48px;
        border-radius: 4px;
        background: #f8fafc;
      }
      .ant-select {
        height: 48px;
        min-width: 200px;
        width: 100%;
        .ant-select-selector {
          height: 48px;
          border-radius: 4px;
          background: #f8fafc;
          align-items: center;
          .ant-select-selection-search-input {
            padding-top: 14px;
          }
        }
      }
    }
  }
`;

export const SearchField = styled.div`
  /* width: 100%; */
  .ant-input {
    background: #f8fafc;
  }
  .ant-input-affix-wrapper {
    border-radius: 4px;
    height: 48px;
    /* width: 320px; */
    background: #f8fafc;
    /* @media ${device.mobileL} {
      width: 290px;
    } */
    /* @media ${device.mobileM} {
      width: 250px;
    } */
  }
  .label {
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const AddInputModal = styled.div`
  .ant-input {
    background: #f8fafc;
    height: 48px;
    border-radius: 4px;
  }
  label {
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const DateField = styled.div`
  width: 100%;
  .label {
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .ant-picker {
    height: 48px;
    border-radius: 4px;
    background: #f8fafc;
  }
`;

export const SelectionField = styled.div``;

export const CustomFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 16px;
  border-bottom: 1px solid #e5e7eb;
  @media ${device.mobileL} {
    padding: 0px 12px 16px;
  }
  .filter-field {
    display: flex;
    align-items: center;
    gap: 18px;
    .mobile-input-field {
      display: block;
      @media ${device.mobileL} {
        display: none;
      }
    }
    .input-field {
      .label {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .ant-input-affix-wrapper {
        border-radius: 4px;
        height: 48px;
        width: 320px;
        background: #f8fafc;
        @media ${device.mobileL} {
          width: 290px;
        }
        @media ${device.mobileM} {
          width: 250px;
        }
      }
      .ant-input {
        background: #f8fafc;
      }
      .ant-picker {
        height: 48px;
        border-radius: 4px;
        background: #f8fafc;
      }
      .ant-select {
        height: 48px;
        min-width: 200px;
        width: 100%;
        .ant-select-selector {
          height: 48px;
          border-radius: 4px;
          background: #f8fafc;
          align-items: center;
          .ant-select-selection-search-input {
            padding-top: 14px;
          }
        }
      }
    }
  }
`;

export const NewFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
  @media ${device.mobileL} {
    padding: 0px 0px 14px;
  }
  .new-filter-field {
    @media ${device.mobileL} {
      width: 100%;
    }
  }
  .filter-field {
    display: flex;
    align-items: center;
    gap: 18px;
    .mobile-input-field {
      display: block;
      @media ${device.mobileL} {
        display: none;
      }
      @media ${device.tablet} {
        display: none;
      }
    }
    .input-field {
      width: 100%;
      .label {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .ant-input-affix-wrapper {
        border-radius: 4px;
        height: 48px;
        width: 320px;
        background: #f8fafc;
        @media ${device.mobileL} {
          /* width: 290px; */
          width: 100%;
        }
        @media ${device.mobileM} {
          /* width: 250px; */
        }
      }
      .ant-input {
        background: #f8fafc;
      }
      .ant-picker {
        height: 48px;
        border-radius: 4px;
        background: #f8fafc;
      }
      .ant-select {
        height: 48px;
        min-width: 200px;
        width: 100%;
        .ant-select-selector {
          height: 48px;
          border-radius: 4px;
          background: #f8fafc;
          align-items: center;
          .ant-select-selection-search-input {
            padding-top: 14px;
          }
        }
      }
    }
  }
`;

export const CInputNumber = styled.div`
  .ant-input-number .ant-input-number-input {
    width: 100%;
    height: 44px;
    background: #f8fafc;
  }
`;

export const FilterLoanValue = styled.div`
  position: relative;
  .header {
    color: #30345e !important;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    cursor: pointer;
    padding-left: 0px;
    padding-right: 0px;
  }
  .filter-loan-value {
    /* position: absolute; */
    top: 14px;
    right: 0;
    z-index: 500;
    .filter-loan-container {
      background: #fff;
      /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); */
      border-radius: 16px;
      /* width: 480px; */
      height: auto;
      /* padding: 24px 16px 40px; */
      .filter-calculator {
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          .name {
            color: #000000;
            font-weight: 600;
            font-size: 20px;
          }
        }
        .min-max-loan-value {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 24px;
          .label {
            color: #202020;
            font-weight: 600;
            font-size: 14px;
          }
          .ant-input-number {
            background: #f8fafc;
            border: 1px solid #e5e7eb;
            border-radius: 4px;
            width: 100%;
            height: 44px;
          }
          input {
            height: 48px;
          }
        }
        .range-value {
          margin-bottom: 72px;
          .ant-slider .ant-slider-track {
            background-color: #30345e;
          }
        }
        .filter-button-container {
          display: flex;
          gap: 14px;
          .reset {
            color: #30345e;
            font-weight: 600;
            font-size: 14px;
            border: 1px solid #30345e;
            background: #fff;
            box-shadow: none;
            border-radius: 60px;
            width: 100%;
            height: 48px;
          }
          .apply {
            color: #30345e;
            font-weight: 600;
            font-size: 14px;
            background: #ffb619;
            border-radius: 60px;
            width: 100%;
            height: 48px;
            border: 0;
          }
        }
      }
    }
  }
`;

export const MobileFilterContainer = styled.div`
  .filter-calculator {
    .filter-name {
      color: #000000;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .date-value {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 24px;
      border-bottom: 1px solid #e5e7eb;
      padding-bottom: 18px;
      .input-field {
        .label {
          color: #000000;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 5px;
        }
        .ant-picker {
          height: 48px;
          border-radius: 4px;
          background: #f8fafc;
          width: 100%;
        }
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .name {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .min-max-loan-value {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 24px;
      .label {
        color: #202020;
        font-weight: 600;
        font-size: 12px;
      }
      .ant-input-number {
        background: #f8fafc;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        width: 100%;
      }
      input {
        height: 48px;
      }
    }
    .range-value {
      /* margin-bottom: 72px; */
      margin-bottom: 24px;
      .ant-slider .ant-slider-track {
        background-color: #30345e;
      }
    }
    .filter-by-status {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgb(229, 231, 235);
      .name {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 12px;
      }
      .ant-checkbox + span {
        color: #30345e;
        font-weight: 600;
      }
    }

    .filter-by-failed-status {
      margin-bottom: 24px;
      .name {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 12px;
      }
      .ant-checkbox + span {
        color: #30345e;
        font-weight: 600;
      }
    }

    .filter-button-container {
      display: flex;
      gap: 14px;
      .reset {
        color: #30345e;
        font-weight: 600;
        font-size: 14px;
        border: 1px solid #30345e;
        background: #fff;
        box-shadow: none;
        border-radius: 60px;
        width: 100%;
        height: 48px;
      }
      .apply {
        color: #30345e;
        font-weight: 600;
        font-size: 14px;
        background: #ffb619;
        border-radius: 60px;
        width: 100%;
        height: 48px;
        border: 0;
      }
    }
  }
`;

export const TableContainer = styled.div`
  padding: 16px 16px;

  .ant-list .ant-list-item {
    display: block;
  }

  @media ${device.mobileL} {
    padding: 4px 10px 24px;
  }
  .ant-pagination {
    margin-bottom: 0 !important;
  }
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev,
  .ant-pagination .ant-pagination-jump-next {
    background: #ffb619;
    border-radius: 44px;
    border: 0;
    padding: 0px 16px;
    font-weight: 600;
  }
  .ant-pagination .ant-pagination-jump-prev,
  .ant-pagination .ant-pagination-jump-next {
    padding: 0;
  }
  .hide-display {
    display: none;
  }
  .filter-mobile-loader-wrapper {
    min-height: 70vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  table {
    border: 1px solid #f0f0f0;
    border-bottom: none;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: 0 0;
  }
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: transparent;
  }
  .status-button {
    background: #ffed8e;
    border-radius: 4px;
    color: #30345e !important;
    letter-spacing: -0.25px;
    border: 0;
  }
  .old-status-button {
    background: rgb(255 250 222);
    border-radius: 4px;
    color: #30345e !important;
    letter-spacing: -0.25px;
    border: 0;
  }
`;

export const UsersTableContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  .ant-list .ant-list-item {
    display: block;
  }

  @media ${device.mobileL} {
    padding: 0px;
  }
  .ant-pagination {
    margin-bottom: 0 !important;
  }
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev,
  .ant-pagination .ant-pagination-jump-next {
    background: #ffb619;
    border-radius: 44px;
    border: 0;
    padding: 0px 16px;
    font-weight: 600;
  }
  .ant-pagination .ant-pagination-jump-prev,
  .ant-pagination .ant-pagination-jump-next {
    padding: 0;
  }
  .hide-display {
    display: none;
  }
  .filter-mobile-loader-wrapper {
    min-height: 70vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  table {
    border: 1px solid #f0f0f0;
    border-bottom: none;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: 0 0;
  }
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: transparent;
  }
  .status-button {
    background: #ffed8e;
    border-radius: 4px;
    color: #30345e !important;
    letter-spacing: -0.25px;
    border: 0;
  }
  .old-status-button {
    background: rgb(255 250 222);
    border-radius: 4px;
    color: #30345e !important;
    letter-spacing: -0.25px;
    border: 0;
  }
`;

export const ShowFilterWeb = styled.div`
  .show-filter-container {
    display: flex;
    align-items: center;
    gap: 6px;
    .label {
      color: #6b7280;
      font-weight: 600;
      font-size: 14px;
      @media ${device.mobileL} {
        display: none;
      }
    }
  }
  .ant-select {
    .ant-select-selector {
      width: 150px;
      @media ${device.mobileL} {
        width: 150px;
      }
    }
  }
`;

export const PendingDocMobileCard = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  /* background: red; */
  /* border-bottom: 1px solid #e5e7eb; */
  padding-bottom: 16px;
  padding-top: 16px;
  gap: 4px;
  img {
    object-fit: cover;
  }
  .other-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .pending-detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .arrow-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .car-info {
    display: flex;
    gap: 5px;
    .name {
      color: #202020;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
    }
    .phone,
    .car {
      color: #202020;
      font-size: 14px;
      line-height: 21px;
    }
    .amount {
      color: #202020;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .dic-amount {
    color: #202020;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: end;
  }
  .loan-stage {
    color: #30345e;
    font-weight: 400;
    font-size: 12px;
    background: #ffed8e;
    border-radius: 4px;
    padding: 4px 2px;
  }
  .other-loan-stage {
    color: #30345e;
    font-weight: 400;
    font-size: 12px;
    background: rgb(255 250 222);
    border-radius: 4px;
    padding: 4px 2px;
  }
`;

export const NoInventoryContainer = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  .content {
    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media ${device.mobileL} {
      width: 100%;
    }
    img {
      margin: 0 auto;
    }
    .sub-heading {
      color: #6b7280;
      font-weight: 400;
      font-size: 18px;
      @media ${device.mobileL} {
        font-size: 14px;
      }
    }
  }
`;

export const TablePagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  .button-wrapper {
    display: flex;
    gap: 10px;
    button {
      background: #ffb619;
      border-radius: 44px;
      border: 0;
      .button {
        display: flex;
        align-items: center;
        div {
          color: #30345e;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
`;

export const NewLoansButton = styled.div`
  display: block;
  position: fixed;
  bottom: 4rem;
  /* left: 0; */
  right: 3rem;
  z-index: 500;
  width: fit-content;
  @media ${device.mobileL} {
    right: 1rem;
    bottom: 5.2rem;
  }
  .button-content {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    max-width: 1500px;
    margin: 0 auto;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #30345e;
    font-weight: 800;
    font-size: 14px;
    background: #ffb619;
    border-radius: 60px;
    min-width: 137px;
    width: auto;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const LoanDetailsHeader = styled.div`
  width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  @media ${device.mobileL} {
    padding: 16px 12px;
    min-height: 72px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .loan-header {
    display: flex;
    align-items: center;
    gap: 10px;
    @media ${device.mobileL} {
      margin-bottom: 12px;
    }
    cursor: pointer;
    .name {
      font-weight: bold;
      font-size: 20px;
      color: #30345e;
      @media ${device.mobileL} {
        font-size: 16px;
      }
    }
    .ant-switch.ant-switch-checked {
      background: #1677ff;
      background: #22c55e;
    }
    .ant-switch {
      background: rgba(0, 0, 0, 0.4);
    }
  }
`;

export const LoanDateHeader = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  border-radius: 8px;
  width: 100%;
  min-height: 52px;
  height: auto;
  display: flex;
  align-items: center;
  color: #30345e;
  font-weight: 600;
  font-size: 16px;
  padding: 16px;
  @media ${device.mobileL} {
    font-size: 14px;
    padding: 16px 12px;
  }

  .smart-notification-container {
    display: flex;
    align-items: center;
    gap: 16px;
    @media ${device.mobileL} {
      flex-direction: column;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
      @media ${device.mobileL} {
        font-size: 18px;
        text-align: center;
      }
    }
    .content {
      font-size: 15px;
      font-weight: 300;
      line-height: 24px;
      width: 95%;
      text-align: justify;
      @media ${device.mobileL} {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .smart-image {
      img {
        width: 360px;
        height: 100%;
        margin: 0 auto;
        @media ${device.mobileL} {
          width: 120px;
          height: auto;
        }
      }
    }
  }
`;

export const CustomSelect = styled.div`
  .ant-select {
    .ant-select-selector {
      border-radius: 30px;
      background: #ffb619;
      align-items: center;
      color: #30345e;
      font-size: 14px;
      font-weight: 600 !important;
      border-color: #ffb619;
    }
    .ant-select-selection-placeholder {
      color: #30345e;
    }
  }
  .ant-select-disabled {
    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .ant-select-disabled:where(
      .css-dev-only-do-not-override-1km3mtt
    ).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
`;

export const PartnerSelect = styled.div`
  .ant-select {
    .ant-select-selector {
      border-radius: 30px;
      background: #fff;
      align-items: center;
      color: #30345e;
      font-size: 14px;
      font-weight: 600 !important;
      border-color: #30345e;
      padding-left: 18px;
      padding-right: 18px;
    }
    .ant-select-selection-placeholder {
      color: #30345e;
    }
  }
  .ant-select-disabled {
    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
`;

export const CustomerCarInfo = styled.div`
  min-height: 508px;
  height: auto;
  @media ${device.mobileL} {
    min-height: 100%;
  }
  @media ${device.tablet} {
    min-height: 100%;
  }
  .car-info {
    background: #eef6ff;
    padding: 12px;
    @media ${device.mobileL} {
      display: flex;
      gap: 10px;
    }
    img {
      object-fit: cover;
      @media ${device.tablet} {
        width: 100%;
        height: 250px;
        border-radius: 7px;
      }
      @media ${device.mobileL} {
        width: 93px;
        height: 87px;
        border-radius: 7px;
      }
    }
    .detail {
      display: flex;
      justify-content: space-between;
      @media ${device.mobileL} {
        justify-content: start;
        gap: 6px;
      }
      div {
        color: #30345e;
        font-weight: 600;
        font-size: 16px;
        @media ${device.mobileL} {
          font-size: 13px;
        }
      }
    }
  }
  .car-price {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    background: #ffffff;
    border-radius: 0px 0px 8px 8px;
    height: 57px;
    display: flex;
    align-items: center;
    div {
      color: #30345e;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .customer-info {
    color: #30345e;
    font-weight: 600;
    font-size: 16px;
    background: #ffffff;
    border-radius: 4px 4px 0px 0px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 12px;
    margin-bottom: 4px;
  }
  .customer-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 0px 0px 8px 8px;
    height: auto;
    padding: 12px;
    @media ${device.mobileL} {
      padding: 0px;
    }
    ul {
      list-style-type: disc;
      padding-left: 15px;
      li {
        color: #30345e;
        font-weight: 600;
        font-size: 16px;
        @media ${device.mobileL} {
          font-size: 14px;
        }
      }
    }
    .whatsapp {
      text-align: center;
      color: #6b7280;
      font-size: 10px;
    }
  }
`;

export const LoanDocsAccordion = styled.div`
  .site-collapse-custom-collapse .site-collapse-custom-panel,
  .site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 24px;
    overflow: hidden;
    background: #f7f7f7;
    border: 0px;
    border-radius: 0px;
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    @media ${device.mobileL} {
      padding: 0px 12px 16px;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    @media ${device.mobileL} {
      padding: 12px 12px;
    }
  }
`;

export const DownloadLoanButton = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${device.tablet} {
    justify-content: center;
    margin: 24px 0px;
  }
  @media ${device.mobileL} {
    justify-content: center;
    margin: 24px 0px;
  }
  .ant-btn {
    color: #30345e !important;
    font-weight: 800;
    font-size: 14px;
    border-color: #30345e;
    border-radius: 44px;
    min-width: 181px;
    width: auto;
    height: 32px;
  }

  .ant-btn-disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: none;
  }
`;

export const DealDownloadLoanButton = styled.div`
  display: flex;
  color: #30345e !important;
  font-weight: 800;
  font-size: 14px;
  border: 1px solid #30345e !important;
  border-radius: 44px;
  height: 3rem;
  align-items: center !important;
  justify-content: center !important;
  min-width: 11.8rem;

  @media ${device.tablet} {
    justify-content: center;
    margin: 24px 0px;
  }
  @media ${device.mobileL} {
    justify-content: center;
    margin: 24px 0px;
  }
  .ant-btn {
    color: #30345e !important;
    font-weight: 800;
  }
  /* display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    justify-content: center;
    margin: 24px 0px;
  }
  @media ${device.mobileL} {
    justify-content: center;
    margin: 24px 0px;
  }
  .ant-btn {
    color: #30345e !important;
    font-weight: 800;
    font-size: 14px;
    border: 1px solid #30345e !important;
    border-radius: 44px;
    min-width: 181px;
    width: auto;
    padding: 0px 10px;
    height: 3rem;
  } */
`;

export const MobileCommissionDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  .name {
    color: #6b7280;
    font-weight: 600;
    font-size: 16px;
  }
  .amount {
    color: #202020;
    font-weight: 600;
    font-size: 16px;
  }
`;

export const UploadDocumentModal = styled.div`
  padding: 16px;
  .label {
    font-weight: 600;
    padding-bottom: 8px;
  }
  form {
    .document-input {
      height: 45px;
      border-radius: 2px;
    }
    label {
      font-weight: 600;
    }
    .ant-input {
      border-radius: 2px;
    }
    .input-custom {
      border-radius: 2px;
      height: 45px;
    }
    .ant-input-affix-wrapper {
      border-radius: 2px;
      height: 45px;
    }
    .button-container {
      display: flex;
      gap: 12px;
      .cancel-button {
        background: #fff;
        color: rgb(48, 52, 94);
        width: 100%;
        height: 45px;
        border-radius: 2px;
        border: 1px solid rgb(48, 52, 94);
        span {
          font-weight: 600;
        }
      }
      .submit-button {
        background: rgb(255, 182, 25);
        color: rgb(48, 52, 94);
        width: 100%;
        height: 45px;
        border-radius: 2px;
        span {
          font-weight: 600;
        }
      }
    }
  }
`;

export const UploadSignedDocumentModal = styled.div`
  padding: 16px 16px;

  form {
    label {
      font-weight: 600;
    }
    .ant-upload-wrapper .ant-upload-drag {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      background: white;
      border: 1px solid #e5e7eb !important;
      border-radius: 8px;
      cursor: pointer;
      transition: border-color 0.3s;
    }
    .button-container {
      display: flex;
      gap: 12px;
      .cancel-button {
        background: #fff;
        color: rgb(48, 52, 94);
        width: 100%;
        height: 45px;
        border-radius: 2px;
        border: 1px solid rgb(48, 52, 94);
        span {
          font-weight: 600;
        }
      }
      .submit-button {
        background: rgb(255, 182, 25);
        color: rgb(48, 52, 94);
        width: 100%;
        height: 45px;
        border-radius: 2px;
        span {
          font-weight: 600;
        }
      }
    }
  }
`;

export const CustomerDocument = styled.div`
  background: #ffffff;
  min-height: 200px;
  height: auto;
  padding: 16px;
  @media ${device.mobileL} {
    padding: 0px;
    margin-bottom: 60px;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 0.809513px solid #cdc3c3; */
    padding: 16px 0px;
    padding-top: 0px;
    @media ${device.mobileL} {
      padding: 0px 12px 16px;
    }
    .customer-header {
      color: rgb(38, 42, 75);
      font-size: 16px;
      font-weight: 600;
      z-index: 1;
      @media ${device.mobileL} {
        font-size: 14px;
      }
    }
    .upload {
      color: #30345e !important;
      font-weight: 600;
      font-size: 14px;
      background: #ffb619;
      border-radius: 60px;
      min-width: 165px;
      width: auto;
      height: 32px;
      border-color: #ffb619 !important;
      @media ${device.mobileL} {
        min-width: auto;
        font-size: 12px;
      }
      &:disabled {
        border-color: #d9d9d9 !important;
        color: rgba(0, 0, 0, 0.25) !important;
        background-color: rgba(0, 0, 0, 0.04);
        box-shadow: none;
      }
    }
  }
`;

export const CustomerLoanInfo = styled.div`
  background: #ffffff;
  /* min-height: 497px; */
  height: auto;
  padding: 16px;
  margin-bottom: 24px;
  @media ${device.mobileL} {
    padding: 6px;
    background: #fff;
  }
`;

export const DeclinationReason = styled.div`
  background: #ffffff;
  padding: 16px 24px;
  @media ${device.mobileL} {
    padding: 12px;
  }
`;

export const BreakdownContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  .vertical-line {
    border: 0.809513px solid #e5e7eb;
    border: 0.809513px solid red;
    height: 167px;
  }
`;

export const LoanBreakdownContent = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  .header {
    color: #30345e;
    font-weight: 800;
    font-size: 16.1903px;
    margin-bottom: 24px;
  }
  .breakdown-container {
    gap: 24px 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .content {
      .name {
        color: #6b7280;
        font-size: 11.3332px;
      }
      .value {
        font-weight: 600;
        font-size: 12.9522px;
        color: #202020;
      }
    }
  }
`;

export const AdditionalFeeContent = styled.div`
  width: 100%;
  max-width: 100%;
  .header {
    color: #30345e;
    font-weight: 800;
    font-size: 16.1903px;
    margin-bottom: 24px;
  }
  .breakdown-container {
    gap: 24px 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .content {
      .name {
        color: #6b7280;
        font-size: 11.3332px;
      }
      .value {
        font-weight: 600;
        font-size: 12.9522px;
        color: #202020;
      }
    }
  }
`;

export const FootNoteInfo = styled.div`
  border-top: 0.809513px solid #e5e7eb;
  border-bottom: 0.809513px solid #e5e7eb;
  padding: 16px 0px;
  padding-right: 36px;
  font-size: 14.5712px;
  color: #000;
  @media ${device.mobileL} {
    border-top: none;
    border-bottom: none;
    border-top: 0.809513px solid #e5e7eb;
    border-bottom: 0.809513px solid #e5e7eb;
    padding: 16px 0px;
    margin-top: 0px;
    margin-bottom: 12px;
    text-align: justify;
    font-size: 14px;
  }
`;

export const DealerFootNoteInfo = styled.div`
  border-top: 0.809513px solid #e5e7eb;
  /* border-bottom: 0.809513px solid #e5e7eb; */
  padding: 16px 0px;
  padding-right: 36px;
  /* margin-top: 24px; */
  font-size: 14.5712px;
  color: #000;
  @media ${device.mobileL} {
    border-top: none;
    border-bottom: none;
    padding: 0px 0px;
    margin-top: 0px;
    margin-bottom: 16px;
    text-align: justify;
  }
`;

export const PreviewUploadedDoc = styled.div`
  /* border-top: 0.809513px solid #e5e7eb; */
  padding: 16px 0px 20px;
  @media ${device.mobileL} {
    border-bottom: 0.809513px solid #e5e7eb;
    margin-bottom: 16px;
  }
  .header {
    color: rgb(48, 52, 94);
    font-weight: 800;
    font-size: 16.1903px;
    margin-bottom: 12px;
    @media ${device.mobileL} {
      font-size: 14px;
    }
  }
  .list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    @media ${device.mobileL} {
      gap: 8px;
    }
    a {
      font-size: 14px;
      font-weight: 500;
      color: #6b7280;
      @media ${device.mobileL} {
        font-size: 12px;
      }
    }
  }
`;

export const ActionButtons = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media ${device.mobileL} {
    position: relative;
    padding: 16px 0px 0px;
    background: #fff;
    z-index: 2;
  }
  .grouping {
    display: flex;
    align-items: center;
    gap: 12px;
    .ant-btn {
      height: auto;
    }
    @media ${device.mobileL} {
      gap: 10px;
    }
  }
  .email {
    color: #30345e !important;
    font-weight: 600;
    font-size: 14px;
    /* border: 1px solid #30345e !important; */
    border-color: #30345e !important;
    border-radius: 44px;
    min-width: 144px;
    width: auto;
    height: 32px;
    @media ${device.mobileL} {
      min-width: auto;
      font-size: 13px;
    }
    &:disabled {
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
    }
  }
  .upload {
    color: #30345e !important;
    font-weight: 600;
    font-size: 14px;
    background: #ffb619;
    border-radius: 44px;
    min-width: 165px;
    width: auto;
    height: 32px;
    border-color: #ffb619 !important;
    @media ${device.mobileL} {
      min-width: auto;
      font-size: 14px;
    }
    &:disabled {
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
    }
  }
`;

export const CollapseContainer = styled.div`
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    transition: transform 0.3s;
    transform: rotate(-90deg);
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #30345e;
    font-weight: 600;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    @media ${device.mobileL} {
      padding: 12px;
    }
  }
  .ant-collapse {
    background: #fff;
    border-radius: 0px;
    border: none;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
    border-bottom: 0.809513px solid rgb(229, 231, 235);
    border-radius: 0px;
  }
`;

export const OldLoansContainer = styled.div`
  .document-container {
    margin-bottom: 24px;
    .kyc-document-container {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }
  .header {
    color: rgb(48, 52, 94);
    font-weight: 800;
    font-size: 16.1903px;
    margin-bottom: 14px;
  }
  .offer-heading {
    color: rgb(48, 52, 94);
    font-weight: 800;
    font-size: 12.5px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  .kyc-container {
    .document-list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      -webkit-box-align: center;
      align-items: center;
      a {
        font-size: 12.5px;
        font-weight: 500;
        color: #6b7280;
        @media ${device.mobileL} {
          font-size: 12px;
        }
      }
    }
  }
  .offer-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    .offer-list {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      align-items: center;
      @media ${device.mobileL} {
        gap: 8px;
      }
      .name {
        color: rgb(107, 114, 128);
        font-size: 11.3332px;
      }
      .value {
        font-weight: 600;
        font-size: 12.9522px;
        color: rgb(32, 32, 32);
      }
    }
    .document-list {
      width: 50%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @media ${device.mobileL} {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
      }
      a {
        font-size: 12.5px;
        font-weight: 500;
        color: #6b7280;
        @media ${device.mobileL} {
          font-size: 12px;
        }
      }
    }
  }
`;

export const InventoryCalculateLoan = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 4;
  .button-container {
    background: #ffb619;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 110px;
    padding: 4px 8px 4px 4px;
    &:hover {
      text-decoration: none;
      background-color: rgb(255, 182, 25);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
        rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    }
    cursor: pointer;
    .name {
      color: #30345e;
      font-size: 14px;
      font-weight: bolder;
      @media ${device.mobileL} {
        font-size: 12.5px;
      }
    }
    img {
      display: flex;
    }
  }
`;
export const OutlineButton = styled.button`
  height: 48px;
  border-radius: 60px;
  background: #fff;
  border: 1px solid #30345e;
  color: #30345e;
  font-weight: 600;
  font-size: 14px;
  @media ${device.mobileL} {
    font-size: 14px;
  }
  &:hover {
    text-decoration: none;
    background-color: rgba(49, 53, 91, 0.04);
    border: 1px solid rgb(49, 53, 91);
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    box-shadow: none;
    border: 1px solid #d9d9d9;
  }
`;

export const ApplyLoanButton = styled.button`
  height: 48px;
  background: #ffb619;
  border-radius: 60px;
  border: 0;
  color: #30345e;
  font-weight: 600;
  font-size: 14px;
  @media ${device.mobileL} {
    font-size: 14px;
  }
  &:hover {
    text-decoration: none;
    background-color: rgb(255, 182, 25);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
      rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    box-shadow: none;
    border: 1px solid #d9d9d9;
  }
`;

export const InventoryCarsCard = styled.div`
  padding: 16px 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
    padding: 14px 8px;
  }
  .image-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center center;
  }
  .card-container {
    position: relative;
    z-index: 2;
    cursor: pointer;
    .ant-card {
    }
    .ant-card .ant-card-body {
      padding: 10px 10px 14px;
    }
    .ant-tag.ant-tag-has-color,
    .ant-tag.ant-tag-has-color a,
    .ant-tag.ant-tag-has-color a:hover,
    .ant-tag.ant-tag-has-color .anticon-close,
    .ant-tag.ant-tag-has-color .anticon-close:hover {
      color: #fff;
      border-radius: 40px;
      font-weight: bold;
      font-size: 14px;
      padding: 5px 10px;
      @media ${device.mobileL} {
        font-size: 13px;
      }
    }
    .car-container {
      border-bottom: 1px solid #e1e7ec;
      padding-bottom: 16px;
      .car-name {
        color: #485164;
        font-size: 24px;
        font-family: OutfitBold !important;
        @media ${device.mobileL} {
          font-size: 20px;
        }
      }
    }
    .amount-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0 12px;
      .amount {
        color: #485164;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        @media ${device.mobileL} {
          font-size: 14px;
        }
      }
      .location {
        color: #a3a3a3;
        font-weight: 500;
        font-size: 12px;
      }
      .min-percent {
        color: #a3a3a3;
        font-weight: 500;
        font-size: 12.5px;
        @media ${device.mobileL} {
          font-size: 11px;
        }
      }
      .min-amount {
        color: #4e538a;
        font-size: 22px;
        line-height: 20px;
        font-family: OutfitBold !important;
        text-align: right;
        @media ${device.mobileL} {
          font-size: 18px;
        }
      }
    }
    .cta-container {
      display: flex;
      align-items: center;
      gap: 14px;
      @media ${device.mobileL} {
        gap: 4px;
      }
      button {
        width: 100%;
      }
      .req-inspection-btn {
        height: 48px;
        border-radius: 60px;
        background: #fff;
        border: 1px solid #30345e;
        span {
          color: #30345e;
          font-weight: 600;
          font-size: 14px;
          @media ${device.mobileL} {
            font-size: 12px;
          }
        }
        &:hover {
          text-decoration: none;
          background-color: rgba(49, 53, 91, 0.04);
          border: 1px solid rgb(49, 53, 91);
        }
        &:disabled {
          color: rgba(0, 0, 0, 0.25);
          background: rgba(0, 0, 0, 0.04);
          box-shadow: none;
          border: 1px solid #d9d9d9;
        }
      }
      .apply-loan-btn {
        height: 48px;
        background: #ffb619;
        border-radius: 60px;
        border: 0;
        span {
          color: #30345e;
          font-weight: 600;
          font-size: 14px;
          @media ${device.mobileL} {
            font-size: 12px;
          }
        }
        &:hover {
          text-decoration: none;
          background-color: rgb(255, 182, 25);
          box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
            rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
        }
        &:disabled {
          color: rgba(0, 0, 0, 0.25);
          background: rgba(0, 0, 0, 0.04);
          box-shadow: none;
          border: 1px solid #d9d9d9;
          span {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
`;

export const CarTagsContainer = styled.div`
  .ant-tag.ant-tag-has-color,
  .ant-tag.ant-tag-has-color a,
  .ant-tag.ant-tag-has-color a:hover,
  .ant-tag.ant-tag-has-color .anticon-close,
  .ant-tag.ant-tag-has-color .anticon-close:hover {
    color: #fff;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
    font-family: OutfitRegular;
    padding: 5px 10px;
    @media ${device.mobileL} {
      font-size: 12.5px;
    }
  }
`;

export const CarOutlineButton = styled.div`
  .req-inspection-btn {
    height: 48px;
    border-radius: 60px;
    background: #fff;
    border: 1px solid #30345e;
    span {
      color: #30345e;
      font-weight: 600;
      font-size: 14px;
      @media ${device.mobileL} {
        font-size: 12px;
      }
    }
    &:hover {
      text-decoration: none;
      background-color: rgba(49, 53, 91, 0.04);
      border: 1px solid rgb(49, 53, 91);
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background: rgba(0, 0, 0, 0.04);
      box-shadow: none;
      border: 1px solid #d9d9d9;
    }
  }
`;

export const CarFilledButton = styled.div`
  .apply-loan-btn {
    height: 48px;
    background: #ffb619;
    border-radius: 60px;
    border: 0;
    span {
      color: #30345e;
      font-weight: 600;
      font-size: 14px;
      @media ${device.mobileL} {
        font-size: 12px;
      }
    }
    &:hover {
      text-decoration: none;
      background-color: rgb(255, 182, 25);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
        rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background: rgba(0, 0, 0, 0.04);
      box-shadow: none;
      border: 1px solid #d9d9d9;
    }
  }
`;

export const NewCarCard = styled.div`
  padding: 16px 16px;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
    padding: 14px 8px;
  } */
  .image-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center center;
  }
  .card-container {
    position: relative;
    z-index: 2;
    cursor: pointer;
    .ant-card {
    }
    .ant-card .ant-card-body {
      padding: 10px 10px 14px;
    }

    .car-container {
      border-bottom: 1px solid #e1e7ec;
      padding-bottom: 16px;
      .car-name {
        color: #485164;
        font-size: 24px;
        font-family: OutfitBold !important;
        @media ${device.mobileL} {
          font-size: 20px;
        }
      }
    }
    .amount-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0 12px;
      .amount {
        color: #485164;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        @media ${device.mobileL} {
          font-size: 14px;
        }
      }
      .location {
        color: #a3a3a3;
        font-weight: 500;
        font-size: 12px;
      }
      .min-percent {
        color: #a3a3a3;
        font-weight: 500;
        font-size: 12.5px;
        @media ${device.mobileL} {
          font-size: 11px;
        }
      }
      .min-amount {
        color: #4e538a;
        font-size: 22px;
        line-height: 20px;
        font-family: OutfitBold !important;
        text-align: right;
        @media ${device.mobileL} {
          font-size: 18px;
        }
      }
    }
    .cta-container {
      display: flex;
      align-items: center;
      gap: 14px;
      @media ${device.mobileL} {
        gap: 4px;
      }
      button {
        width: 100%;
      }
      .req-inspection-btn {
        height: 48px;
        border-radius: 60px;
        background: #fff;
        border: 1px solid #30345e;
        span {
          color: #30345e;
          font-weight: 600;
          font-size: 14px;
          @media ${device.mobileL} {
            font-size: 12px;
          }
        }
        &:hover {
          text-decoration: none;
          background-color: rgba(49, 53, 91, 0.04);
          border: 1px solid rgb(49, 53, 91);
        }
        &:disabled {
          color: rgba(0, 0, 0, 0.25);
          background: rgba(0, 0, 0, 0.04);
          box-shadow: none;
          border: 1px solid #d9d9d9;
        }
      }
      .apply-loan-btn {
        height: 48px;
        background: #ffb619;
        border-radius: 60px;
        border: 0;
        span {
          color: #30345e;
          font-weight: 600;
          font-size: 14px;
          @media ${device.mobileL} {
            font-size: 12px;
          }
        }
        &:hover {
          text-decoration: none;
          background-color: rgb(255, 182, 25);
          box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
            rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
        }
        &:disabled {
          color: rgba(0, 0, 0, 0.25);
          background: rgba(0, 0, 0, 0.04);
          box-shadow: none;
          border: 1px solid #d9d9d9;
        }
      }
    }
  }
`;

export const InventoryDetailsPage = styled.div`
  /* background: #fff; */
  /* padding: 24px 16px 48px; */
  height: auto;
  @media ${device.mobileL} {
    margin-bottom: 24px;
    /* padding: 0px 16px 48px; */
  }
  .preview-container {
    display: block;
    @media ${device.mobileL} {
      display: none;
    }
  }
`;

export const CarMediaContainer = styled.div`
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 28px;
  .car-media-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    position: relative;
    .img-one {
      width: 100%;
      height: 490px;
      background: #ccc;
      overflow: hidden;
      img {
        width: 100%;
        height: 490px;
        object-fit: cover;
      }
    }
    .img-two-one {
      overflow: hidden;
      width: 100%;
      height: 237px;
      background: #ccc;
      img {
        width: 100%;
        height: 237px;
        object-fit: cover;
      }
    }
    .media-group-two {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
    .show-photos {
      display: flex;
      gap: 10px;
      color: #000000;
      font-weight: 600;
      font-size: 13.5908px;
      padding: 8px 16px;
      background: #ffffff;
      border-radius: 7.76619px;
      min-width: 170px;
      width: auto;
      cursor: pointer;
      position: absolute;
      right: 16px;
      bottom: 16px;
      z-index: 5;
    }
  }
`;

export const CarMediaMobile = styled.div`
  .content-style {
    margin: 0;
    width: 100%;
    height: 250px;
    color: "#fff";
    text-align: center;
    background: #ccc;
    overflow: hidden;
    border-radius: 4.56923px;
    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }
  }
`;

export const CarContentContainer = styled.div`
  width: 100%;
  padding: 0 36px;
  @media ${device.mobileL} {
    padding: 0 0;
    display: block;
  }
  .car-content-one-wrapper {
    @media ${device.mobileL} {
      width: 100%;
    }
    .card-one {
      .ant-card-head,
      .ant-card-body {
        padding: 14px;
      }
      .title-container {
        .name {
          /* font-weight: 600; */
          font-size: 24px;
          color: #30345e;
          font-family: OutfitBold !important;
          @media ${device.mobileL} {
            font-size: 20px;
            margin-bottom: 8px;
            flex-direction: column;
          }
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          .franchise-name {
            font-size: 16px;
            color: #6b7280;
            @media ${device.mobileL} {
              font-size: 14px;
            }
          }
        }
        .detail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media ${device.mobileL} {
            display: block;
          }
          .location {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #6b7280;
            font-weight: 600;
            font-size: 16px;
            @media ${device.mobileL} {
              font-size: 12px;
              margin-bottom: 5px;
            }
          }
          .detail-name {
            display: flex;
            align-items: center;
            gap: 8px;
            span {
              color: #6b7280;
              font-weight: 600;
              font-size: 14px;
              @media ${device.mobileL} {
                font-size: 12px;
              }
            }
            .rate-wrap {
              display: flex;
              align-items: center;
              gap: 2px;
            }
          }
        }
      }
      .overview {
        color: #30345e;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 8px;
        @media ${device.mobileL} {
          font-size: 16px;
        }
      }
      .car-description-wrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 16px;
        column-gap: 100px;
        @media ${device.mobileL} {
          column-gap: 30px;
        }
        .title {
          color: #6b7280;
          font-weight: 400;
          font-size: 12px;
        }
        .content {
          color: #30345e;
          font-weight: 600;
          font-size: 16px;
          @media ${device.mobileL} {
            font-size: 14px;
          }
        }
      }
    }
    .card-two {
      .ant-card-head {
        padding: 0 14px;
        border-bottom: none;
      }
      .ant-card-body {
        padding: 36px 14px;
        min-height: 200px;
        height: auto;
      }
      .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
        padding: 16px 48px;
        font-size: 14px;
        font-weight: 600;
        color: #7f83a8;
        @media ${device.mobileL} {
          padding: 16px 10px;
        }
      }
      .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #262a4b;
      }
      .ant-tabs .ant-tabs-ink-bar {
        background: #ffb619;
      }
      .ant-tag.ant-tag-has-color,
      .ant-tag.ant-tag-has-color a,
      .ant-tag.ant-tag-has-color a:hover,
      .ant-tag.ant-tag-has-color .anticon-close,
      .ant-tag.ant-tag-has-color .anticon-close:hover {
        color: #000;
        border-radius: 12px;
        font-weight: 600;
      }
    }
  }
  .car-content-two-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media ${device.mobileL} {
      width: 100%;
    }
    .ant-card-body {
      padding: 0px;
    }
    .card-three {
      .title-container {
        /* border-bottom: 1px solid #f0f0f0; */
        padding: 14px 14px 12px;
        .price {
          color: #6b7280;
          font-weight: 600;
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 6px;
        }
        .installment-amount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .instalment {
            color: #30345e;
            font-weight: 600;
            font-size: 20px;
            font-family: OutfitBold !important;
          }
          .amount {
            color: #4b5563;
            font-weight: 600;
            font-size: 14px;
          }
        }
        .note {
          color: #6b7280;
          font-weight: 400;
          font-size: 12px;
        }
      }
      .apply-container {
        padding: 0 14px;
        border-bottom: 1px solid #f0f0f0;
        display: block;
        @media ${device.mobileL} {
          /* display: none; */
          padding: 0 14px;
        }
        .apply-loan-btn {
          margin: 36px 0px;
          width: 100%;
          color: #30345e;
          font-weight: 600;
          font-size: 14px;
          background: #ffb619;
          border-radius: 60px;
          border: 0;
          height: 48px;
          @media ${device.mobileL} {
            margin: 16px 0px;
          }
          &:disabled {
            color: rgba(0, 0, 0, 0.25);
            background: rgba(0, 0, 0, 0.04);
            box-shadow: none;
            border: 1px solid #d9d9d9;
            span {
              color: rgba(0, 0, 0, 0.25);
            }
          }
        }
        .submit-offer-btn {
          margin: 0px 0px;
          width: 100%;
          color: #30345e;
          font-weight: 600;
          font-size: 14px;
          background: #ffb619;
          border-radius: 60px;
          border: 0;
          height: 48px;
          @media ${device.mobileL} {
            margin: 16px 0px;
          }
        }
      }
      .edit-container {
        padding: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .make-edit {
          color: #000000;
          font-size: 16px;
          @media ${device.mobileL} {
            font-size: 14px;
          }
        }
        .edit-car {
          color: #30345e;
          font-weight: 600;
          font-size: 14px;
          width: 135px;
          height: 48px;
          border: 1px solid #30345e;
          border-radius: 60px;
          @media ${device.mobileL} {
            font-size: 12px;
            width: 110px;
            height: 32px;
            padding: 4px;
          }
          &:disabled {
            color: rgba(0, 0, 0, 0.25);
            background: rgba(0, 0, 0, 0.04);
            box-shadow: none;
            border: 1px solid #d9d9d9;
          }
        }
      }
    }
  }
`;

export const ApplyInventoryLoan = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 16px 12px;
  display: none;
  @media ${device.mobileL} {
    display: block;
  }
  button {
    width: 100%;
    font-size: 14px;
    background: #ffb619;
    border-radius: 60px;
    border: 0;
    height: 48px;
    span {
      color: #30345e;
      font-weight: 600;
    }
  }
`;

export const InspectionReportTable = styled.div`
  .ant-collapse-borderless {
    background-color: rgba(0, 0, 0, 0.02);
    background: transparent;
    border: 0;
  }

  .ant-collapse-header-text {
    font-weight: 600;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    font-weight: 400;
    background: 0 0;
    border-top: none;
    color: #30345e;
    font-weight: 600;
  }
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before,
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    width: 0px;
  }
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: transparent;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    /* border: none !important; */
  }
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: #fff;
  }

  /* .table-img {
    img {
      @media ${device.mobileL} {
        width: 20px;
        height: 20px;
      }
    }
  } */
`;

export const CreateCarContainer = styled.div`
  header {
    text-align: center;
    padding: 24px;
    color: #30345e;
    font-size: 1.6rem;
    font-weight: bolder;
    @media ${device.mobileL} {
      font-size: 24px;
      padding: 0px 16px 24px;
    }
  }
  .form-main-wrapper {
    display: flex;
    justify-content: center;
    .form-container-page {
      background: #fff;
      width: 690px;
      min-height: 400px;
      height: auto;
      border-radius: 10px;
      @media ${device.mobileL} {
        width: 100%;
      }
      .header {
        color: #30345e;
        font-weight: 600;
        font-size: 20px;
        border-bottom: 1px solid #e5e7eb;
        padding: 16px;
        @media ${device.mobileL} {
          font-size: 18px;
        }
      }
    }
  }
`;

export const CustomFormContainer = styled.div`
  padding: 16px 16px 48px;
  .custom-form-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
    column-gap: 16px;
    margin-bottom: 24px;
    @media ${device.mobileL} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const CustomFormField = styled.div`
  .feedback {
    font-size: 12px;
    color: #ff4d4f;
  }
  .display-flex {
    display: flex;
  }
  .label {
    color: #30345e;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .loading-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .loading-btn {
      margin-top: 36px;
      width: 40%;
      border-radius: 60px;
      height: 48px;
      font-weight: 600;
      @media ${device.mobileL} {
        width: 80%;
      }
    }
  }
  .ant-btn-primary {
    border-radius: 60px;
    width: 40%;
    height: 48px;
    font-weight: 600;
    background: rgb(255, 182, 25);
    color: rgb(48, 52, 94);
    font-size: 16px;
    font-weight: 600;
    border: 0;
    @media ${device.mobileL} {
      width: 80%;
    }
    &:hover {
      background: rgb(255, 182, 25);
      color: rgb(48, 52, 94);
    }
  }
  .ant-btn-primary:disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: none;
    border: 1px solid #d9d9d9;
  }
  .css-1twrztz-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
    background: rgb(255, 182, 25);
    color: rgb(48, 52, 94);
    font-weight: 600;
  }
  .css-1twrztz-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
    background: rgb(255, 182, 25);
  }
  .ant-picker {
    height: 54px;
    width: 100%;
    input {
      font-size: 16px;
    }
  }
`;

export const CustomInputMileage = styled.div`
  .label {
    color: rgb(48, 52, 94);
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .ant-input-wrapper {
    height: 54px;
    border-radius: 4px;
    font-size: 16px;
  }
  .ant-input {
    height: 54px;
    border-radius: 4px;
    font-size: 16px;
  }
  .ant-select {
    height: 54px;
    width: 80px;
    .ant-select-selector {
      height: 54px;
      border-radius: 4px;
      font-size: 16px;
      align-items: center;
      color: #000;
    }
  }
`;

export const CustomCarUploadContainer = styled.div`
  /* padding: 24px 16px 48px; */
  .upload-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    @media ${device.mobileL} {
      grid-template-columns: repeat(1, 1fr);
      margin: 0 auto;
    }
  }
  .ant-upload-wrapper {
    position: relative;
  }

  form {
    label {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    width: 100%;
    height: 125px;
    border-radius: 4px;
    overflow: hidden;
  }

  .delete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-text {
    position: absolute;

    h1 {
      font-weight: 600;
    }
    span[class^="dot-"] {
      opacity: 0;
    }
    .dot-one {
      animation: dot-one 2s infinite linear;
    }
    .dot-two {
      animation: dot-two 2s infinite linear;
    }
    .dot-three {
      animation: dot-three 2s infinite linear;
    }
    @keyframes dot-one {
      0% {
        opacity: 0;
      }
      15% {
        opacity: 0;
      }
      25% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes dot-two {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes dot-three {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      75% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export const CarFeaturesContainer = styled.div`
  padding: 24px 16px 48px;
  .ant-input-affix-wrapper {
    height: 48px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
  }
  .search-container {
    .label {
      color: #30345e;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
  .car-features-check-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 24px 0;
    @media ${device.mobileL} {
      grid-template-columns: repeat(2, 1fr);
    }
    .ant-checkbox-wrapper {
      color: #30345e;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const LoanCalcInputField = styled.div`
  .label {
    color: rgb(75, 85, 99);
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }
`;

export const LoanCalculatorContainer = styled.div`
  .car-detail-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 18px;
    column-gap: 12px;
    @media ${device.mobileL} {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .ant-card-body {
    padding: 0px;
  }

  .card-three-card {
    width: 100%;
    @media ${device.mobileL} {
      border: 0;
    }
    .loan-title {
      color: rgba(48, 52, 94, 1);
      font-size: 24px;
      font-family: OutfitBold !important;
      text-align: center;
      padding: 15px 110px;
      border-bottom: 1px solid rgba(229, 231, 235, 1);
      @media ${device.mobileL} {
        font-size: 18px;
        padding: 10px 0px 16px;
        border-bottom: none;
      }
    }
    .route-loan-title {
      display: none;
      @media ${device.mobileL} {
        display: block;
      }
    }
    .modal-loan-title {
      color: rgba(48, 52, 94, 1);
      font-size: 24px;
      font-family: OutfitBold !important;
      text-align: center;
      padding: 15px 110px;
      @media ${device.mobileL} {
        font-size: 16px;
        padding: 8px 10px;
        border-bottom: none;
      }
    }
    .range-slider-container {
      padding: 14px 14px 20px;
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: 100%;
      @media ${device.mobileL} {
        padding: 0px;
      }
    }
    .loan-detail-name {
      color: rgba(72, 81, 100, 1);
      font-size: 16px;
      font-weight: 600;
      font-family: OutfitBold !important;
      @media ${device.mobileL} {
        font-family: OutfitRegular !important;
      }
    }
    .route-range-slider-container {
      padding: 20px 20px 20px;
      display: flex;
      gap: 24px;
      width: 100%;
      @media ${device.mobileL} {
        padding: 10px 10px 30px;
        display: flex;
        flex-direction: column;
      }
    }
    .range-slider-card {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: 45%;
      @media ${device.mobileL} {
        width: 100%;
      }

      .car-instal-container {
        padding: 4px 10px 4px 10px;
        background: #fcfdfe;
        border-radius: 8px;
        .installment-amount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .instalment {
            color: #b7bbc1;
            font-weight: 600;
            font-size: 18px;
            font-family: OutfitBold !important;
          }
          .amount {
            color: #b7bbc1;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
      .route-car-instal-container {
        padding: 8px 10px 8px 10px;
        background: #f8fafc;
        border-radius: 8px;
        .installment-amount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .instalment {
            color: #30345e;
            font-weight: 600;
            font-size: 16px;
            font-family: OutfitBold !important;
          }
          .amount {
            color: #4b5563;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
      .instal-container {
        background: #f8fafc;
        border-radius: 8px;
        padding: 10px 10px 14px;
        .installment-loan-amount {
          display: flex;
          justify-content: space-between;
          /* align-items: center; */
          .instalment {
            color: #30345e;
            font-weight: 600;
            font-size: 16px;
            font-family: OutfitBold !important;
          }
          .amount {
            color: #4b5563;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 8px;
            @media ${device.mobileL} {
              font-size: 14px;
            }
          }
          .term {
            color: #9299b5;
            font-weight: 400;
            font-size: 14px;
            @media ${device.mobileL} {
              font-size: 12.5px;
            }
          }
        }
      }
      .submit-btn {
        background: #ffb619;
        height: 48px;
        border-radius: 60px;
        border: 1px solid #30345e;
        border: 0;
        span {
          font-size: 14px;
          font-weight: bold;
          color: #30345e;
        }
        &:disabled {
          color: rgba(0, 0, 0, 0.25);
          background: rgba(0, 0, 0, 0.04);
          box-shadow: none;
          border: 1px solid #d9d9d9;
        }
      }
      .repayment-date {
        .label {
          color: #30345e;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 6px;
        }
        .ant-picker {
          width: 100%;
          height: 48px;
          background: #f8fafc;
        }
      }
    }

    .estimated-amount-card {
      background: #30345e;
      border-radius: 8px;
      padding: 24px 15px 23px 15px;
      display: flex;
      flex-direction: column;
      gap: 18px;
      position: relative;
      width: 55%;
      @media ${device.mobileL} {
        padding: 15px 12px;
        width: 100%;
      }
      .estimated-month {
        color: #f9f9f9;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
      }
      .estimated-amount {
        color: #fff;
        font-size: 32px;
        font-family: OutfitBold !important;
        text-align: center;
      }
      .key-value-container {
        display: flex;
        flex-direction: column;
        gap: 7px;
        .key-value-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .key {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
          }
          .value {
            font-size: 14px;
            font-family: OutfitBold !important;
            color: #fff;
          }
        }
      }
      .key-value-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .key {
          font-size: 14px;
          font-weight: 600;
          color: #fff;
        }
        .value {
          font-size: 14px;
          font-family: OutfitBold !important;
          color: #fff;
        }
      }
      .monthly-includes {
        color: #f9f9f9;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 4px;
      }
      .monthly-sentence {
        display: flex;
        flex-wrap: wrap;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        .sentence {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .apply-loan-btn {
        background: #ffb619;
        height: 48px;
        border-radius: 60px;
        border: 0;
        span {
          font-size: 14px;
          font-weight: bold;
          color: #30345e;
        }
      }
      .interest-rate {
        color: #ffffff;
        font-size: 13px;
        font-weight: 400;
        font-style: italic;
        text-align: center;
      }
    }

    .inventory-slider-card,
    .inventory-amount-card {
      width: 100%;
    }
    .ant-select {
      height: 48px;
      min-width: 70px;
      width: 100%;
      .ant-select-selector {
        height: 48px;
        border-radius: 6px;
        background: #fff;
        align-items: center;
        color: rgb(48, 52, 94);
        font-size: 16px;
        font-weight: 600;
        @media ${device.mobileL} {
          height: auto;
        }
      }
    }
    .ant-slider .ant-slider-track {
      background-color: #ffbf1f;
    }
    .ant-slider .ant-slider-handle::after {
      width: 15px;
      height: 15px;
      background-color: #ffffff;
    }
    .ant-slider .ant-slider-handle:hover::after,
    .ant-slider .ant-slider-handle:active::after,
    .ant-slider .ant-slider-handle:focus::after {
      box-shadow: 0 0 0 4px #30345e;
      width: 15px;
      height: 15px;
      inset-inline-start: -1px;
      inset-block-start: -1px;
    }
    .ant-radio-group {
      gap: 12px;
      display: flex;
      flex-wrap: wrap;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      z-index: 1;
      color: #30345e;
      background: #ffffff;
      border: 1px solid #30345e;
    }
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      )::before {
      background-color: #30345e;
    }
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      )::before {
      background-color: #30345e;
    }
    .ant-slider .ant-slider-handle::after {
      box-shadow: 0 0 0 2px #30345e;
    }
    .ant-radio-button-wrapper:first-child {
      border-start-start-radius: 0;
      border-end-start-radius: 0;
    }
    .ant-radio-button-wrapper:last-child {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
    }
  }

  .route-card-three-card {
    width: 80%;
    margin: 0 auto;
    @media ${device.mobileL} {
      width: 100%;
    }
  }

  .blurry-background {
    background: rgb(48 52 93 / 55%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(6px);
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

export const LoanLimitStyled = styled.div`
  padding: 2rem 0 2rem 0;
  font-family: "Outfit";

  .custom-container {
    width: 1000px;
    @media ${device.laptopL} {
      width: 100%;
    }
    @media ${device.tablet} {
      width: 100%;
    }
    @media ${device.mobileL} {
      width: 100%;
    }
  }

  .top-header {
    color: #30345e;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bolder;
  }

  .accordion-container {
    padding: 3rem 20rem;
    @media ${device.tablet} {
      padding: 0rem;
    }
    .accordion {
      .accordion-inner {
        .accordion-title {
          font-size: 1.4rem;
          font-weight: bolder;

          @media ${device.tablet} {
            font-size: 1rem;
          }
        }
        .two-fields {
          display: flex;
          justify-content: space-between;

          @media ${device.tablet} {
            display: block;
          }

          div {
            width: 48%;

            @media ${device.tablet} {
              width: 100%;
            }
          }
        }
        .marital-status {
          width: 48%;

          .hJrnzI {
            width: 100%;

            .ant-select {
              width: 100%;

              .ant-select-selector {
                width: 100%;
              }
            }
          }

          @media ${device.tablet} {
            width: 100%;
          }
        }
      }
    }
    .first-accordion {
      position: relative;

      .accordion-inner {
        padding: 14px;
        border-radius: 18px;
        color: #30345e;
      }
    }

    .second-accordion {
      position: relative;
      margin-bottom: 3rem;

      .accordion-inner {
        padding: 14px;
        border-radius: 18px;
        color: #30345e;
      }
    }
  }

  .about-section,
  .profession-section {
    margin: 0 auto;

    .about-header,
    .profession-header {
      position: relative;

      .counter {
        background: #fff;
        width: fit-content;
        padding: 20px 25px;
        border-radius: 50%;
        font-family: "Outfitbold";
        font-size: 24px;
        color: #30345e;
        position: absolute;
        top: 14px;
        left: 10rem;
      }
      .profession-sub-header {
        color: #30345e;
      }
      .next,
      .submit {
        padding: 0 6rem;
      }
    }
  }

  .formLabel {
    font-weight: 500;
    font-size: 0.855rem;
    color: #30345e;
  }
`;

export const ChangeCarButton = styled.button`
  font-size: 14px;
  font-weight: bolder;
  color: rgb(48, 52, 94);
  background: rgb(255, 182, 25);
  border-radius: 44px;
  min-width: 110px;
  width: auto;
  height: 32px;
  border: 0;
  padding: 0 8px;
  @media ${device.mobileL} {
    min-width: 120px;
    height: 32px;
    font-size: 14px;
  }
  &:disabled {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: none;
  }
`;

export const CustomButton = styled.div`
  .ant-btn {
    background: #ffb619;
    width: 100%;
    height: 48px;
    border-radius: 60px;
    border: none;
    span {
      color: #30345e;
      font-weight: 600;
      font-size: 14px;
    }
    &:disabled {
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
      border: 1px solid #d9d9d9;
      span {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;

  .top-text {
    font-size: 16px;
    color: #30345e;
    font-weight: bolder;
    margin-left: 0.5rem;
  }
`;

export const ViewButton = styled.button`
  color: #30345e !important;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #30345e !important;
  border-radius: 44px;
  width: auto;
  height: 32px;
  @media ${device.mobileL} {
    min-width: auto;
    font-size: 13px;
  }
`;

export const EquityPaymentContainer = styled.div`
  /* padding: 16px; */
  form {
    .document-input {
      height: 45px;
      border-radius: 2px;
    }
    .ant-form-item .ant-form-item-label > label {
      font-size: 15px;
      font-weight: 600;
    }
    input {
      &::placeholder {
        font-size: 15px;
      }
    }
    .ant-input {
      border-radius: 2px;
    }
    .ant-input-custom {
      border-radius: 2px;
      height: 45px;
      font-size: 16px;
    }
    .ant-input-affix-wrapper {
      border-radius: 2px;
      height: 45px;
    }
    .ant-select {
      height: 45px;
      width: 100%;
      @media ${device.mobileL} {
        width: auto;
        height: auto;
      }
      .ant-select-selector {
        height: 45px;
        border-radius: 2px;
        align-items: center;
        color: rgb(48, 52, 94);
        font-size: 16px;
        font-weight: 600;
        @media ${device.mobileL} {
          height: auto;
        }
      }
    }
    .button-container {
      display: flex;
      gap: 12px;
      .cancel-button {
        background: #fff;
        color: rgb(48, 52, 94);
        width: 100%;
        height: 45px;
        border-radius: 2px;
        border: 1px solid rgb(48, 52, 94);
        span {
          font-weight: 600;
        }
      }
      .submit-button {
        background: rgb(255, 182, 25);
        color: rgb(48, 52, 94);
        width: 100%;
        height: 45px;
        border-radius: 60px;
        span {
          font-weight: 600;
        }
      }
    }
    .ant-upload-wrapper .ant-upload-drag {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(229, 231, 235);
      box-shadow: rgb(250, 250, 255) 0px 1px 3px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px;
    }
  }
`;

export const CDocInput = styled.div`
  .ant-input {
    border-radius: 2px;
    height: 45px;
  }
`;

export const TrackerFormContainer = styled.div`
  form {
    .document-input {
      height: 45px;
      border-radius: 2px;
    }
    .ant-form-item .ant-form-item-label > label {
      font-size: 14px;
      font-weight: 500;
    }
    input {
      &::placeholder {
        font-size: 15px;
      }
    }
    .ant-input {
      border-radius: 2px;
    }
    .ant-input-custom {
      border-radius: 2px;
      height: 45px;
      font-size: 16px;
    }
    .ant-input-affix-wrapper {
      border-radius: 2px;
      height: 45px;
    }
    .custom-ant-input {
      border-radius: 2px;
      height: 45px;
    }
    .ant-select {
      height: 45px;
      width: 100%;
      @media ${device.mobileL} {
        width: auto;
        height: auto;
      }
      .ant-select-selector {
        height: 45px;
        border-radius: 2px;
        align-items: center;
        color: rgb(48, 52, 94);
        font-size: 16px;
        font-weight: 600;
        @media ${device.mobileL} {
          height: auto;
        }
      }
    }
    .button-container {
      display: flex;
      gap: 12px;
      .cancel-button {
        background: #fff;
        color: rgb(48, 52, 94);
        width: 100%;
        height: 45px;
        border-radius: 2px;
        border: 1px solid rgb(48, 52, 94);
        span {
          font-weight: 600;
        }
      }
      .submit-button {
        background: rgb(255, 182, 25);
        color: rgb(48, 52, 94);
        width: 100%;
        height: 45px;
        border-radius: 60px;
        span {
          font-weight: 600;
        }
      }
    }
    .ant-upload-wrapper .ant-upload-drag {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(229, 231, 235);
      box-shadow: rgb(250, 250, 255) 0px 1px 3px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px;
    }
  }
`;

export const CheckEligibilityFormContainer = styled.div`
  padding: 2rem 0 2rem 0;
  font-family: "Outfit";

  .top-header {
    color: #30345e;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bolder;
  }

  .accordion-container {
    padding: 3rem 20rem;
    @media ${device.tablet} {
      padding: 0rem;
    }
    .accordion {
      .accordion-inner {
        .accordion-title {
          font-size: 1.4rem;
          font-weight: bolder;

          @media ${device.tablet} {
            font-size: 1rem;
          }
        }
        .two-fields {
          display: flex;
          justify-content: space-between;

          @media ${device.tablet} {
            display: block;
          }

          div {
            width: 48%;

            @media ${device.tablet} {
              width: 100%;
            }
          }
        }
        .marital-status {
          width: 48%;

          .hJrnzI {
            width: 100%;

            .ant-select {
              width: 100%;

              .ant-select-selector {
                width: 100%;
              }
            }
          }

          @media ${device.tablet} {
            width: 100%;
          }
        }
      }
    }
    .first-accordion {
      position: relative;

      .accordion-inner {
        padding: 14px;
        border-radius: 18px;
        color: #30345e;
      }
    }

    .second-accordion {
      position: relative;
      margin-bottom: 3rem;

      .accordion-inner {
        padding: 14px;
        border-radius: 18px;
        color: #30345e;
      }
    }
  }

  .about-section,
  .profession-section {
    margin: 0 auto;

    .about-header,
    .profession-header {
      position: relative;

      .counter {
        background: #fff;
        width: fit-content;
        padding: 20px 25px;
        border-radius: 50%;
        font-family: "Outfitbold";
        font-size: 24px;
        color: #30345e;
        position: absolute;
        top: 14px;
        left: 10rem;
      }
      .profession-sub-header {
        color: #30345e;
      }
      .next,
      .submit {
        padding: 0 6rem;
      }
    }
  }
`;

export const CustomSegmentButton = styled.div`
  .ant-segmented {
    padding: 8px;
    color: #30345e;
    font-weight: 600;
    font-size: 14px;
    background-color: #eeefff;
    border-radius: 40px;
    width: 35%;
    @media ${device.laptop} {
      width: 60%;
    }
    @media ${device.tablet} {
      width: 100%;
    }
    @media ${device.mobileL} {
      width: 100%;
    }
  }
  .ant-segmented .ant-segmented-item-selected {
    background-color: #30345e;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 40px;
  }
  .ant-segmented .ant-segmented-item {
    border-radius: 40px;
    width: 100%;
  }
`;

export const NotifyTab = styled.div`
  .ant-tabs .ant-tabs-tab {
    padding: 12px 14px;
    font-size: 14px;
    color: #7f83a8;
    font-weight: 600;
    @media ${device.mobileL} {
      padding: 12px 6px;
    }
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #262a4b;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: #ffb619;
  }
  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 12px;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 0px 0;
  }
`;

export const EditCarFeaturesTab1 = styled.div`
  .ant-tabs {
    display: grid;
    grid-template-columns: 3fr 9fr;
    grid-gap: 1rem;
    @media ${device.mobileL} {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0rem;
    }
    .ant-tabs-nav {
      width: 100%;
      background: #ffffff;
      border-bottom: 1px solid #e5e7eb;
      border-radius: 8px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media ${device.mobileL} {
        width: auto;
        display: block;
      }
    }
    .ant-tabs-content-holder {
      padding: 1rem;
      background: #ffffff;
      border-radius: 8px;
    }
  }
  .ant-tabs-tabpane-active {
    padding: 0 !important;
  }
  .ant-tabs-content-holder {
    padding: 0 !important;
  }
  .ant-tabs-tab-active {
    border-left: 4px solid #ffb619;
    border-right: 0;
    background: #eeefff;
    @media ${device.mobileL} {
      border-left: none;
      background: transparent;
    }
  }
  .ant-tabs-tab-active::before {
    border-right: 4px solid #ffb619;
    @media ${device.mobileL} {
      border-right: none;
    }
  }
  .ant-tabs-tab-btn {
    font-family: "OutfitRegular";
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    color: #30345e;
    padding: 8px 0px;
    color: #6b7280;
    font-weight: 600;
    @media ${device.mobileL} {
      font-size: 15px;
      padding: 4px 6px;
    }
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    color: #30345e;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: #fff;
    pointer-events: none;
  }

  .ant-tabs .ant-tabs-ink-bar {
    @media ${device.mobileL} {
      position: absolute;
      background: #ffb619;
      pointer-events: none;
    }
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    @media ${device.mobileL} {
      height: 2px;
    }
  }

  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    @media ${device.mobileL} {
      margin: 0 0 0 12px;
    }
  }
`;

export const EditCarFeaturesTab = styled.div`
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  :where(.css-dev-only-do-not-override-1km3mtt).ant-tabs-right
    > .ant-tabs-nav
    .ant-tabs-tab,
  :where(.css-dev-only-do-not-override-1km3mtt).ant-tabs-left
    > div
    > .ant-tabs-nav
    .ant-tabs-tab,
  :where(.css-dev-only-do-not-override-1km3mtt).ant-tabs-right
    > div
    > .ant-tabs-nav
    .ant-tabs-tab {
    padding: 18px 40px;
    text-align: left;
    background: #eeefff;
  }
  .ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #ffb619;
    pointer-events: none;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #30345e;
    font-weight: 600;
    font-size: 18px;
  }
  .ant-tabs .ant-tabs-tab {
    font-size: 18px;
    color: #30345e;
    font-weight: 600;
  }
`;

export const EditFeaturesCheckGroup = styled.div`
  .ant-checkbox-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 6px;
    column-gap: 4px;
    @media ${device.mobileL} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0px;
  }
  .ant-checkbox-wrapper {
    font-size: 15px;
  }
`;

export const SelectCarContainer = styled.div`
  padding: 2rem 0 2rem 0;
  font-family: "Outfit";

  .top-header {
    color: #30345e;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bolder;
  }

  .range-slider-container {
    width: 50%;
    @media ${device.mobileL} {
      width: 100%;
    }
  }

  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid rgba(229, 231, 235, 1);
  }

  .accordion-container {
    padding: 2rem 20rem;
    @media ${device.tablet} {
      padding: 0rem;
    }
    .css-15v22id-MuiAccordionDetails-root {
      padding: 24px 16px 24px;
      @media ${device.mobileL} {
        padding: 16px 14px;
      }
    }
    .accordion {
      .accordion-inner {
        .accordion-title {
          font-size: 1.4rem;
          font-weight: bolder;

          @media ${device.tablet} {
            font-size: 1rem;
          }
        }
        .dealer-container {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-bottom: 1rem;

          .dealer-avatar {
            margin-right: 1rem;
          }
          .dealer-title {
            font-size: 1.4rem;
            font-weight: bolder;

            @media ${device.tablet} {
              font-size: 1rem;
            }
          }
        }
        .make-model {
          display: flex;
          justify-content: space-between;
          gap: 16px;
          margin-bottom: 1rem;
          @media ${device.mobileL} {
            flex-direction: column;
          }

          .select-container {
            width: 100%;
            &:first-child {
              /* margin-right: 1.5rem; */

              @media ${device.tablet} {
                /* margin-right: 1rem; */
              }
            }
            &:last-child {
              /* display: flex; */
              /* flex-direction: column; */
            }
            .label {
              font-weight: bolder;
              font-size: 0.9rem;
              margin-bottom: 0.6rem;
            }
            .ant-select {
              width: 100%;
              &:last-child {
                justify-self: flex-end;
              }
              .ant-select-selector {
                height: 48px;
                background: #f8fafc;
                border-radius: 4px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
          .year {
            width: 100%;
            &:last-child {
              display: flex;
              flex-direction: column;
            }
            .label {
              font-weight: bolder;
              font-size: 0.9rem;
              margin-bottom: 0.6rem;
            }
            .ant-select {
              width: 100%;
              &:last-child {
                justify-self: flex-end;
              }
              .ant-select-selector {
                height: 48px;
                background: #f8fafc;
                border-radius: 4px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
        }
        .search-by-car {
          text-align: center;
          margin: 3rem 0 1.5rem;
          font-weight: bolder;
        }
        .find-car-button {
          display: flex;
          justify-content: center;
        }
      }
    }
    .first-accordion {
      position: relative;

      .accordion-inner {
        /* padding: 14px; */
        border-radius: 18px;
        color: #30345e;
      }
    }

    .second-accordion {
      position: relative;

      .accordion-inner {
        padding: 14px;
        border-radius: 18px;
        color: #30345e;
      }

      &::before {
        content: "2";
        position: absolute;
        top: 14px;
        left: -100px;
        background: #fff;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Outfitbold";
        font-size: 22px;
        border-radius: 50%;
      }
    }

    .single-fields {
      .label {
        font-size: 0.9rem;
        margin-bottom: 0.6rem;
      }
      input {
        background: #fff !important;
      }
      .ant-input-affix-wrapper {
        height: 56px;
        background: #fff;
      }
    }
  }
`;

export const SelectYearContainer = styled.div`
  width: 50%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding-right: 0.6rem;
  margin-bottom: 0.8rem;
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const SettingFormContainer = styled.div`
  .ant-form-item .ant-form-item-label > label {
    font-size: 14px;
    color: #30345e;
    font-weight: 600;
  }
  .ant-input {
    width: 100%;
    max-width: 100%;
    border-radius: 6px;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    font-size: 14px;
  }
  .custom-ant-input {
    height: 48px;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #e2e8f0;
    box-shadow: none;
  }
  img {
    width: 21px !important;
    height: 14px !important;
  }
  /* .ant-input-number .ant-input-number-input {
    height: 48px;
    border-radius: 6px;
    font-size: 14px;
    background: #f8fafc;
  }
  .ant-input-number-group .ant-input-number-group-addon {
    border-radius: 0px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: 1px solid #e2e8f0;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    width: 45px;
    background: #f8fafc;
  }
  .ant-input-number-affix-wrapper {
    border-radius: 6px;
    border: 1px solid #e2e8f0;
    background: #f8fafc;
  } */
  .ant-input-affix-wrapper {
    height: 48px;
    border-radius: 6px;
    font-size: 14px;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
  }
  .ant-input-group .ant-input-group-addon {
    border-radius: 0px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: 1px solid #e2e8f0;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    width: 45px;
    background: #f8fafc;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #e2e8f0;
  }
`;

export const TelCustomField = styled.div`
  .ant-form-item .ant-form-item-label > label {
    font-size: 14px;
    color: #30345e;
    font-weight: 600;
  }
  .ant-input {
    width: 100%;
    max-width: 100%;
    border-radius: 6px;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    font-size: 14px;
  }
  .custom-ant-input {
    height: 56px;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #e2e8f0;
    box-shadow: none;
  }
  img {
    width: 21px !important;
    height: 14px !important;
  }
  .ant-input-affix-wrapper {
    height: 56px;
    border-radius: 6px;
    font-size: 14px;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
  }
  .ant-input-group .ant-input-group-addon {
    border-radius: 0px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: 1px solid #e2e8f0;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    width: 45px;
    background: #f8fafc;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #e2e8f0;
  }
`;

export const InputFieldStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "4px",
    backgroundColor: "#F8FAFC",
    fontFamily: "OutfitRegular",
    boxShadow: "none",
    height: "48px",
    "&.Mui-disabled": {
      backgroundColor: "#f0f0f0",
      borderColor: "#E5E7EB",
      cursor: "not-allowed",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#30345e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E5E7EB",
      boxShadow: "none",
    },
    "&:hover fieldset": {
      borderColor: "#E5E7EB",
      boxShadow: "none",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #d0d2d5",
      boxShadow: "none",
    },
    "&.Mui-disabled fieldset": {
      borderColor: "#E5E7EB",
      cursor: "not-allowed",
    },
  },
};

export const TextAreaFieldStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "4px",
    backgroundColor: "#F8FAFC",
    fontFamily: "OutfitRegular",
    boxShadow: "none",
  },
  "& .MuiInputLabel-root": {
    color: "#30345e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E5E7EB",
      boxShadow: "none",
    },
    "&:hover fieldset": {
      borderColor: "#E5E7EB",
      boxShadow: "none",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E5E7EB",
      boxShadow: "none",
    },
  },
};

export const InputLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: "#30345E",
  mb: 1,
  fontFamily: "OutfitRegular",
};

export const FormImageLabelStyle = {
  fontSize: "14px",
  fontWeight: "600",
  color: "#30345E",
  mb: 1,
  fontFamily: "OutfitRegular",
};

export const FormLabelStyle = {
  fontSize: "14px",
  fontWeight: "500",
  color: "#30345E",
  // mb: 1,
  fontFamily: "OutfitRegular",
  whiteSpace: "pre-line",
};

export const FormAutoCompleteStyle = {
  "& .MuiAutocomplete-inputRoot": {
    backgroundColor: "#F8FAFC",
    borderRadius: "6px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E5E7EB",
    },
    "&:hover fieldset": {
      borderColor: "#E5E7EB",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #d0d2d5",
      boxShadow: "none",
    },
  },
};

export const FormMuiSelectStyle = {
  backgroundColor: "#F8FAFC",
  borderRadius: "6px",
  fontFamily: "OutfitRegular",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#E5E7EB",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E5E7EB",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E5E7EB",
  },
  transition: "border-color 0.3s ease",
};

export const FormNumbersStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "6px",
    backgroundColor: "#F8FAFC",
    fontFamily: "OutfitRegular",
    boxShadow: "none",
  },
  "& .MuiInputLabel-root": {
    color: "#30345e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E5E7EB",
      boxShadow: "none",
    },
    "&:hover fieldset": {
      borderColor: "#E5E7EB",
      boxShadow: "none",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #d0d2d5",
      boxShadow: "none",
    },
  },
};

export const FormDropdownStyle = {
  "& .MuiAutocomplete-inputRoot": {
    backgroundColor: "#F8FAFC",
    borderRadius: "4px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E5E7EB",
    },
    "&:hover fieldset": {
      borderColor: "#E5E7EB",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #d0d2d5",
      boxShadow: "none",
    },
    "&.Mui-disabled fieldset": {
      borderColor: "#E5E7EB",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f0f0f0",
      cursor: "not-allowed",
    },
  },
};

export const confirmStyle = {
  background: "#FFB619",
  borderRadius: 60,
  color: "#30345E",
  fontWeight: 600,
  border: 0,
  height: 48,
  minWidth: 101,
};

export const deactivateConfirmStyle = {
  background: "#DC2626",
  borderRadius: 60,
  color: "#fff",
  fontWeight: 600,
  border: 0,
  height: 48,
  minWidth: 101,
  width: "auto",
};

export const cancelStyle = {
  background: "#fff",
  borderRadius: 60,
  color: "#30345E",
  fontWeight: 600,
  border: "1px solid #30345E",
  height: 48,
  minWidth: 101,
};

export const paragraphStyle = {
  fontSize: 15,
  fontWeight: 400,
  marginBottom: 24,
  color: "#4B5563",
};

export const titleStyle = {
  fontSize: 22,
  fontWeight: 600,
  color: "#30345E",
};
