import React, { useState, useEffect, useRef } from "react";
import {
  ApplyLoanButton,
  CarContentContainer,
  CarMediaContainer,
  CarMediaMobile,
  InventoryDetailsPage,
  LoanDetailsHeader,
  TableContainer,
} from "@/layouts/application-layouts/styles";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Switch,
  Image,
  Button,
  Carousel,
  Card,
  Space,
  Tag,
  Empty,
  Dropdown,
} from "antd";
import type { MenuProps } from "antd";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import { useQuery } from "@tanstack/react-query";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import formatInt from "@/utils/format-int";
import { device } from "@/utils/device";
import ListCarModal from "@/layouts/application-layouts/ListCarModal";
import DelistCarModal from "@/layouts/application-layouts/DelistCarModal";
import { ImageLoader } from "@/utils/ImageLoader";
import RequestInspectionModal from "@/layouts/application-layouts/RequestInspectionModal";
import { useTranslation } from "react-i18next";
import { Form, Inputs } from "../auth/styles/sign-in";
import GethelpInput from "@/components/blocks/inputs/getheipinput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoanCalculatorModal } from "@/components/LoanCalculatorModal";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import InspectionResults from "@/components/InspectionResults";
import {
  AvgRatingIcon,
  HighRatingIcon,
  LowRatingIcon,
  TextEditIcon,
  ThreeDotsIcon,
} from "@/assets/svgs";
import { Loader } from "../auth/styles/loader";
import { ICarMedias } from "@/interface/inventory-alerts";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .preview-container {
    display: none;
    @media ${device.mobileL} {
      display: none;
    }
  }
`;

const RequestInspectionButton = styled(Button)`
  height: 32px;
  background: #ffb619;
  border-radius: 60px;
  border: 0;
  span {
    color: #30345e;
    font-weight: 600;
    font-size: 14px;
  }
  &:hover {
    text-decoration: none;
    background-color: rgb(255, 182, 25);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
      rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    box-shadow: none;
    border: 1px solid #d9d9d9;
  }
  margin-right: 2rem;
`;

const SwtichWrap = styled.div`
  .ant-switch.ant-switch-checked {
    background: #22c55e !important;
  }
  .ant-switch {
    height: 1.9276rem;
    width: 3.5rem;
  }
  .ant-switch .ant-switch-handle {
    top: 3.5px;
    width: 23px;
    height: 23px;
  }
  .ant-switch .ant-switch-handle::before {
    border-radius: 18px;
  }
  .ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 27px);
  }
`;

interface IRepoOffer {
  amount: string;
}

const InventoryDetails = () => {
  const { carId }: any = useParams();
  const navigate = useNavigate();
  const country = storage.getCountry();
  const role = storage.getRole();
  const queryParameters = new URLSearchParams(window.location.search);
  const [visible, setVisible] = useState(false);
  const [scaleStep] = useState(0.5);
  const isFranchise = storage.utilities.isFranchise();
  const [activeTabKey1, setActiveTabKey1] = useState<string>("tab1");
  const [carById, setCarById]: any = useState({});
  const [carMedia, setCarMedia] = useState<ICarMedias[]>([]);
  const [carFeature, setCarFeature]: any = useState([]);
  const [inspectionId, setInspectionId] = useState("");
  const [checkListed, setCheckListed] = useState(false);
  const [listCar, setListCar] = useState(false);
  const [delistCar, setDelistCar] = useState(false);
  const [carData, setCarData] = useState("");
  const [carDetails, setCarDetails] = useState({});
  const [reqInspection, setReqInspection] = useState(false);
  const { t } = useTranslation("inventory");
  const [hasInspectionRequest, setHasInspectionRequest] = useState(true);
  const isDsaAgent = storage.utilities.isDsaAgent();
  const repoCheck = queryParameters.get("repo");
  const isAssumedUser = storage.getAssumedUser();
  const [openCalculator, setOpenCalculator] = useState(false);
  const [viewMoreActions, setViewMoreActions] = useState(false);
  const filterRef = useRef<any>(null);
  const totalImgLength: number = 4;

  useEffect(() => {
    document.title = `Dealer Plus | Car Details [${role}]`;
  }, [role]);

  const toLoan = (id: string) => {
    navigate(`/applications/loans?car_id=${id}&form_type=new`);
  };

  const toEdit = (id: string) => {
    navigate(`/inventory/${id}/edit`);
  };

  const showListCar = (data: any) => {
    setCarData(data);
    setListCar(true);
  };

  const handleOpenListCar = () => {
    setListCar(false);
  };

  const handleCancelListCar = () => {
    setListCar(false);
  };

  const showDelistCar = (data: any) => {
    setCarData(data);
    setDelistCar(true);
  };

  const handleOpenDelistCar = () => {
    setDelistCar(false);
  };

  const handleCancelDelistCar = () => {
    setDelistCar(false);
  };

  const showReqInspection = (data: any) => {
    setCarDetails(data);
    setReqInspection(true);
  };

  const handleOpenReqInspection = () => {
    setReqInspection(false);
  };

  const handleCancelReqInspection = () => {
    setReqInspection(false);
  };

  const { isLoading: carAdminLoading } = useQuery({
    queryKey: ["fetch_admin_cars_by_id", carId],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/inventory/admin/car/${carId}`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarById(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: carMediaLoading } = useQuery({
    queryKey: ["car_media_by_id", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId,
        page_number: "1",
        page_size: "20",
      });
      return client.get(`/v1/inventory/car_media`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      const filteredMedia = parsedResults?.carMediaList?.filter(
        (item: ICarMedias) => {
          return item?.name !== "Thumbnail";
        }
      );
      setCarMedia(filteredMedia);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: carFeaturesLoading } = useQuery({
    queryKey: ["car_feature_by_id", carId],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId,
        page_number: "1",
        page_size: "1000",
      });
      return client.get(`/v1/inventory/car_feature`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setCarFeature(parsedResults);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["get_inspection_id", carId, country],
    queryFn: () => {
      const params = new URLSearchParams({
        car_id: carId,
        country: country,
        type: "regular",
      });
      return client.get(`/v1/inspection`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      setInspectionId(parsedResults?.result[0]?.id);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["get_inspection_request", carById, country],
    queryFn: () => {
      if (!carById?.vin) {
        return;
      }
      const params = new URLSearchParams({
        vin: carById?.vin,
        country: country,
        type: "dealer-regular",
      });
      return client.get(`/v1/inventory/inspection_request`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResults = JSON.parse(res?.data);
      if (parsedResults?.inspectionRequests?.length > 0) {
        setHasInspectionRequest(true);
      } else setHasInspectionRequest(false);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const tabList = [
    {
      key: "tab1",
      tab: t("inspection-report"),
    },
    {
      key: "tab2",
      tab: t("car-features"),
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() => toEdit(carId)}
          className="flex gap-2 text-sm text-[#30345E] font-semibold"
        >
          <TextEditIcon /> Edit Car
        </button>
      ),
      key: "0",
    },
  ];

  const renderFeaturesTags = () => {
    return (
      <div>
        {carFeature?.carFeatureList?.length > 0 ? (
          <Space size={[4, 16]} wrap>
            {carFeature?.carFeatureList?.map((elm: any) => (
              <Tag key={elm?.id} color="#E5E7EB" className="font-outfit">
                {elm?.feature?.name}
              </Tag>
            ))}
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    );
  };

  const contentList: Record<string, React.ReactNode> = {
    tab1: <InspectionResults carId={carId} damages={carById.damages} />,
    tab2: renderFeaturesTags(),
  };

  const onTab1Change = (key: string) => {
    setActiveTabKey1(key);
  };

  useEffect(() => {
    if (carById?.marketplaceVisible) {
      setCheckListed(true);
    } else {
      setCheckListed(false);
    }
  }, [carById]);

  const handleListCar = (checked: boolean) => {
    if (checked) {
      setCheckListed(true);
      showListCar(carId);
    } else if (!checked) {
      setCheckListed(false);
      showDelistCar(carId);
    }
  };

  const handleCarousel = (currentSlide: number) => {};

  const useFinancingonChange = (checked: boolean) => {
    return checked;
  };

  const schema = yup
    .object({
      amount: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRepoOffer>({
    resolver: yupResolver(schema),
  });

  const handleSubmitOffer = (data: IRepoOffer) => {
    return data;
  };

  const HIRequestButton = () => {
    if (hasInspectionRequest) {
      return { text: t("pending-inspection"), disabled: true };
    } else {
      return {
        text: t("request-inspection"),
        disabled: false,
        onClick: () => showReqInspection(carById),
      };
    }
  };

  const HIRequestButtonProps = HIRequestButton();
  const transmission = carById?.transmission || "---";
  const fuelType = carById?.fuelType || "---";
  const engineType = carById?.engineType || "---";
  const bodyTypeName = carById?.bodyType?.name || "---";
  const exteriorColor = carById?.exteriorColor || "---";
  const interiorColor = carById?.interiorColor || "---";
  const displayTransmission = capitalFirstLetter(transmission);
  const displayFuelType = capitalFirstLetter(fuelType);
  const displayEngineType = capitalFirstLetter(engineType);
  const displayBodyTypeName = capitalFirstLetter(bodyTypeName);
  const displayExteriorColor = capitalFirstLetter(exteriorColor);
  const displayInteriorColor = capitalFirstLetter(interiorColor);
  const year = carById?.year || "---";
  const make = carById?.model?.make?.name || "---";
  const model = carById?.model?.name || "---";
  const city = carById?.city || "---";
  const cityDisplay = capitalFirstLetter(city);
  const state = carById?.state || "---";
  const stateDisplay = capitalFirstLetter(state);
  const locationDisplay = `${cityDisplay}, ${stateDisplay}`;
  const carInfo = `${year} ${make} ${model} ${carById?.trim?.name || ""}`;

  // const carActivities = [
  //   {
  //     title: "No. of views",
  //     value: 0,
  //   },
  //   {
  //     title: "No. of leads",
  //     value: 0,
  //   },
  //   {
  //     title: "Loan applications",
  //     value: 0,
  //   },
  //   {
  //     title: "Completed applications",
  //     value: 0,
  //   },
  //   {
  //     title: "Conditional offers",
  //     value: 0,
  //   },
  //   {
  //     title: "Final offers",
  //     value: 0,
  //   },
  // ];

  const renderCardInspections = () => {
    return (
      <Card
        className="card-two"
        style={{ width: "100%" }}
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={onTab1Change}
      >
        {contentList[activeTabKey1]}
      </Card>
    );
  };

  const displayRatingsText = (value: number): string => {
    if (value >= 4 && value <= 5) {
      return "Great listing score. Your car is more likely to be visible to most customers";
    } else if (value >= 3 && value < 4) {
      return "Your car is more likely to be visible to 40% of customers. Update details to boost score";
    } else {
      return "Your car may not be visible to 80% customers. Update details to boost score";
    }
  };

  const displayRatingsIcon = (value: number) => {
    if (value >= 4 && value <= 5) {
      return <HighRatingIcon />;
    } else if (value >= 3 && value < 4) {
      return <AvgRatingIcon />;
    } else {
      return <LowRatingIcon />;
    }
  };

  if (carAdminLoading || carMediaLoading || carFeaturesLoading) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  const calculateFallbackGrid = (length: number) => {
    if (length < totalImgLength) {
      return totalImgLength - length;
    }
    return 0;
  };

  return (
    <>
      <Main>
        <LoanDetailsHeader>
          {isFranchise ? (
            <div>
              <h1 className="flex items-center gap-2 text-xl text-[#30345E] font-semibold font-outfit mb-1 md:mb-0 md:leading-relaxed">
                Listing score: {carById?.listingScore}{" "}
                {displayRatingsIcon(
                  Number(Number(carById?.listingScore)?.toFixed(1))
                )}
              </h1>
              <p className="text-sm text-[#30345E] font-light font-outfit leading-tight">
                {displayRatingsText(
                  Number(Number(carById?.listingScore)?.toFixed(1))
                )}
              </p>
            </div>
          ) : (
            <button className="loan-header" onClick={() => navigate(-1)}>
              <img
                src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
                alt="arrow-back-img"
              />{" "}
              <div className="name">{t("car-details")}</div>
            </button>
          )}

          {!isDsaAgent && (
            <div className="loan-header">
              {!carById?.marketplaceVisible && (
                <div className="apply-loan-container">
                  {!carById.inspected && (
                    <RequestInspectionButton
                      onClick={HIRequestButtonProps.onClick}
                      disabled={
                        HIRequestButtonProps.disabled || !!isAssumedUser
                      }
                      className="apply-loan-btn"
                    >
                      {HIRequestButtonProps?.text}
                    </RequestInspectionButton>
                  )}
                </div>
              )}
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="name">
                    {carById?.marketplaceVisible
                      ? t("delist-car")
                      : t("list-car")}
                  </div>
                  <Switch
                    onChange={handleListCar}
                    loading={carAdminLoading}
                    checked={checkListed}
                    disabled={
                      (!inspectionId && !isFranchise) || !!isAssumedUser
                    }
                  />
                </div>
                {isFranchise && (
                  <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                    placement="bottomRight"
                    className=""
                  >
                    <button aria-label="Open Edit Car">
                      <ThreeDotsIcon />
                    </button>
                  </Dropdown>
                )}
              </div>
            </div>
          )}
        </LoanDetailsHeader>

        <InventoryDetailsPage>
          {/* CAR MEDIA */}
          <CarMediaContainer className="hidden lg:block">
            <div className="car-media-container">
              <div className="img-one">
                <img
                  src={ImageLoader({
                    src: `${carById?.imageUrl}`,
                    width: 400,
                    quality: 100,
                  })}
                  alt="car-img"
                />
              </div>
              <div className="media-group-two">
                {carMedia?.slice(0, 4)?.map((elm: any) => (
                  <div className="img-two-one" key={elm?.id}>
                    <img
                      src={ImageLoader({
                        src: elm?.url,
                        width: 400,
                        quality: 100,
                      })}
                      alt="car-img"
                    />
                  </div>
                ))}
                {Array(
                  calculateFallbackGrid(carMedia?.slice(0, 4)?.length)
                ).fill(
                  <div className="w-full h-[237px] flex items-center justify-center bg-neutral-200 overflow-hidden">
                    <img
                      src="https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg"
                      alt="fallback"
                      className="object-contain"
                    />
                  </div>
                )}
              </div>
              <button className="show-photos" onClick={() => setVisible(true)}>
                <img
                  src="https://media.autochek.africa/file/publicAssets/Frame-106.svg"
                  alt="screen-img"
                />{" "}
                {t("show-all-photos")}
              </button>
            </div>
          </CarMediaContainer>

          <CarMediaMobile className="mb-5 block lg:hidden">
            <Carousel afterChange={handleCarousel} autoplay>
              {carMedia?.map((elm: Record<string, any>) => (
                <div key={elm?.id}>
                  <div className="content-style">
                    <img
                      src={ImageLoader({
                        src: `${elm?.url}`,
                        width: 400,
                        quality: 75,
                      })}
                      alt="car-img"
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </CarMediaMobile>
          {/* CAR MEDIA */}

          <CarContentContainer className="flex flex-col xl:flex-row gap-[24px]">
            {/* FIRST SECTION */}
            <div className="car-content-one-wrapper w-full max-w-full xl:max-w-[65%]">
              <Space direction="vertical" size={24} style={{ width: "100%" }}>
                <div className="card-one bg-white divide-[#f0f0f0] divide-y border border-solid border-[#f0f0f0] rounded-lg">
                  <div className="title-container p-[14px]">
                    <div className="name font-outfit font-semibold">
                      <div>{carInfo}</div>
                      {!repoCheck && (
                        <div className="franchise-name font-outfit font-semibold">
                          {t("franchise")} : {carById?.carManagerName}
                        </div>
                      )}
                    </div>
                    <div className="detail">
                      <div className="location">
                        <img
                          src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-1-0.svg"
                          alt="location-icon"
                        />{" "}
                        {locationDisplay}
                      </div>
                      <div className="detail-name">
                        <span className="rate-wrap">
                          <img
                            src="https://media.autochek.africa/file/publicAssets/star.svg"
                            alt="rate-icon"
                          />{" "}
                          {carById?.gradeScore?.toFixed(1) || "---"}
                        </span>
                        <span> | </span>
                        <span>
                          {t("id")}: {carId || "---"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-[14px]">
                    <div className="overview">{t("overview")}</div>
                    <div className="car-description-wrapper">
                      <div>
                        <div className="title">{t("fuel-type")}</div>
                        <div className="content">{displayFuelType}</div>
                      </div>
                      <div>
                        <div className="title">{t("Transmission")}</div>
                        <div className="content">{displayTransmission}</div>
                      </div>
                      <div>
                        <div className="title">{t("engine-type")}</div>
                        <div className="content">{displayEngineType}</div>
                      </div>
                      <div>
                        <div className="title">{t("body-style")}</div>
                        <div className="content">{displayBodyTypeName}</div>
                      </div>
                      <div>
                        <div className="title">{t("exterior-color")}</div>
                        <div className="content">{displayExteriorColor}</div>
                      </div>
                      <div>
                        <div className="title">{t("interior-color")}</div>
                        <div className="content">{displayInteriorColor}</div>
                      </div>
                    </div>
                  </div>
                  {carById?.summary && (
                    <div className="p-[14px]">
                      <h3 className="font-semibold text-base xl:text-xl text-[#30345e] mb-2">
                        Summary
                      </h3>
                      <p className="text-xs md:text-sm text-[#4B5563] font-normal">
                        {carById?.summary}
                      </p>
                    </div>
                  )}
                </div>

                <div className="hidden xl:block">{renderCardInspections()}</div>
              </Space>
            </div>

            {/* SECOND SECTION */}
            <div className="block xl:block w-full max-w-full xl:max-w-[35%]">
              <div className="car-content-two-wrapper">
                <div className="card-three w-full bg-white divide-[#f0f0f0] divide-y border border-solid border-[#f0f0f0] rounded-lg">
                  <div className={`title-container`}>
                    <div className="price">
                      <img
                        src="https://media.autochek.africa/file/publicAssets/cash-outline.svg"
                        alt="price-icon"
                      />{" "}
                      {t("price")}
                    </div>
                    {repoCheck && (
                      <div
                        className={`amount ${
                          repoCheck &&
                          `text-base font-bold text-[#30345E] tracking-[0.01rem]`
                        } `}
                      >
                        {formatInt(carById?.marketplacePrice, true)}
                      </div>
                    )}
                    {!repoCheck && (
                      <div className="installment-amount">
                        <div className="instalment">
                          {formatInt(carById?.installment, true)} / {t("mo")}
                        </div>
                        <div className="amount">
                          {formatInt(carById?.marketplacePrice, true)}
                        </div>
                      </div>
                    )}
                    {!repoCheck && (
                      <div className="note">
                        {t("estimated-payment-excludes-taxes")}.
                      </div>
                    )}
                  </div>
                  {repoCheck && (
                    <div className="p-4">
                      <div className="flex justify-between items-center">
                        <div>
                          <span className="text-[#30345E] text-base font-bold tracking-[0.01rem]">
                            Use financing
                          </span>
                        </div>
                        <SwtichWrap>
                          <Switch
                            defaultChecked
                            onChange={useFinancingonChange}
                          />
                        </SwtichWrap>
                      </div>
                      <div className="flex justify-between items-center pt-4">
                        <div>
                          <span className="text-[#30345E] text-base font-bold tracking-[0.01rem]">
                            Make an offer
                          </span>
                        </div>
                        <div>
                          <span className="text-[#6B7280]">
                            Offer must be X% of car price
                          </span>
                        </div>
                      </div>
                      <div className="pt-2">
                        <Form
                          onSubmit={handleSubmit(handleSubmitOffer)}
                          autoComplete="off"
                        >
                          <Inputs>
                            <GethelpInput
                              label={""}
                              type="text"
                              placeholder={"Enter amount"}
                              {...register("amount")}
                              error={errors.amount}
                            />
                          </Inputs>
                          <div
                            className="apply-container"
                            style={{ padding: 0, marginTop: "-10px" }}
                          >
                            <button className="submit-offer-btn font-bold">
                              Submit Offer
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  )}

                  {/* ACTION BUTTONS */}
                  <div className="grid grid-cols-2 relative gap-4 px-3 py-4">
                    <button
                      onClick={() => setViewMoreActions(!viewMoreActions)}
                      ref={filterRef}
                      className=" text-[#30345E] h-fit text-sm border border-solid border-[#30345E] font-semibold rounded-full flex justify-center py-3 my-auto"
                    >
                      More Actions{" "}
                      <span>
                        <ChevronDownIcon
                          style={{
                            width: "22px",
                            marginLeft: "10px",
                          }}
                        />
                      </span>
                    </button>
                    {viewMoreActions && (
                      <div className="absolute bottom-0 left-0 top-[90px] w-1/2">
                        <div className="actions bg-white  shadow-md rounded-md text-[#6B7280] font-bold text-center">
                          <div className="border-b py-3 cursor-pointer hover:bg-gray-100">
                            <button
                              disabled={
                                (!inspectionId && !isFranchise) ||
                                !!isAssumedUser
                              }
                              onClick={() => toEdit(carId)}
                            >
                              {t("edit-car")}
                            </button>
                          </div>
                          <div className="">
                            <button
                              onClick={() => {
                                carById?.marketplaceVisible
                                  ? showDelistCar(carId)
                                  : showListCar(carId);
                              }}
                              className="disabled:cursor-not-allowed w-full py-3 cursor-pointer hover:bg-gray-100"
                              disabled={
                                (!inspectionId && !isFranchise) ||
                                !!isAssumedUser
                              }
                            >
                              {carById?.marketplaceVisible
                                ? t("delist-car")
                                : t("list-car")}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {!repoCheck && (
                      <div className="apply-container !p-0">
                        <ApplyLoanButton
                          className="w-full"
                          onClick={() => toLoan(carId)}
                          disabled={!!isAssumedUser}
                        >
                          {t("apply-for-loan")}
                        </ApplyLoanButton>
                      </div>
                    )}
                  </div>
                </div>

                {/* CAR ACTIVITIES */}
                {/* {isFranchise && (
                  <div className="border rounded-lg p-[14px] bg-white">
                    <h2 className="font-bold text-layout-aside-scene my-2">
                      Activities on this car
                    </h2>
                    <div className="grid grid-cols-3 gap-4">
                      {carActivities.map((activity) => (
                        <div key={activity.title} className="">
                          <p className="text-xs text-[#6B7280]">
                            {activity.title}
                          </p>
                          <p className="text-base font-bold text-layout-aside-scene">
                            {activity.value}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="delisted mt-3">
                      <p className="text-xs text-[#6B7280]">Delisted by</p>
                      <div className="font-bold text-layout-aside-scene text-base">
                        Autochek
                      </div>
                    </div>
                  </div>
                )} */}

                {/* LOAN CALCULATOR */}
                <div className=" bg-white rounded-lg border">
                  <div className="flex justify-between">
                    <img
                      src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/calculate-loan-car"
                      alt=""
                      className="w-[50%]"
                    />
                    <Button
                      type="text"
                      style={{
                        color: "#EEEFFF",
                        backgroundColor: "#30345E",
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 18px",
                        margin: "auto 0",
                        fontWeight: 600,
                        marginRight: 30,
                      }}
                      onClick={() => setOpenCalculator(true)}
                    >
                      Use loan calculator
                    </Button>
                  </div>
                </div>

                {/* INSPECTION AND FEATURES TABLE */}
                <div className="block xl:hidden">
                  <div className="car-content-one-wrapper">
                    {renderCardInspections()}
                  </div>
                </div>
              </div>
            </div>
          </CarContentContainer>
        </InventoryDetailsPage>
      </Main>

      {/* PREVIEW CAR IMAGE GALLERY COMPONENT */}
      <div className="preview-container hidden">
        <Image.PreviewGroup
          preview={{
            visible,
            scaleStep,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        >
          {carMedia?.map((elm) => (
            <div key={elm?.id}>
              <Image
                style={{ display: "none" }}
                width={200}
                src={elm?.url}
                alt="car-img"
              />
            </div>
          ))}
        </Image.PreviewGroup>
      </div>

      <ListCarModal
        isModalOpen={listCar}
        handleOk={handleOpenListCar}
        handleCancel={handleCancelListCar}
        carId={carData}
      />
      <DelistCarModal
        isModalOpen={delistCar}
        handleOk={handleOpenDelistCar}
        handleCancel={handleCancelDelistCar}
        carId={carData}
      />
      <RequestInspectionModal
        isModalOpen={reqInspection}
        handleOk={handleOpenReqInspection}
        handleCancel={handleCancelReqInspection}
        carData={carDetails}
      />
      <LoanCalculatorModal
        car={carById}
        carId={carById?.id}
        loading={carAdminLoading}
        isModalOpen={openCalculator}
        handleOk={() => setOpenCalculator(false)}
        handleCancel={() => setOpenCalculator(false)}
      />
    </>
  );
};

export default InventoryDetails;
