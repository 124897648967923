import { useTranslation } from "react-i18next";
import StageCard from "./StageCard";

const FinancingStepOne = () => {
  const { t } = useTranslation("dealer-gamification");

  return (
    <div>
      <div>
        <h4 className="font-outfitBold custom-font-bold font-bold text-left lg:text-center text-base lg:text-2xl text-[#30345E]">
          {t("how-to-qualify-for-financing")}
        </h4>
        <p className="font-medium text-left lg:text-center text-sm lg:text-base text-[#6B7280]">
          {t("you-need-to-be-on-any-of-these-dealership-levels")}
        </p>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-5">
        <StageCard
          medal_url={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Bronze.png?updatedAt=1700813945032`}
          dealer_stage={t("bronze-stage")}
          stage_desc={t("sell-one-200")}
        />
        <StageCard
          medal_url={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals.png?updatedAt=1700813944875`}
          dealer_stage={t("silver-stage")}
          stage_desc={t("sell-three-600")}
        />
        <StageCard
          medal_url={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-1.png?updatedAt=1700813944709`}
          dealer_stage={t("gold-stage")}
          stage_desc={t("sell-six-1200")}
        />
        <StageCard
          medal_url={`https://ik.imagekit.io/eo0k4ohmt/dealer-plus/dealer-medals/Medals-2.png?updatedAt=1700813944845`}
          dealer_stage={t("platinum-stage")}
          stage_desc={t("sell-twelve-2400")}
        />
      </div>
    </div>
  );
};

export default FinancingStepOne;
