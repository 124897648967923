import { useState } from "react";
import { fetchLoanStatement } from "@/hooks/use-api";
import { Loader } from "@/pages/auth/styles/loader";
import handleDownloadBase64toPdf from "@/utils/base64-to-pdf";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";

interface ILoanStatementProps {
  loanId: string;
}

const DownloadLoanStatement: React.FC<ILoanStatementProps> = ({ loanId }) => {
  const { t: t2 } = useTranslation("dealer-gamification");
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { isFetching: loadingLoanDocument } = useQuery({
    queryKey: ["download_loan_statements", loanId],
    queryFn: () => {
      return fetchLoanStatement(loanId);
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      handleDownloadBase64toPdf(parsedResponse?.data);
      setIsDownloading(false);
    },
    onError: (error: any) => {
      const err =
        JSON.parse(error?.response?.data)?.error ||
        JSON.parse(error?.response?.data)?.message;
      message.error(err ?? "Error regenerating loan statement");
      setIsDownloading(false);
    },
    enabled: !!isDownloading,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <button
      onClick={() => setIsDownloading(true)}
      className="min-w-[97px] w-fit h-8 md:h-9 text-sm text-[#30345e] font-semibold px-4 bg-white !border !border-solid border-[#30345e] rounded-full disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
    >
      {loadingLoanDocument ? <Loader variant="secondary" /> : t2("download")}
    </button>
  );
};

export default DownloadLoanStatement;
