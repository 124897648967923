import create from "zustand";
import { message } from "antd";
import client from "@/lib/axios-client";
import { ILoansById } from "@/interface/loansById";

interface LoansByIdStore {
  loansByIdLoading: boolean;
  loansById: ILoansById | undefined;
  fetchLoansById: (id: string) => Promise<void>;
}

const useLoansByIdStore = create<LoansByIdStore>((set) => ({
  loansByIdLoading: false,
  loansById: undefined,
  fetchLoansById: async (loanId: string) => {
    try {
      set({ loansByIdLoading: true });
      const response = await client.get(`/v1/origination/loans/${loanId}`);
      set({
        loansById: JSON.parse(response?.data),
        loansByIdLoading: false,
      });
    } catch (err: any) {
      set({ loansByIdLoading: false });
      message.error(err?.response?.data?.message || "Error fetching loans");
    }
  },
}));

export default useLoansByIdStore;
