import InputConfig from "@/components/formElements/mui-form/input-config";
import { FormSectionProps } from "@/components/formElements/mui-form/types";
import useCarFormStore from "@/states/create-car";
import { getSection } from "./helper";
import SectionWrapper from "./section-wrapper";

const CarSnapshotsSection: React.FC<FormSectionProps> = ({
  config,
  data,
  methods,
  dependencies,
}) => {
  const sectionStep = 2;
  const { currentStep, completedSteps, setAlert } = useCarFormStore();
  if (currentStep !== sectionStep) return null;

  const section = getSection("carSnapshots", config);
  const isCompleted = completedSteps.includes(sectionStep);

  return (
    <SectionWrapper step={sectionStep} section={section}>
      <InputConfig
        step={sectionStep}
        section={section}
        setAlert={setAlert}
        data={data}
        methods={methods}
        dependencies={dependencies}
        isCompleted={isCompleted}
      />
    </SectionWrapper>
  );
};

export default CarSnapshotsSection;
