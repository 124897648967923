import { DealerFinancingStepper } from "@/assets/svgs";
import { useTranslation } from "react-i18next";

const FinancingStepTwo = () => {
  const { t } = useTranslation("dealer-gamification");

  const why_loan_limit = [
    {
      id: 1,
      heading: t("fill-out-the-loan-limit-approval-form"),
      text: t("this-will-help-us-determine-your-loan-limit"),
    },
    {
      id: 2,
      heading: t("sign-master-agreement-contract"),
      text: t("you-will-need-to-sign-the-loan-agreement-contracts"),
    },
    {
      id: 3,
      heading: t("start-applying-for-loans"),
      text: t(
        "once-we-have-verified-your-collaterals-you-have-full-access-to-the-loan-facilities"
      ),
    },
  ];

  return (
    <div className="px-4">
      <div>
        <p className="text-xl lg:text-2xl text-left lg:text-center font-bold custom-font-bold text-[#30345E]">
          {t("why-do-i-need-to-have-a-loan-limit")}?
        </p>
        <p className="text-sm lg:text-base text-left lg:text-center text-[#30345E] leading-5 pt-2 max-w-lg lg:mx-auto">
          {t("your-loan-limit-helps")}
        </p>
      </div>
      <div className="pt-6">
        <p className="text-xl lg:text-2xl font-bold text-left lg:text-center custom-font-bold text-[#30345E]">
          {t("how-it-works")}
        </p>
        <div className="pt-4">
          <ul className="grid grid-cols-1 lg:grid-cols-3 grid-rows-auto gap-x-5">
            {why_loan_limit.map((item) => {
              return (
                <li
                  key={item.id}
                  className="border-b lg:border lg:border-solid lg:border-[#e5e7eb] py-3 bg-transparent lg:bg-white p-0 lg:p-4 rounded-lg"
                >
                  <div className="flex flex-row md:flex-row lg:flex-col space-y-0 lg:space-y-3 space-x-3 lg:space-x-0">
                    <div>
                      <DealerFinancingStepper />
                    </div>
                    <div className="max-w-md">
                      <div className="leading-relaxed font-bold text-[#30345E] text-lg custom-font-bold tracking-[0.01rem]">
                        {item.heading}
                      </div>
                      <div className="text-sm lg:text-base text-[#30345E]">
                        {item.text}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FinancingStepTwo;
