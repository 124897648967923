import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import storage from "@/lib/storage";
import {
  getProduct,
  getProductData,
  fetchCountryId,
  getStates,
  getCities,
  getBanks,
  getCountries,
  getAboutUs,
  getFranchise,
} from "@/hooks/use-loan";
import InputGroup from "@/components/formElements/input-group";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import {
  CheckEligibilityFormContainer,
  TableContainer,
} from "@/layouts/application-layouts/styles";
import { convertStringToBoolean } from "@/utils/stringToBoolean";
import { useTranslation } from "react-i18next";
import { Loader } from "../auth/styles/loader";
import { FieldItem, LoanFieldItem } from "@/types";
import {
  Box,
  Container,
  PaletteColorOptions,
  Stack,
  ThemeProvider,
  createTheme,
} from "@mui/material";

declare module "@mui/material/styles" {
  interface CustomPalette {
    custom: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    custom: true;
  }
}

const Loans = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const carId: any = queryParameters.get("car_id");
  const loan_Id: any = queryParameters.get("loan_id");
  const new_loan: any = queryParameters.get("form_type");
  const country = storage.getCountry();
  const user = storage.getUser();
  const role = storage.getRole();
  const isAccountManager = storage.utilities.isAccountManager();
  const isFranchise = storage.utilities.isFranchise();
  const isDsaAgent = storage.utilities.isDsaAgent();
  const isDealerDsa = storage.utilities.isDealerDsa();
  let selectOptions: any = {};
  const [loanFields, setLoanFields]: any = useState({});
  const [loanProduct, setLoanProduct]: any = useState();
  const [countryId, setCountryId]: any = useState();
  const [productData, setProductData]: any = useState({});
  const [alert, setAlert]: any = useState("");
  const [expanded, setExpanded] = useState(0);
  const [completed, setCompleted]: any = useState({});
  const [directorCount, setDirectorCount] = useState(1);
  const [state, setState]: any = useState([]);
  const [city, setCity]: any = useState([]);
  const [banks, setBanks]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries]: any = useState([]);
  const [successAlert, setSuccessAlert] = useState("");
  const [aboutUs, setAboutUs]: any = useState([]);
  const [franchise, setFranchise]: any = useState([]);
  const [loanId, setLoanId] = useState("");
  const [loanById, setLoanById]: any = useState([]);
  const [formRender, setFormRender] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const submitionInProgress = useRef(false);
  const [formData, setFormData]: any = useState({});
  const navigate = useNavigate();
  const lang: string = window.localStorage.getItem("storedLang") ?? "";
  const { t } = useTranslation("loan-form");

  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor: any) =>
    augmentColor({ color: { main: mainColor } });
  const theme = createTheme({
    palette: {
      custom: createColor("#ffb619"),
    },
  });

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  useEffect(() => {
    setLoanId(loan_Id);
  }, [loan_Id]);

  useQuery({
    queryKey: ["id_loans", country, loan_Id],
    queryFn: () => {
      return client.get(`/v1/origination/loans/${loan_Id}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      setLoanById(parsedLoanDetails?.customer?.profile);
    },
    enabled: !!loan_Id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    let initialData: any = {};

    if (new_loan === "new") {
      setFormRender(true);
    } else {
      for (let i = 0; i < loanById?.length; i++) {
        initialData[loanById[i]?.name] = loanById[i];
        if (i === loanById?.length - 1) {
          setFormRender(true);
        }
      }
    }

    setFormData(initialData ?? {});
  }, [loanById, new_loan]);

  const handleAccordionChange =
    (panel: any) => (event: any, newExpanded: any) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (loan_Id) return;

    const source = {
      name: "source",
      value: "DSA",
      type: "selection",
      valid: true,
    };

    const sourceData = {
      name: "sourceData",
      value: user?.email,
      type: "email",
      valid: true,
    };

    const sourceDataId = {
      name: "sourceDataId",
      value: user?.email,
      type: "text",
      valid: true,
    };

    const referrerSource = {
      name: "referrerSource",
      value: "",
      type: "selection",
      valid: true,
    };

    const referrerData = {
      name: "referrerData",
      value: "",
      type: "selection",
      valid: true,
    };

    const referrerDataId = {
      name: "referrerDataId",
      value: "",
      type: "text",
      valid: true,
    };

    let data: any = {};

    if (isAccountManager) {
      source.value = "accountManager";
      sourceData.value = user?.email;
      sourceDataId.value = user?.id;
      data = {
        sourceDataId,
        sourceData,
        source,
      };
    }

    if (isFranchise) {
      source.value = "FRANCHISE";
      sourceData.value = user?.company?.name;
      sourceDataId.value = user?.company?.id;
      referrerSource.value = "Franchise";
      referrerData.value = user?.company?.name;
      referrerDataId.value = user?.company?.id;
      data = {
        sourceDataId,
        sourceData,
        source,
        referrerSource,
        referrerData,
        referrerDataId,
      };
    }

    if (isDsaAgent) {
      source.value = "DSA";
      sourceData.value = user?.email;
      sourceDataId.value = user?.id;
      data = {
        sourceDataId,
        sourceData,
        source,
      };
    }

    if (isDealerDsa) {
      source.value = "dealerDsa";
      sourceData.value = user?.email;
      sourceDataId.value = user?.id;
      data = {
        sourceDataId,
        sourceData,
        source,
      };
    }

    setFormData((state: any) => ({
      ...state,
      ...data,
    }));
  }, [
    isAccountManager,
    isDealerDsa,
    isDsaAgent,
    isFranchise,
    loan_Id,
    user?.company?.id,
    user?.company?.name,
    user?.email,
    user?.id,
  ]);

  useEffect(() => {
    const referrerDataId = {
      name: "referrerDataId",
      value: "",
      type: "text",
      valid: true,
    };

    let data: any = {};

    if (
      formData?.referrerSource?.value === "Franchise" &&
      formData?.referrerData?.value
    ) {
      const franchiseId = franchise?.result?.find(
        (item: any) => item?.name === formData?.referrerData?.value
      )?.id;
      if (franchiseId) {
        referrerDataId.value = franchiseId;
        data = {
          ...data,
          referrerDataId,
        };
      }
    }

    setFormData((state: any) => {
      if (formData?.referrerSource?.value === "DSA") {
        state["referrerDataId"] && delete state["referrerDataId"];
      }
      return {
        ...state,
        ...data,
      };
    });
  }, [
    formData?.referrerData?.value,
    formData?.referrerSource?.value,
    franchise?.result,
  ]);

  useEffect(() => {
    const loanPreferencesFields: any = [];

    productData?.config?.loanFields.forEach((item: any, index: any) => {
      if (item.name === "loanPreferences") {
        loanPreferencesFields.push(item.fields);
      }
    });

    const fieldWithDefaultVal = loanPreferencesFields
      ?.flat()
      .filter((field: any) => field.defaultValue);

    const initialDefaultValField = fieldWithDefaultVal?.map((field: any) => ({
      name: field.name,
      value: field.defaultValue,
      type: field.type,
      valid: true,
    }));

    initialDefaultValField.forEach((field: any) => {
      setLoanFields((state: any) => ({
        ...state,
        [field.name]: field,
      }));
    });
  }, [productData, loan_Id]);

  const setDefaultValues = (data = []) => {
    data.forEach((item: any) => {
      if (!item.visibility && item.defaultValue) {
        setFormData((state: any) => ({
          ...state,
          [item.name]: { name: item.name, value: item.defaultValue },
        }));
      }
      if (item.fields?.length) {
        setDefaultValues(item.fields);
      }
      if (item.when?.length) {
        setDefaultValues(item.when);
      }
      if (item.actions?.length) {
        setDefaultValues(item.actions);
      }
    });
  };

  useEffect(() => {
    if (productData?.config?.sections) {
      setDefaultValues(productData.config.sections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  const handleInputChange = async (e: any) => {
    const { name, value, type, label } = e.target;
    const valueObject = {
      name,
      value,
      type,
      label: label || e.label,
      valid: true,
    };

    setFormData((state: any) => {
      if (name === "source") {
        state["sourceData"] && delete state["sourceData"];
      }
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  // Check error for product sections
  const validateFormDataItem = (item: FieldItem): string | null => {
    if (item.type && item.required && !formData[item.name]?.value) {
      return `${item.label} is required`;
    }
    if (item.when?.length && (item.required || formData[item.name]?.value)) {
      const matchingWhen = item.when.find(
        (whenItem) => whenItem.value === formData[item.name]?.value
      );
      if (matchingWhen) {
        const error = validateFormDataSubfields(matchingWhen.fields);
        if (error) return error;
      }
    }
    return null;
  };

  const validateFormDataSubfields = (fields: FieldItem[]): string | null => {
    for (const item of fields) {
      const error = validateFormDataItem(item);
      if (error) return error;
    }
    return null;
  };

  const checkErrors = (data: FieldItem[] = []): string | null => {
    for (const item of data) {
      const error = validateFormDataItem(item);
      if (error) return error;
      if (item.fields?.length) {
        const subfieldError = validateFormDataSubfields(item.fields);
        if (subfieldError) return subfieldError;
      }
    }
    return null;
  };

  useEffect(() => {
    const id = fetchCountryId(country);
    id.then((result) => setCountryId(JSON.parse(result?.data)));
  }, [country]);

  useEffect(() => {
    if (countryId) {
      const getState = getStates(countryId?.id);
      countryId?.id &&
        getState.then((result) => setState(JSON.parse(result?.data)));
    }
  }, [countryId]);

  useEffect(() => {
    if (formData?.state?.value) {
      const selectedStateId = state?.states?.find(
        (item: any) => item.name === formData?.state?.value
      )?.id;
      selectedStateId &&
        getCities(selectedStateId).then((result) =>
          setCity(JSON.parse(result?.data))
        );
    }
  }, [formData?.state?.value, state?.states]);

  useEffect(() => {
    const getBank = getBanks(country);
    getBank.then((result) => setBanks(JSON.parse(result?.data)));
  }, [country]);

  useEffect(() => {
    const getCountry = getCountries();
    getCountry.then((result) => setCountries(JSON.parse(result?.data)));
  }, []);

  useEffect(() => {
    const about = getAboutUs(country);
    about.then((result) => setAboutUs(JSON.parse(result?.data)));
  }, [country]);

  useEffect(() => {
    const franchise = getFranchise(country);
    franchise.then((result) => setFranchise(JSON.parse(result?.data)));
  }, [country]);

  useEffect(() => {
    const product = getProduct(country);
    product.then((result) => setLoanProduct(JSON.parse(result.data)));
  }, [country]);

  useEffect(() => {
    setPageLoading(true);
    if (loanProduct?.result) {
      const loanProductId = loanProduct.result.find(
        (x: any) => x.name === "Decision Engine Loan"
      ).id;

      const product = getProductData(loanProductId);
      product.then((result) => {
        setProductData(JSON.parse(result.data));
        setPageLoading(false);
      });
    }
  }, [loanProduct]);

  const handleLoanFieldChange = async (e: any) => {
    const { name, value, type, label } = e.target;
    const valueObject = {
      name,
      value,
      type,
      label: label || e.label,
      valid: true,
    };

    setLoanFields((state: any) => {
      return {
        ...state,
        [name]: valueObject,
      };
    });
  };

  const addDirector = () => {
    if (directorCount === 3) return;
    setDirectorCount(directorCount + 1);
  };

  const validateDsasEmail = (hasRole: boolean) => {
    if (hasRole && formData?.referrerSource?.value === "DSA") {
      if (formData?.referrerData?.value === user?.email) {
        return "You cannot use your email, kindly provide another DSA email";
      }
    }
    return null;
  };

  const validateReferrerSource = (hasRole: boolean, currentFields: any[]) => {
    const referrerSourceFieldExists = currentFields.some(
      (field: any) => field.name === "referrerSource"
    );
    if (
      hasRole &&
      referrerSourceFieldExists &&
      !formData?.referrerSource?.value
    ) {
      return `Referrer source is required`;
    }
    return null;
  };

  const submit = async (e: any, isDraft = true) => {
    e.preventDefault();
    setAlert(null);

    const userId = user.id;

    const error =
      checkErrors(productData.config.sections) ??
      checkLoanFieldErrors(productData.config.loanFields) ??
      validateDsasEmail(isDealerDsa) ??
      validateDsasEmail(isDsaAgent);

    if (error && !isDraft) {
      setAlert(error);
      return;
    }

    if (loading || submitionInProgress.current) {
      return;
    }

    if (isDraft && !userId) {
      return;
    }

    submitionInProgress.current = true;

    !isDraft && setLoading(true);

    try {
      const validFormData: any = {};
      Object.keys(formData).forEach((item) => {
        if (
          formData[item]?.value !== undefined &&
          formData[item]?.value !== null
        ) {
          validFormData[item] = { ...formData[item] };
        }
        if (validFormData[item]?.type === "amount") {
          validFormData[item].value = validFormData[item]?.value?.replaceAll(
            ",",
            ""
          );
        }
      });
      Object.keys(loanFields).forEach((item) => {
        if (loanFields[item]?.type === "amount") {
          loanFields[item].value = loanFields[item]?.value?.replaceAll(",", "");
        }
      });
      const newFormData = {
        ...validFormData,
      };
      Object.keys(newFormData).forEach((item) => {
        if (
          newFormData[item]?.value === undefined ||
          newFormData[item]?.value === null
        ) {
          delete newFormData[item];
        }
      });

      const mappedLoanFields = Object.values(loanFields).map((field: any) => ({
        ...field,
        value:
          field.type === "checkbox"
            ? convertStringToBoolean(field.value)
            : field.value,
      }));

      const payload: any = {
        profile: Object.values(newFormData),
        loan_fields: mappedLoanFields,
        car_id: carId,
        user_id: user.id,
        product_id: productData.id,
        country: country,
        is_draft: isDraft,
        // utm_source: JSON.parse(localStorage.getItem("utm_source")),
        // utm_medium: JSON.parse(localStorage.getItem("utm_medium")),
        // utm_campaign: JSON.parse(localStorage.getItem("utm_campaign")),
        // utm_id: JSON.parse(localStorage.getItem("utm_id")),
        // utm_term: JSON.parse(localStorage.getItem("utm_term")),
        // utm_content: JSON.parse(localStorage.getItem("utm_content")),
      };
      const res: any = loanId
        ? await client.put(
            `v2/origination/loan/${loanId}?lang=${lang}`,
            payload
          )
        : await client.post(`v2/origination/loan?lang=${lang}`, payload);
      const parsedResponse = JSON.parse(res?.data);
      setLoanId(parsedResponse?.id);
      submitionInProgress.current = false;
      if (!isDraft) {
        navigate(
          `/applications/loan/confirmation?loan_id=${parsedResponse?.id}`
        );
      }
    } catch (error: any) {
      submitionInProgress.current = false;
      let errorMessage = JSON.parse(error?.response?.data);
      setLoading(false);
      setAlert(
        errorMessage.message || typeof errorMessage?.error === "string"
          ? errorMessage?.error
          : "Something went wrong."
      );
    }
  };

  // check error for loan fields
  const validateLoanFieldItem = (item: LoanFieldItem): string | null => {
    if (item.type && item.required && !loanFields[item.name]?.value) {
      return `${item.label} is required`;
    }
    if (item.shouldBe && item.shouldBe !== loanFields[item.name]?.value) {
      return `${item.label} should be ${item.shouldBe}`;
    }
    return null;
  };

  const validateLoanFieldSubfields = (
    fields: LoanFieldItem[]
  ): string | null => {
    for (const item of fields) {
      const error = validateLoanFieldItem(item);
      if (error) return error;
    }
    return null;
  };

  const checkLoanFieldErrors = (data: LoanFieldItem[] = []): string | null => {
    for (const item of data) {
      const error = validateLoanFieldItem(item);
      if (error) return error;
      if (item.fields?.length) {
        const subfieldError = validateLoanFieldSubfields(item.fields);
        if (subfieldError) return subfieldError;
      }
    }
    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {pageLoading ? (
          <TableContainer>
            <div className="filter-mobile-loader-wrapper">
              <Loader variant={"secondary"} />
            </div>
          </TableContainer>
        ) : (
          <CheckEligibilityFormContainer>
            <h2 className="top-header">{t("Loan Application")}</h2>
            <Box
              style={{
                backgroundColor: "#F8FAFC",
                marginBottom: "-64px",
                paddingBottom: "64px",
              }}
            >
              <Container maxWidth="md">
                {formRender && (
                  <form onSubmit={(e) => submit(e, false)}>
                    <Stack spacing={5}>
                      <Stack alignItems="center" sx={{ marginTop: 5 }}>
                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={!!alert}
                          autoHideDuration={5000}
                          onClose={() => setAlert("")}
                        >
                          <Alert severity="error" variant="filled">
                            {alert}
                          </Alert>
                        </Snackbar>

                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={!!successAlert}
                          autoHideDuration={5000}
                          onClose={() => setSuccessAlert("")}
                        >
                          <Alert severity="success" variant="filled">
                            {successAlert}
                          </Alert>
                        </Snackbar>
                      </Stack>
                      {
                        <>
                          {productData?.config?.sections?.map(
                            (item: any, index: any) => (
                              <Accordion
                                key={productData?.config?.sections?.indexOf(
                                  item
                                )}
                                expanded={expanded === index}
                                onChange={handleAccordionChange(index)}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    completed[index] && expanded !== index ? (
                                      <CheckCircleIcon
                                        sx={{ color: "#22C55E" }}
                                      />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )
                                  }
                                >
                                  <Typography
                                    variant="h6"
                                    fontWeight="600"
                                    textAlign="center"
                                    fontFamily="OutfitRegular"
                                  >
                                    {t(item.label)}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <InputGroup
                                    label={item.label}
                                    fields={item.fields}
                                    formData={formData}
                                    onBlur={submit}
                                    handleInputChange={handleInputChange}
                                    selectOptions={{
                                      ...selectOptions,
                                      state: state?.states,
                                      city: city?.cities,
                                      primaryBank: banks?.banks,
                                      source: aboutUs?.sources,
                                      sourceData:
                                        formData?.source?.value === "Bank"
                                          ? banks?.banks
                                          : franchise?.result,
                                      nationality: countries?.countries,
                                      citizenship: countries?.countries,
                                      referrerData:
                                        formData?.referrerSource?.value ===
                                        "Franchise"
                                          ? franchise?.result
                                          : [],
                                    }}
                                    addDirector={addDirector}
                                    directorCount={directorCount}
                                  />
                                  <Grid container spacing={2} marginTop="16px">
                                    <Grid item xs={12} sm={12} md={4}>
                                      <LoadingButton
                                        variant="contained"
                                        type="button"
                                        fullWidth
                                        color="custom"
                                        style={{
                                          fontWeight: "600",
                                        }}
                                        loading={loading}
                                        onClick={(e) => {
                                          const error =
                                            checkErrors(item.fields) ??
                                            validateDsasEmail(isDealerDsa) ??
                                            validateDsasEmail(isDsaAgent) ??
                                            validateReferrerSource(
                                              isAccountManager,
                                              item?.fields
                                            );
                                          if (error) {
                                            setAlert(error);
                                            setCompleted((current: any) => ({
                                              ...current,
                                              [index]: false,
                                            }));
                                            return;
                                          }
                                          setExpanded(index + 1);
                                          setCompleted((current: any) => ({
                                            ...current,
                                            [index]: true,
                                          }));
                                        }}
                                      >
                                        {t("Next")}
                                      </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <LoadingButton
                                        variant="contained"
                                        type="button"
                                        fullWidth
                                        color="custom"
                                        style={{
                                          fontWeight: "600",
                                        }}
                                        onClick={(e) => {
                                          const error =
                                            checkErrors(item.fields) ??
                                            validateDsasEmail(isDealerDsa) ??
                                            validateDsasEmail(isDsaAgent) ??
                                            validateReferrerSource(
                                              isAccountManager,
                                              item?.fields
                                            );
                                          if (error) {
                                            setAlert(error);
                                            setCompleted((current: any) => ({
                                              ...current,
                                              [index]: false,
                                            }));
                                            return;
                                          }
                                          setExpanded(index + 1);
                                          setCompleted((current: any) => ({
                                            ...current,
                                            [index]: true,
                                          }));
                                          navigate(
                                            `/applications/loans/${loanId}`
                                          );
                                        }}
                                      >
                                        {t("Save and Exit")}
                                      </LoadingButton>
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            )
                          )}
                          {productData?.config?.loanFields?.map(
                            (item: any, index: any) => (
                              <Accordion
                                key={
                                  productData?.config?.sections?.length + index
                                }
                                expanded={
                                  expanded ===
                                  productData?.config?.sections?.length + index
                                }
                                onChange={handleAccordionChange(
                                  productData?.config?.sections?.length + index
                                )}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    completed[
                                      productData?.config?.sections?.length +
                                        index
                                    ] &&
                                    expanded !==
                                      productData?.config?.sections?.length +
                                        index ? (
                                      <CheckCircleIcon
                                        sx={{ color: "#22C55E" }}
                                      />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )
                                  }
                                >
                                  <Typography
                                    variant="h6"
                                    fontWeight="600"
                                    textAlign="center"
                                    fontFamily="OutfitRegular"
                                  >
                                    {t(item.label)}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <InputGroup
                                    label={item.label}
                                    fields={item.fields}
                                    formData={loanFields}
                                    onBlur={submit}
                                    handleInputChange={handleLoanFieldChange}
                                    selectOptions={{
                                      ...selectOptions,
                                    }}
                                  />
                                  {index ===
                                  productData?.config?.loanFields?.length -
                                    1 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      marginTop="16px"
                                    >
                                      <Grid item xs={12} sm={12} md={4}>
                                        <LoadingButton
                                          variant="contained"
                                          type="button"
                                          fullWidth
                                          color="custom"
                                          style={{
                                            fontWeight: "600",
                                          }}
                                          loading={loading}
                                          disabled={
                                            !!checkLoanFieldErrors(item.fields)
                                          }
                                          onClick={(e) => {
                                            const error = checkLoanFieldErrors(
                                              item.fields
                                            );
                                            if (error) {
                                              setAlert(error);
                                              setCompleted((current: any) => ({
                                                ...current,
                                                [index]: false,
                                              }));
                                              return;
                                            }
                                            submit(e, false);
                                          }}
                                        >
                                          {t("Submit")}
                                        </LoadingButton>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      container
                                      spacing={2}
                                      marginTop="16px"
                                    >
                                      <Grid item xs={12} sm={12} md={4}>
                                        <LoadingButton
                                          variant="contained"
                                          type="button"
                                          fullWidth
                                          color="custom"
                                          style={{
                                            fontWeight: "600",
                                          }}
                                          loading={loading}
                                          onClick={(e) => {
                                            const error = checkLoanFieldErrors(
                                              item.fields
                                            );
                                            if (error) {
                                              setAlert(error);
                                              setCompleted((current: any) => ({
                                                ...current,
                                                [index]: false,
                                              }));
                                              return;
                                            }
                                            setExpanded(
                                              productData?.config?.sections
                                                ?.length +
                                                index +
                                                1
                                            );
                                            setCompleted((current: any) => ({
                                              ...current,
                                              [productData?.config?.sections
                                                ?.length + index]: true,
                                            }));
                                          }}
                                        >
                                          {t("Next")}
                                        </LoadingButton>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={4}>
                                        <LoadingButton
                                          variant="contained"
                                          type="button"
                                          fullWidth
                                          color="custom"
                                          style={{
                                            fontWeight: "600",
                                          }}
                                          onClick={(e) => {
                                            const error = checkErrors(
                                              item.fields
                                            );
                                            if (error) {
                                              setAlert(error);
                                              setCompleted((current: any) => ({
                                                ...current,
                                                [index]: false,
                                              }));
                                              return;
                                            }
                                            setExpanded(index + 1);
                                            setCompleted((current: any) => ({
                                              ...current,
                                              [index]: true,
                                            }));
                                            navigate(
                                              `/applications/loans/${loanId}`
                                            );
                                          }}
                                        >
                                          {t("Save and Exit")}
                                        </LoadingButton>
                                      </Grid>
                                    </Grid>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            )
                          )}
                        </>
                      }
                    </Stack>
                  </form>
                )}
              </Container>
            </Box>
          </CheckEligibilityFormContainer>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Loans;
