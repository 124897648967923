export enum LOAN_STATUS {
  APPLICATION_COMPLETED = "APPLICATION_COMPLETED",
  CONDITIONAL_OFFER_RECEIVED = "CONDITIONAL_OFFER_RECEIVED",
  SIGNED_LOAN_DOCUMENTS_SUBMITTED = "SIGNED_LOAN_DOCUMENTS_SUBMITTED",
  PARTIAL_EQUITY_PAID = "PARTIAL_EQUITY_PAID",
  EQUITY_PAID = "EQUITY_PAID",
  DISBURSED = "DISBURSED",
}

export const getOfferByColor = (status: string): string => {
  switch (status) {
    case "ACCEPTED":
      return "success";
    case "OFFER_REJECTED":
      return "error";
    case "PENDING":
      return "processing";
    default:
      return "";
  }
};

export const DISBURSED_LOAN_STATUSES: string[] = [
  LOAN_STATUS.SIGNED_LOAN_DOCUMENTS_SUBMITTED,
  LOAN_STATUS.PARTIAL_EQUITY_PAID,
  LOAN_STATUS.EQUITY_PAID,
  LOAN_STATUS.DISBURSED,
];
