import { IDealerLimitResult } from "@/interface/dealer";
import storage from "@/lib/storage";
import { LIMIT_REQUEST_STATUS } from "@/utils/finance-status";
import { useNavigate } from "react-router-dom";

const FinancingOptionsCard = ({
  text,
  body,
  link,
  dealerLimit,
}: {
  text: string;
  body: string;
  link: string;
  dealerLimit: IDealerLimitResult;
}) => {
  const navigate = useNavigate();
  const isAssumedUser = storage.getAssumedUser();

  return (
    <div className="bg-white rounded-lg w-full p-4 lg:h-[11rem] min-h-[13rem] h-auto border border-solid border-[#e5e7eb]">
      <div className="lg:mb-4 mb-2">
        <p className="capitalize font-bold text-[#30345E] text-base lg:text-lg custom-font-bold">
          {text}
        </p>
        <p className="text-sm lg:text-base text-[#30345E] leading-tight pt-2">
          {body}
        </p>
      </div>
      <button
        disabled={
          dealerLimit?.status !== LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED ||
          !!isAssumedUser
        }
        onClick={() => navigate(link)}
        className="flex items-center justify-center px-2 py-1 text-[#30345E] border border-solid border-[#30345E] rounded-full disabled:text-black/25 disabled:bg-black/5 disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:cursor-not-allowed"
      >
        <span className="text-sm font-bold tracking-[0.01563rem] capitalize">
          Apply now
        </span>
      </button>
    </div>
  );
};

export default FinancingOptionsCard;
