import getInitial from "@/utils/get-initial";
import { useTranslation } from "react-i18next";

export default function DealerName({
  props,
}: Readonly<{
  props: { dealerName: string; id: string; activeDate: string };
}>) {
  const { t } = useTranslation("dealer-gamification");

  function formatDate(inputDate: string) {
    const date = new Date(inputDate);
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  }

  return (
    <main className="flex justify-between items-center">
      <div className="flex items-center gap-2">
        {!!props.dealerName && (
          <>
            <div className="text-[#30345E] text-xs bg-[#CCDDFF] uppercase flex items-center justify-center w-[32px] h-[32px] font-medium rounded-full">
              {getInitial(props.dealerName ?? "") ?? "--"}
            </div>

            <div>
              <div className="text-base text-[#30345E] font-bold leading-tight capitalize">
                {props.dealerName ?? "--"}
              </div>
              <div className="text-[#6B7280] text-[10px] uppercase">
                {t("active-since")}:{" "}
                {props.activeDate ? formatDate(props.activeDate) : "--"}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="border-l border-solid border-[#E5E7EB] pl-3 py-2">
        <div className="text-sm text-[#6B7280]">
          ID:{" "}
          <span className="text-[#30345E] text-sm font-semibold">
            {props.id?.slice(0, 3)}...
          </span>
        </div>
      </div>
    </main>
  );
}
