import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImageLoader } from "@/utils/ImageLoader";
import { numberWithCommas } from "@/utils/commonFunctions";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import formatInt from "@/utils/format-int";
import { useTranslation } from "react-i18next";
import {
  ApplyLoanButton,
  CarTagsContainer,
  InventoryCalculateLoan,
  OutlineButton,
} from "@/layouts/application-layouts/styles";
import storage from "@l/storage";
import tw from "tailwind-styled-components";
import { Space, Tag, Typography, message } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import ListCarModal from "@/layouts/application-layouts/ListCarModal";
import DelistCarModal from "@/layouts/application-layouts/DelistCarModal";
import RequestInspectionModal from "@/layouts/application-layouts/RequestInspectionModal";
import CarModerationModal from "@/layouts/application-layouts/ModerationModal";
import { IVariant } from "@/types";

interface IInventoryGrid {
  variant: IVariant;
  loanId: string;
  partnerId: string;
  tabValue: string;
  delistedValue: string | number;
  productData: any;
  simulate: string;
  viewType: string;
  carsResult: any;
  carId: string;
  setCarId: React.Dispatch<React.SetStateAction<string>>;
}

const CardSection = tw.div``;

export default function InventoryCarGrid({
  variant,
  loanId,
  partnerId,
  tabValue,
  delistedValue,
  productData,
  simulate,
  viewType,
  carsResult,
  carId,
  setCarId,
}: Readonly<IInventoryGrid>) {
  const navigate = useNavigate();
  const { t } = useTranslation("inventory");
  const isAssumedUser = storage.getAssumedUser();
  const isFranchise = storage.utilities.isFranchise();
  const redirectPath = `/applications/loans/${loanId}`;
  const queryClient = useQueryClient();
  const { Text } = Typography;
  const [ellipsis] = useState(true);

  // states
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [listCar, setListCar] = useState(false);
  const [delistCar, setDelistCar] = useState(false);
  const [reqInspection, setReqInspection] = useState<boolean>(false);
  const [carDetails, setCarDetails]: any = useState({});
  const [moderateCar, setModerateCar] = useState(false);

  const checkMobileView = () => {
    const breakpoint: number = 1024;
    setIsMobileView(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  const handleNavigation = (path: string) => navigate(path);

  const navigateToInventory = (id: string) => {
    navigate(
      variant === "repo" ? `/inventory/${id}?repo=true` : `/inventory/${id}`
    );
  };

  const toOfferSimulator = (id: string) => {
    navigate(
      `/applications/offer-simulator?loan_id=${loanId}&car_id=${id}&partner_id=${partnerId}`
    );
  };

  const handleCarChange = async (carId: string) => {
    const payload: any = {
      id: loanId,
      car_id: carId,
    };

    try {
      const response = await client.put(
        productData === 2
          ? `v2/origination/loan/${loanId}`
          : `v1/origination/loan/${loanId}`,
        payload
      );
      if (response) {
        message.success("Car changed successfully");
        queryClient.invalidateQueries([
          "fetch_dig_offers",
          "loans_by_id",
          "fetch_uploaded_documents",
        ]);
        handleNavigation(redirectPath);
      }
    } catch (error: any) {
      message.error(
        JSON.parse(error?.response?.data)?.error ||
          JSON.parse(error?.response?.data)?.message
      );
    }
  };

  const toLoan = (id: string) => {
    if (variant === "loan-transfer") {
      navigate(`/applications/loan-transfer?car_id=${id}&form_type=new`);
    } else {
      navigate(`/applications/loans?car_id=${id}&form_type=new`);
    }
  };

  const handleAction = (variant: string, id: string) => {
    if (variant === "change-car") handleCarChange(id);
    else if (variant === "repo")
      handleNavigation(`/dealer/financing/guide?car_id=${id}`);
    else toLoan(id);
  };

  const showListCar = (data: string) => {
    setCarId(data);
    setListCar(true);
  };

  const showReqInspection = (data: any) => {
    setCarDetails(data);
    setReqInspection(true);
  };

  const showDelistCar = (data: any) => {
    setCarId(data);
    setDelistCar(true);
  };

  const showModerateCar = (data: any) => {
    setCarId(data);
    setModerateCar(true);
  };

  const renderDelistButton = (elm: any) => {
    return (
      <OutlineButton
        className="w-full"
        onClick={() => showDelistCar(elm?.id)}
        disabled={!!isAssumedUser}
      >
        {t("delist-car")}
      </OutlineButton>
    );
  };

  const renderListCarButton = (elm: any) => {
    return (
      <OutlineButton
        className="w-full"
        onClick={() => showListCar(elm?.id)}
        disabled={!!isAssumedUser || delistedValue === "delisted_by_autochek"}
      >
        {t("list-car")}
      </OutlineButton>
    );
  };

  const renderRequestInspection = (elm: any) => {
    return (
      <OutlineButton
        className="w-full"
        onClick={() => showReqInspection(elm)}
        disabled={!!isAssumedUser}
      >
        {t("request-inspection")}
      </OutlineButton>
    );
  };

  const inspectionButtonView = (elm: any) => {
    let inspectionButtonContent;

    if (elm?.hasInspection) {
      inspectionButtonContent = renderListCarButton(elm);
    } else if (elm?.hasInspectionRequest) {
      inspectionButtonContent = (
        <OutlineButton className="w-full" disabled>
          {t("pending-inspection")}
        </OutlineButton>
      );
    } else {
      inspectionButtonContent = renderRequestInspection(elm);
    }
    return inspectionButtonContent;
  };

  const renderApplyLoanAction = (data: any) => {
    return (
      <ApplyLoanButton
        className="w-full"
        onClick={() => {
          variant === "change-car" && simulate
            ? toOfferSimulator(data?.id)
            : handleAction(variant, data?.id);
        }}
        disabled={!!isAssumedUser}
      >
        {variant === "change-car" ? t("select-car") : t("apply-for-loan")}
      </ApplyLoanButton>
    );
  };

  const renderCarsButton = (elm: any) => {
    if (elm?.marketplaceVisible) {
      return renderDelistButton(elm);
    } else {
      return inspectionButtonView(elm);
    }
  };

  const renderUpdateDetailsButton = (data: any) => {
    return (
      <div
        className={`flex flex-row items-center ${buttonWidthContainer} gap-3`}
      >
        <OutlineButton
          className="w-full"
          onClick={() => showModerateCar(data?.id)}
          disabled={!!isAssumedUser}
        >
          {t("Update Details")}
        </OutlineButton>
      </div>
    );
  };

  const readyForListingActions = (data: any) => {
    return (
      <div
        className={`flex flex-row items-center ${buttonWidthContainer} gap-3`}
      >
        {renderListCarButton(data)}
        {renderApplyLoanAction(data)}
      </div>
    );
  };

  const underModerationActions = (data: any) => {
    return (
      <div
        className={`flex flex-row items-center ${buttonWidthContainer} gap-3`}
      >
        {renderApplyLoanAction(data)}
      </div>
    );
  };

  const listedActions = (data: any) => {
    return (
      <div
        className={`flex flex-row items-center ${buttonWidthContainer} gap-3`}
      >
        {renderDelistButton(data)}
        {renderApplyLoanAction(data)}
      </div>
    );
  };

  const renderTabFilterButtons = (data: any) => {
    switch (tabValue) {
      case "ready_for_listing":
      case "delisted":
        return readyForListingActions(data);
      case "under_moderation":
        return underModerationActions(data);
      case "listed":
        return listedActions(data);
      case "failed_moderations":
        return renderUpdateDetailsButton(data);
      default:
        return null;
    }
  };

  const isGridView = viewType === "GRID" || isMobileView || !isFranchise;

  const GridClass = isGridView
    ? "grid-cols-1 md:grid-cols-2 xl:grid-cols-3"
    : "grid-cols-1";
  const gridCardStyle = isGridView ? "flex-col" : "flex-row gap-4 p-3";
  const buttonWidthContainer = isGridView ? "w-full" : "w-[40%]";
  const contentDividerStyle = isGridView
    ? "flex-col"
    : "flex-row justify-between";
  const priceStyle = isGridView ? "text-right" : "text-left";
  const contentContainer = isGridView ? "p-3 md:pb-4" : "";
  const imageContainer = isGridView
    ? "rounded-t-lg w-full"
    : "rounded-lg w-[300px]";

  return (
    <main>
      <div className={`grid ${GridClass} gap-5 p-2.5 md:p-4`}>
        {carsResult?.carList?.map((elm: any) => (
          <div
            key={elm?.id}
            className={`${gridCardStyle} flex w-full border border-solid border-[#E5E7EB] rounded-lg`}
          >
            <div
              className={`relative ${imageContainer} h-[200px] md:h-[230px] overflow-hidden`}
            >
              <CardSection onClick={() => navigateToInventory(elm?.id)}>
                <img
                  src={ImageLoader({
                    src: `${elm?.imageUrl}`,
                    width: 400,
                    quality: 100,
                  })}
                  alt="Car Banner"
                  className={`object-cover w-full h-auto object-center`}
                />
              </CardSection>

              {variant === "inventory" && (
                <InventoryCalculateLoan>
                  <button
                    className="button-container z-10"
                    onClick={() =>
                      navigate(`/loan-calculator?car_id=${elm?.id}`)
                    }
                  >
                    <img
                      src="https://media.autochek.africa/file/publicAssets/Group-36536.svg"
                      alt="car-img"
                    />
                    <div className="name">{t("calculate-loan")}</div>
                    <img
                      src="https://media.autochek.africa/file/publicAssets/Line.svg"
                      alt="chevron-img"
                    />
                  </button>
                </InventoryCalculateLoan>
              )}
            </div>

            <div className="flex-1 w-full">
              <div
                className={`flex flex-col divide-y gap-4 w-full max-w-full h-full justify-center ${contentContainer}`}
              >
                <CardSection onClick={() => navigateToInventory(elm?.id)}>
                  <div className="text-xl md:text-2xl text-[#485164] font-outfit font-semibold mb-1">
                    {elm?.year
                      ? `${elm?.year} ${elm?.model?.make?.name || elm?.title} ${
                          elm?.model?.name || ""
                        } ${elm?.trim?.name || ""}`
                      : "---"}{" "}
                  </div>
                  <CarTagsContainer>
                    <Space size={[0, 8]} wrap>
                      <Tag color="#4E538A">
                        {elm?.sellingCondition &&
                          capitalFirstLetter(t(elm?.sellingCondition))}
                        {!elm?.sellingCondition && "---"}
                      </Tag>
                      <Tag color="#4E538A">
                        {numberWithCommas(elm?.mileage)}{" "}
                        {t(elm?.mileageUnit)?.toUpperCase()}
                      </Tag>
                      <Tag color="#4E538A">
                        {elm?.engineType &&
                          capitalFirstLetter(t(elm?.engineType)?.toUpperCase())}
                        {!elm?.engineType && "---"}
                      </Tag>
                    </Space>
                  </CarTagsContainer>
                </CardSection>

                <div className={`flex ${contentDividerStyle}`}>
                  <div>
                    {variant !== "repo" && (
                      <CardSection
                        className="flex items-center justify-between pt-3"
                        onClick={() => navigateToInventory(elm?.id)}
                      >
                        <div>
                          <div className="text-sm md:text-base font-outfit font-semibold text-[#485164]">
                            {t("franchise")}: {elm?.carManagerName || "---"}
                          </div>
                        </div>
                      </CardSection>
                    )}
                    <CardSection
                      className="flex items-center justify-between pt-3 pb-4"
                      onClick={() => navigateToInventory(elm?.id)}
                    >
                      <div>
                        <div className="text-sm md:text-base font-outfit font-semibold text-[#485164]">
                          {formatInt(elm?.marketplacePrice || 0, true)}
                        </div>
                        <Text
                          style={ellipsis ? { width: "150px" } : undefined}
                          className="text-xs text-[#a3a3a3] font-medium font-outfit"
                          ellipsis={
                            ellipsis ? { tooltip: `${elm?.address}` } : false
                          }
                        >
                          {elm?.address || "---"}
                        </Text>
                      </div>
                      <div>
                        <div
                          className={`text-base md:text-[22px] text-[#4e538a] font-outfitbold font-bold ${priceStyle}`}
                        >
                          {formatInt(elm?.installment || 0, true)} / Mo
                        </div>
                        <div className="text-[10px] md:text-[12.5px] text-[#a3a3a3] font-medium font-outfit">
                          {t("min-downpayment")}
                        </div>
                      </div>
                    </CardSection>
                  </div>

                  {[
                    "ready_for_listing",
                    "under_moderation",
                    "listed",
                    "failed_moderations",
                    "delisted",
                  ].includes(tabValue) && variant === "inventory" ? (
                    renderTabFilterButtons(elm)
                  ) : (
                    <div
                      className={`flex flex-row items-center ${buttonWidthContainer} gap-3`}
                    >
                      {["inventory", "repo"].includes(variant) &&
                        renderCarsButton(elm)}
                      {renderApplyLoanAction(elm)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ListCarModal
        isModalOpen={listCar}
        handleOk={() => setListCar(false)}
        handleCancel={() => setListCar(false)}
        carId={carId}
      />
      <DelistCarModal
        isModalOpen={delistCar}
        handleOk={() => setDelistCar(false)}
        handleCancel={() => setDelistCar(false)}
        carId={carId}
      />
      <RequestInspectionModal
        isModalOpen={reqInspection}
        handleOk={() => setReqInspection(false)}
        handleCancel={() => setReqInspection(false)}
        carData={carDetails}
      />
      <CarModerationModal
        isModalOpen={moderateCar}
        handleOk={() => setModerateCar(false)}
        handleCancel={() => setModerateCar(false)}
        carId={carId}
      />
    </main>
  );
}
