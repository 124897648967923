interface IStageCard {
  medal_url: string;
  dealer_stage: string;
  stage_desc: string;
}

const StageCard: React.FC<IStageCard> = ({
  medal_url,
  dealer_stage,
  stage_desc,
}) => {
  return (
    <div className="bg-white rounded-lg w-full p-4 min-h-[8.9375rem] h-auto md:h-[8rem] border border-solid border-[#e5e7eb]">
      <div>
        <img
          src={medal_url}
          className=" w-[2.875rem] h-[2.875rem]"
          alt="Medal"
        />
      </div>
      <div className="pt-2">
        <p className="uppercase font-outfitBold custom-font-bold font-bold text-[#30345E] text-sm">
          {dealer_stage}
        </p>
        <p className="text-sm text-[#6B7280]">{stage_desc}</p>
      </div>
    </div>
  );
};

export default StageCard;
