import { useEffect, useState } from "react";
import { CopyIcon, MailIcon, PhoneVariantIcon } from "@/assets/svgs";
import {
  fetchBanks,
  fetchUserById,
  initializePayment,
  updateLoanDetails,
} from "@/hooks/use-api";
import {
  FormDropdownStyle,
  InputFieldStyle,
  InputLabelStyle,
} from "@/layouts/application-layouts/styles";
import storage from "@/lib/storage";
import useLoansByIdStore from "@/states/loans-by-id";
import { IOption } from "@/types";
import getInitial from "@/utils/get-initial";
import { Autocomplete, FormControl, FormLabel, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Loader } from "../auth/styles/loader";
import useUserAccountStore from "@/states/user-account";
import { IPersistedJson, IUser } from "@/interface/upfront";
import { CheckOutlined } from "@ant-design/icons";

interface ISupportedBanks {
  label: string;
  value: string;
  id: string;
}

const SelectTextFieldStyle = {
  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: "#30345E",
    fontFamily: "OutfitRegular",
    height: "15px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
  },
};

const accountTypeOptions = [
  { label: "Savings", value: "Savings" },
  { label: "Current", value: "Current" },
];

const ActivateDirectDebit = () => {
  const navigate = useNavigate();
  const country = storage.getCountry();
  const queryParam = new URLSearchParams(window.location.search);
  const loanId = queryParam.get("loan_id") as string;
  const userId = queryParam.get("user_id") as string;
  const [supportedBanks, setSupportedBanks] = useState<ISupportedBanks[]>([]);
  const [selectedBank, setSelectedBank] = useState<string>("");
  const [selectedBankId, setSelectedBankId] = useState<string>("");
  const [selectedAccountNumber, setSelectedAccountNumber] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [linkGenerated, setLinkGenerated] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const [userById, setUserById] = useState<IUser>();
  const [selectedAccountType, setSelectedAccountType] = useState<string>("");
  const [selectedAccountName, setSelectedAccountName] = useState<string>("");
  const [storeData, setStoreData] = useState<IPersistedJson>();

  const { loansById, fetchLoansById } = useLoansByIdStore();
  const { userAccount, fetchUserAccounts } = useUserAccountStore();
  const profileObj = loansById?.customer?.profile;

  useEffect(() => {
    if (userId) fetchUserAccounts(userId);
  }, [fetchUserAccounts, userId]);

  useEffect(() => {
    if (loanId) fetchLoansById(loanId);
  }, [fetchLoansById, loanId]);

  const { isLoading: userDetailsLoading } = useQuery({
    queryKey: ["user_details", userId],
    queryFn: () => {
      return fetchUserById(userId);
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setUserById(parsedResponse);
    },
    enabled: !!country,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isLoading: banksLoading } = useQuery({
    queryKey: ["supported_banks", country],
    queryFn: () => {
      const params = new URLSearchParams({
        country,
      });
      return fetchBanks(params);
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      const filteredBanks = parsedResponse?.banks
        ?.filter((item: any) => item?.paymentEngineCode)
        ?.map((item: Record<string, string>) => {
          return {
            label: item.name,
            value: item.name,
            id: item.paymentEngineCode,
          };
        });
      setSupportedBanks(filteredBanks);
    },
    enabled: !!country,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (!profileObj || !supportedBanks) return;

    const accountName = profileObj?.find(
      (item) => item?.name === "accountHolderName"
    )?.value as string;
    const accountNumber = profileObj?.find(
      (item) => item?.name === "accountNumber"
    )?.value as string;
    const accountType = profileObj?.find((item) => item?.name === "accountType")
      ?.value as string;
    const primaryBank = profileObj.find((item) => item?.name === "primaryBank")
      ?.value as string;
    const matchingBank = supportedBanks.find(
      (item) => item.value === primaryBank
    );

    setSelectedAccountName(accountName);
    setSelectedAccountType(accountType);
    setSelectedAccountNumber(accountNumber);
    setSelectedBank(matchingBank?.value ?? "");
    setSelectedBankId(matchingBank?.id ?? "");
  }, [profileObj, supportedBanks]);

  const doesBankMatch = (): boolean => {
    const profileBankValue = profileObj?.find(
      (item) => item?.name === "primaryBank"
    )?.value as string;
    return supportedBanks.some((item) => item.value === profileBankValue);
  };

  const handleUpdateBankDetailsPayload = () => {
    const payload = {
      profile: [
        {
          name: "primaryBank",
          value: selectedBank,
          label: "Your bank",
          valid: true,
        },
        {
          name: "accountHolderName",
          value: selectedAccountName,
          type: "text",
          label: "Account holder name",
          valid: true,
        },
        {
          name: "accountNumber",
          value: selectedAccountNumber,
          type: "number",
          label: "Your account number",
          valid: true,
        },
        {
          name: "accountType",
          value: selectedAccountType,
          label: "Account type",
          valid: true,
        },
      ],
      isBankUpdateOnly: true,
    };
    return payload;
  };

  const handleGenerateLink = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const data1 = {
      loan_id: loanId,
      bank_code: selectedBankId || userAccount?.bankCode,
      account_number: selectedAccountNumber || userAccount?.accountNumber,
    };
    const data2 = handleUpdateBankDetailsPayload();

    try {
      const response = await initializePayment(data1);
      await updateLoanDetails(loanId, data2);
      const parsedResponse = JSON.parse(response.data);
      setStoreData(parsedResponse);
      setLinkGenerated(true);
      setTimeout(() => {
        setLinkGenerated(false);
      }, 5000);
      fetchUserAccounts(userId);
      fetchLoansById(loanId);
    } catch (error) {
      if (error instanceof AxiosError) {
        message.error(JSON.parse(error.response?.data).message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="flex flex-col gap-6 w-full max-w-full md:max-w-[75%] xl:max-w-[50%] my-0 mx-auto">
      <div className="flex items-start gap-5">
        <button onClick={() => navigate(-1)}>
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left-m.svg"
            alt="left-arrow"
            className="mt-1.5"
          />
        </button>
        <div>
          <h1 className="font-semibold font-outfitbold text-[#30345E] text-lg md:text-2xl">
            Activate direct debit
          </h1>
          <p className="text-sm font-normal text-[#64748B]">
            Use the page below to activate direct debit on this account
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-8 p-4 md:p-6 border border-solid border-[#E2E8F0] rounded-lg bg-white">
        {linkGenerated && (
          <div className="text-sm text-white font-semibold bg-[#22C55E] h-10 w-full rounded-md flex items-center justify-center">
            Link generated
          </div>
        )}

        <div className="border border-solid border-[#E5E7EB] rounded-lg bg-white overflow-hidden">
          {userDetailsLoading ? (
            <div className="flex items-center justify-center w-full min-h-[110px] h-auto">
              <Loader variant="secondary" />
            </div>
          ) : (
            <>
              <div className="flex flex-col md:flex-row gap-2 lg:items-center justify-between bg-[#FFBF421C] py-2 px-3 md:px-4">
                <p className="text-xs md:text-sm text-[#000000] font-medium">
                  Customer Details
                </p>
                <div className="hidden lg:block">
                  <p className="text-xs md:text-sm text-[#30345E] font-semibold w-fit bg-[#FFED8E] px-3 py-1 flex items-center rounded-full">
                    Loan stage: {loansById?.status.replaceAll("_", " ")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:items-center justify-between gap-1 p-3 md:p-4">
                <div className="flex items-center gap-2">
                  <div className="text-xs font-semibold text-[#30345E] w-10 h-10 rounded-full bg-[#CCDDFF] flex items-center justify-center">
                    {getInitial(`${userById?.firstname} ${userById?.lastname}`)}
                  </div>
                  <div>
                    <p className="text-sm text-[#202020] font-semibold">
                      {userById?.firstname
                        ? `${userById?.firstname} ${userById?.lastname}`
                        : "---"}
                    </p>
                    <p className="text-sm text-[#6B7280] font-medium flex items-center gap-1">
                      <MailIcon /> {userById?.email ?? "---"}
                    </p>
                  </div>
                </div>
                <p className="text-sm text-[#2563EB] font-medium flex items-center gap-1 ml-12">
                  <PhoneVariantIcon color="#2563EB" />{" "}
                  {userById?.primaryPhone ?? "---"}
                </p>
              </div>
            </>
          )}
        </div>

        {storeData && (
          <div className="flex flex-col gap-1.5">
            <div className="border border-solid border-[#E5E7EB] bg-[#F8FAFC] flex items-center justify-between px-3 py-3 rounded-sm">
              <p className="text-base text-[#202020] font-normal text-ellipsis whitespace-nowrap w-full max-w-[95%] overflow-hidden">
                {storeData?.redirectUrl}...
              </p>
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(storeData?.redirectUrl);
                  setCopiedLink(true);
                  setTimeout(() => {
                    setCopiedLink(false);
                  }, 1000);
                }}
              >
                {copiedLink ? <CheckOutlined /> : <CopyIcon />}
              </button>
            </div>
            <p className="font-normal text-black text-xs bg-[#FFBF420D] px-3 py-1 rounded-full">
              Note that an automatic email with the link generated has been sent
              to this customer.
            </p>
          </div>
        )}

        <form className="flex flex-col gap-6" onSubmit={handleGenerateLink}>
          <FormControl fullWidth>
            <FormLabel sx={InputLabelStyle}>Bank Account</FormLabel>
            <Autocomplete
              fullWidth
              id="supportedBanks"
              disablePortal
              loading={banksLoading}
              options={supportedBanks ?? []}
              onChange={(_, newValue) => {
                setSelectedBank(newValue?.value as string);
                setSelectedBankId(newValue?.id as string);
              }}
              value={
                supportedBanks?.find(
                  (option: IOption) => option?.value === selectedBank
                ) || null
              }
              getOptionLabel={(option: IOption) => option?.label || ""}
              isOptionEqualToValue={(option: IOption, value: IOption) =>
                option.value === value.value
              }
              sx={FormDropdownStyle}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select bank"
                  sx={SelectTextFieldStyle}
                />
              )}
            />
            {!selectedBankId && (
              <span className="text-red-600 text-sm">
                Your bank is not supported for direct debit, kindly select a new
                bank
              </span>
            )}
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={InputLabelStyle}>Account Number</FormLabel>
            <TextField
              id="accountNumber"
              fullWidth
              type="text"
              placeholder="Enter account number"
              sx={InputFieldStyle}
              inputProps={{
                maxLength: 15,
              }}
              onChange={(event) => {
                const numericValue = event.target.value.replace(/\D/g, "");
                setSelectedAccountNumber(numericValue);
              }}
              value={selectedAccountNumber}
              onBeforeInput={(event: any) => {
                if (!/^\d*$/.test(event.data)) {
                  event.preventDefault();
                }
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={InputLabelStyle}>Account Name</FormLabel>
            <TextField
              id="accountName"
              fullWidth
              type="text"
              placeholder="Enter account name"
              onChange={(e) => setSelectedAccountName(e.target.value)}
              value={selectedAccountName}
              sx={InputFieldStyle}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={InputLabelStyle}>Account Type</FormLabel>
            <Autocomplete
              fullWidth
              id="accountType"
              disablePortal
              options={accountTypeOptions ?? []}
              onChange={(_, newValue) => {
                setSelectedAccountType(newValue?.value as string);
              }}
              value={
                accountTypeOptions?.find(
                  (option: IOption) => option?.value === selectedAccountType
                ) || null
              }
              getOptionLabel={(option: IOption) => option?.label || ""}
              isOptionEqualToValue={(option: IOption, value: IOption) =>
                option.value === value.value
              }
              sx={FormDropdownStyle}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select account type"
                  sx={SelectTextFieldStyle}
                />
              )}
            />
          </FormControl>
          <button
            type="submit"
            disabled={
              !selectedBankId ||
              !selectedAccountNumber ||
              !selectedAccountName ||
              !selectedAccountType
            }
            className="text-sm font-semibold text-[#30345E] bg-[#FFB619] rounded-[60px] w-fit max-w-full md:min-w-[35%] h-12 px-6 mt-4 disabled:text-[#00000040] disabled:bg-[#d9d9d9] disabled:cursor-not-allowed"
          >
            {loading ? (
              <Loader variant="secondary" />
            ) : (
              <>
                {doesBankMatch()
                  ? "Resend Link"
                  : "Update Bank Details & Resend Link"}
              </>
            )}
          </button>
        </form>
      </div>
    </main>
  );
};

export default ActivateDirectDebit;
