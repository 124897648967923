import { Button, message, Modal } from "antd";
import {
  cancelStyle,
  deactivateConfirmStyle,
  paragraphStyle,
  titleStyle,
} from "@/layouts/application-layouts/styles";
import { deactivatePayment } from "@/hooks/use-api";
import { useState } from "react";
import { AxiosError } from "axios";
import { Loader } from "@/pages/auth/styles/loader";
import useUserAccountStore from "@/states/user-account";

interface IModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  userId: string;
}

const DeactivateDebitModal: React.FC<IModal> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  userId,
}) => {
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const { fetchUserAccounts } = useUserAccountStore();

  const handleDeactivateLink = async () => {
    setDeactivateLoading(true);
    const data = {
      user_id: userId,
    };
    try {
      const response = await deactivatePayment(data);
      if (response) {
        fetchUserAccounts(userId);
        handleOk();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        message.error(JSON.parse(error.response?.data).error);
      }
    } finally {
      setDeactivateLoading(false);
    }
  };

  return (
    <Modal
      title={<div style={titleStyle}>Deactivate direct debit</div>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      zIndex={1007}
      footer={[
        <Button key={1} onClick={handleCancel} style={cancelStyle}>
          Cancel
        </Button>,
        <Button
          onClick={handleDeactivateLink}
          key={2}
          type="primary"
          style={deactivateConfirmStyle}
        >
          {deactivateLoading ? (
            <Loader variant="secondary" />
          ) : (
            "Yes, Deactivate"
          )}
        </Button>,
      ]}
    >
      <p style={paragraphStyle}>
        Are you sure you want to deactivate the direct debit on this account?
        Also note that this action can not be reversed.
      </p>
    </Modal>
  );
};

export default DeactivateDebitModal;
