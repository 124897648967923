import { useEffect, useState } from "react";
import {
  AppTabContainer,
  CollapseContainer,
  CustomerCarInfo,
  CustomerDocument,
  CustomerLoanInfo,
  LoanDateHeader,
  TableContainer,
  CustomSelect,
  PartnerSelect,
  LoanDocsAccordion,
} from "@/layouts/application-layouts/styles";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Tabs, Collapse, Button, Select, Tag, message } from "antd";
import type { TabsProps } from "antd";
import styled from "styled-components";
import LoanSteps from "@/layouts/application-layouts/LoanSteps";
import ResumeLoanApplication from "@/layouts/application-layouts/ResumeApplication";
import { isMobile } from "react-device-detect";
import NoLoanOffers from "@/layouts/application-layouts/NoLoanOffer";
import { useQuery } from "@tanstack/react-query";
import storage from "@l/storage";
import client from "@/lib/axios-client";
import formatInt from "@/utils/format-int";
import moment from "moment";
import AllLoanOffers from "@/layouts/application-layouts/AllLoanOffers";
import OldLoansLayout from "@/layouts/application-layouts/OldLoans";
import AdditionalDocModal from "@/layouts/application-layouts/AdditionalDocModal";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import { useTranslation } from "react-i18next";
import SmartNotificationLayout from "@/components/SmartNotification";
import {
  ICustomsDocument,
  IEquityPayment,
  IFieldOption,
  ILoansById,
  IPartnerList,
  IPasswordField,
  IPpiReport,
  IRadioOption,
  IUploadedDocument,
  IValuationReport,
} from "@/interface/dealer";
import { Loader } from "../auth/styles/loader";
import UploadDocCard from "@/components/UploadDocCard";
import {
  fetchCarDocuments,
  fetchInspectionReport,
  fetchValuationReport,
} from "@/hooks/use-api";
import DownloadLoanStatement from "@/components/DownloadLoanStatement";
import { DISBURSED_LOAN_STATUSES } from "@/utils/loan-status";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CUSTOMS_ALLOWED_STATUSES: string[] = [
  "DOCUMENT_SUBMITTED",
  "REQUIRED_DOCUMENTS_UPLOAD_COMPLETED",
  "PPI_REQUESTED",
  "PPI_COMPLETED",
  "FINAL_OFFER_RECEIVED",
  "EQUITY_PAID",
  "CUSTOMS_DOCUMENT_UPLOAD",
  "CUSTOMS_DOCUMENT_APPROVED",
  "SIGNED_LOAN_DOCUMENTS_SUBMITTED",
  "FINAL_OFFER_RECEIVED",
];

const VALUATION_STATUSES: string[] = [
  "PPI_COMPLETED",
  "VALUATION_COMPLETED",
  "FINAL_OFFER_RECEIVED",
  "EQUITY_PAID",
  "CUSTOMS_DOCUMENT_UPLOAD",
  "CUSTOMS_DOCUMENT_APPROVED",
  "SIGNED_LOAN_DOCUMENTS_SUBMITTED",
  "FINAL_OFFER_RECEIVED",
];

const PPI_LOAN_STATUSES: string[] = [
  "DOCUMENT_SUBMITTED",
  "REQUIRED_DOCUMENTS_UPLOAD_COMPLETED",
  "PPI_REQUESTED",
  "PPI_COMPLETED",
  "FINAL_OFFER_RECEIVED",
  "SIGNED_LOAN_DOCUMENTS_SUBMITTED",
  "CUSTOMS_DOCUMENT_UPLOAD",
  "CUSTOMS_DOCUMENT_APPROVED",
];

const EQUITY_LOAN_STATUSES: string[] = [
  "SIGNED_LOAN_DOCUMENTS_SUBMITTED",
  "PARTIAL_EQUITY_PAID",
  "EQUITY_PAID",
];

const LoanDetails = () => {
  const { t } = useTranslation("application");
  const { t: t2 } = useTranslation("sales-funnel");
  const { id }: any = useParams();
  const country = storage.getCountry();
  const role = storage.getRole();
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const isAccountManager = storage.utilities.isAccountManager();
  const isFranchise = storage.utilities.isFranchise();
  const isDsaAgent = storage.utilities.isDsaAgent();
  const isDealerDsa = storage.utilities.isDealerDsa();
  const [loansById, setLoansById] = useState<any>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [docUploadOpen, setDocUploadOpen] = useState(false);
  const [conditionalOfferILoans, setConditionalOfferILoans] = useState([]);
  const [finalOfferILoans, setFinalOfferILoans] = useState([]);
  const [fetchStatusTrail, setFetchStatusTrail] = useState<any>({});
  const [documentList, setDocumentList] = useState([]);
  const [customsDocument, setCustomsDocument] = useState<ICustomsDocument[]>(
    []
  );
  const [ppiReport, setPpiReport] = useState<IPpiReport[]>([]);
  const [valuationReport, setValuationReport] = useState<IValuationReport[]>(
    []
  );
  const [userById, setUserById] = useState<any>({});
  const [partnersList, setPartnersList] = useState<IPartnerList[]>([]);
  const [getPartnerName, setGetPartnerName] = useState<string>("");
  const [ppiReportObj, setPpiReportObj] = useState({});
  const isAssumedUser = storage.getAssumedUser();
  const [getEquityReport, setGetEquityReport] = useState<IEquityPayment[]>([]);

  useQuery({
    queryKey: ["fetch_status_trail", id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: id,
      });
      return client.get(`/v2/origination/${id}/status-trail`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResult = JSON.parse(res?.data);
      setFetchStatusTrail(parsedResult);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    document.title = `Dealer Plus | Applications [${role}]`;
  }, [role]);

  const showDocUpload = () => {
    setDocUploadOpen(true);
  };

  const handleOpenDoc = () => {
    setDocUploadOpen(false);
  };

  const handleCloseDoc = () => {
    setDocUploadOpen(false);
  };

  const handleSelectInspection = (value: string) => {
    switch (value) {
      case "request_ppi":
        navigate("/applications/request-inspection", {
          state: {
            carId: loansById?.carId,
            loanId: id,
          },
        });
        break;
      case "upload_equity":
        navigate(
          `/applications/equity-payment?loanId=${id}&userId=${loansById?.customer?.userId}`
        );
        break;
      case "upload_custom_document":
        navigate(
          `/applications/custom-document?carId=${loansById?.carId}&loanId=${id}`
        );
        break;
      case "valuation_report":
        navigate("/applications/valuation-report", { state: { loanId: id } });
        break;
      case "request_ppi_franchophone":
        navigate(
          `/applications/ppi-request?loanId=${id}&condition=${loansById?.car?.sellingCondition}`,
          {
            state: {
              loanId: id,
              carSellingCondition: loansById?.car?.sellingCondition,
            },
          }
        );
        break;
      case "upload_car_document":
        navigate(
          `/applications/car-tracker?loanId=${id}&carId=${loansById?.carId}`
        );
        break;
    }
  };

  const NGOptions = [
    {
      value: "upload_custom_document",
      label: "Customs Document",
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        CUSTOMS_ALLOWED_STATUSES.includes(item?.statusName)
      )?.completed,
    },
    {
      value: "request_ppi",
      label: "Request PPI",
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        PPI_LOAN_STATUSES.includes(item?.statusName)
      )?.completed,
    },
    {
      value: "upload_equity",
      label: "Upload Equity",
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        EQUITY_LOAN_STATUSES.includes(item?.statusName)
      )?.completed,
    },
  ];

  const KEOptions = [
    {
      value: "valuation_report",
      label: "Valuation Payment",
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        VALUATION_STATUSES.includes(item?.statusName)
      )?.completed,
    },
    {
      value: "request_ppi",
      label: "Request PPI",
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        PPI_LOAN_STATUSES.includes(item?.statusName)
      )?.completed,
    },
    {
      value: "upload_equity",
      label: "Upload Equity",
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        EQUITY_LOAN_STATUSES.includes(item?.statusName)
      )?.completed,
    },
  ];

  const GHOptions = [
    {
      value: "request_ppi",
      label: t2("Request PPI"),
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        PPI_LOAN_STATUSES.includes(item?.statusName)
      )?.completed,
    },
    {
      value: "upload_equity",
      label: t2("Upload Equity"),
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        EQUITY_LOAN_STATUSES.includes(item?.statusName)
      )?.completed,
    },
  ];

  const OthersOptions = [
    {
      value: "request_ppi_franchophone",
      label: t2("Request PPI"),
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        PPI_LOAN_STATUSES.includes(item?.statusName)
      )?.completed,
    },
    {
      value: "upload_equity",
      label: t2("Upload Equity"),
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        EQUITY_LOAN_STATUSES.includes(item?.statusName)
      )?.completed,
    },
  ];

  const WhitelabelOptions = [
    {
      value: "upload_car_document",
      label: "Car Documents",
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        CUSTOMS_ALLOWED_STATUSES.includes(item?.statusName)
      )?.completed,
    },
  ];

  const CashLoanOptions = [
    {
      value: "upload_custom_document",
      label: "Customs Document",
      disabled: !fetchStatusTrail?.statusTree?.find((item: any) =>
        CUSTOMS_ALLOWED_STATUSES.includes(item?.statusName)
      )?.completed,
    },
  ];

  const getInspectionOptions = () => {
    let inspectionOptions = [];

    if (loansById?.productType === "Decision Engine Loan") {
      switch (country) {
        case "NG":
          inspectionOptions = NGOptions;
          break;
        case "KE":
          inspectionOptions = KEOptions;
          break;
        case "GH":
        case "UG":
          inspectionOptions = GHOptions;
          break;
        case "CI":
          inspectionOptions = OthersOptions;
          break;
        default:
          inspectionOptions = NGOptions;
          break;
      }
    } else if (loansById?.productType === "Logbook Finance") {
      return CashLoanOptions;
    } else {
      return WhitelabelOptions;
    }

    return inspectionOptions;
  };

  const renderCarPrice = () => {
    if (
      ["Decision Engine Loan", "Dealer WhiteLabel"].includes(
        loansById?.productType
      )
    ) {
      return (
        <div>
          {t("car-price")}: {formatInt(loansById?.car?.marketplacePrice, true)}
        </div>
      );
    } else if (loansById?.productType === "Logbook Finance") {
      return (
        <div>
          {t("loan-value")}: {formatInt(loansById?.car?.loanValue, true)}
        </div>
      );
    } else {
      return "---";
    }
  };

  const getLeadOwnerInfo = () => {
    if (loansById?.leadOwner?.firstname) {
      return `${capitalFirstLetter(
        loansById?.leadOwner?.firstname
      )} ${capitalFirstLetter(loansById?.leadOwner?.lastname)}`;
    } else if (loansById?.leadManager?.name) {
      return capitalFirstLetter(loansById?.leadManager?.name);
    }
    return "---";
  };

  const renderInspectionOptions = () => {
    const allowedForDecisionEngine =
      ["NG", "GH", "KE", "UG", "CI", "SN"].includes(country) &&
      loansById?.productType === "Decision Engine Loan";

    const allowedForWhitelabel =
      ["NG", "KE"].includes(country) &&
      loansById?.productType === "Dealer WhiteLabel";

    const allowedForCashLoans =
      ["NG"].includes(country) &&
      loansById?.productType === "Logbook Finance" &&
      loansById?.carId;

    if (
      allowedForDecisionEngine ||
      allowedForWhitelabel ||
      allowedForCashLoans
    ) {
      return (
        <div className="flex items-center mb-3 gap-1.5 bg-white p-3 rounded-md">
          <div className="flex-1">
            <div className="text-sm font-medium">
              {loansById?.productType === "Decision Engine Loan"
                ? t2("book-inspections-note")
                : "Add car documents, and tracker information on behalf of your customer"}
            </div>
          </div>
          <CustomSelect>
            <Select
              className="w-[125px]"
              placeholder={t2("Select task")}
              onChange={handleSelectInspection}
              disabled={!!loansById?.salesOppId?.trim()}
              options={getInspectionOptions()}
            />
          </CustomSelect>
        </div>
      );
    }
    return null;
  };

  const { isFetching: loansByIdLoading } = useQuery({
    queryKey: ["loans_by_id", country, id],
    queryFn: () => {
      return client.get(`/v1/origination/loans/${id}`);
    },
    onSuccess: (res: any) => {
      const parsedLoanDetails = JSON.parse(res?.data);
      const conditionalOfferI = parsedLoanDetails?.offers?.filter(function (
        item: any
      ) {
        return item?.offerType === "CONDITIONAL_OFFER";
      });
      const finalOfferI = parsedLoanDetails?.offers?.filter(function (
        item: any
      ) {
        return item?.offerType === "FINAL_OFFER";
      });

      setConditionalOfferILoans(conditionalOfferI);
      setFinalOfferILoans(finalOfferI);
      setLoansById(parsedLoanDetails);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { isFetching: loadUploadedDocs } = useQuery({
    queryKey: ["fetch_uploaded_documents", id],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v2/origination/loan/document-config/${id}`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedUploadedDocuments = JSON.parse(res?.data);
      const fieldWithDocuments = parsedUploadedDocuments?.documents?.find(
        (item: { label: string }) => item.label.startsWith("Upload Documents")
      );
      setUploadedDocuments(fieldWithDocuments?.fields);
    },
    enabled: !!id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["sales_ops"],
    queryFn: () => {
      const params = new URLSearchParams({});
      return client.get(`/v1/user/${loansById?.salesOppId?.trim()}`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedResponse: any = JSON.parse(res?.data);
      setUserById(parsedResponse);
    },
    enabled: !!loansById?.salesOppId?.trim(),
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["document_list", id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: id,
      });
      return client.get(`/v1/origination/documents`, {
        params,
      });
    },
    onSuccess: (res: any) => {
      const parsedDocumentList = JSON.parse(res?.data);
      setDocumentList(parsedDocumentList?.documents);
    },
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["customs_document", country, loansById?.carId],
    queryFn: () => {
      const params = new URLSearchParams({
        country,
        carId: loansById?.carId,
      });
      return fetchCarDocuments(params);
    },
    onSuccess: (res: any) => {
      const parsedData = JSON.parse(res?.data);
      setCustomsDocument(parsedData.customsDocuments);
    },
    enabled: !!loansById?.carId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["inspection_report", loansById?.carId],
    queryFn: () => {
      const params = new URLSearchParams({
        id: loansById?.carId,
        type: "warranty",
      });
      return fetchInspectionReport(params, loansById?.carId);
    },
    onSuccess: (res: any) => {
      const parsedResponse: any = JSON.parse(res?.data);
      setPpiReportObj(parsedResponse);
      setPpiReport([parsedResponse]);
    },
    enabled: !!loansById?.carId,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["valuation_proof", id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: id,
      });
      return fetchValuationReport(params, id);
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setValuationReport([parsedResponse]);
    },
    enabled: country === "KE",
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useQuery({
    queryKey: ["equity_payment", id],
    queryFn: () => {
      const params = new URLSearchParams({
        loan_id: id,
      });
      return client.get(`/v2/origination/equity`, { params });
    },
    onSuccess: (res: any) => {
      const parsedResponse = JSON.parse(res?.data);
      setGetEquityReport(parsedResponse?.equities);
    },
    enabled: !!id,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleDocNestedFields = (
    nestedFields: IUploadedDocument[],
    option: IUploadedDocument,
    listedDocs: any[],
    seenDocs: Record<string, boolean>
  ) => {
    nestedFields.forEach((field) => {
      if (field.type === "file" && field.url) {
        listedDocs.push({
          label: field.label,
          name: field.name,
          status: option.status,
          fileUrl: field.url ?? "",
          type: field.type,
          group: option.group,
          required: option.required,
          reason: option.reason,
          templateUrl: option?.templateUrl ?? "",
        });
        seenDocs[option.group ?? ""] = true;
      }
    });
  };

  const handleDocOptions = (option: any, listedDocs: any, seenDocs: any) => {
    if (["file", "fileSelection"].includes(option.type) && option.visibility) {
      listedDocs.push({
        label: option.label,
        name: option.name,
        status: option.status,
        fileUrl: option.url,
        type: option.type,
        group: option.group,
        required: option.required,
        reason: option.reason,
        templateUrl: option?.templateUrl ?? "",
      });
      seenDocs[option.group ?? ""] = true;

      if (option.type === "fileSelection" && option.when) {
        option.when.forEach((nestedOption: any) => {
          if (nestedOption.fields) {
            handleDocNestedFields(
              nestedOption.fields,
              option,
              listedDocs,
              seenDocs
            );
          }
        });
      }
    }
  };

  const handleDocumentFields = (
    data: IUploadedDocument[]
  ): IUploadedDocument[] => {
    const listedDocs: IUploadedDocument[] = [];
    const seenDocs: Record<string, boolean> = {};

    data.forEach((option) => {
      handleDocOptions(option, listedDocs, seenDocs);
    });

    return listedDocs;
  };

  const handlePasswordFieldOptions = (
    fields: IFieldOption[],
    option: IUploadedDocument,
    passwords: IPasswordField[]
  ) => {
    fields.forEach((field) => {
      if (field.type === "password") {
        passwords.push({
          name: field.name,
          group: option.group,
          label: field.label || "",
          value: field.value,
          password: field.value ?? "",
        });
      }
    });
  };

  const handlePasswordWhenOptions = (
    whenOptions: IRadioOption[],
    option: IUploadedDocument,
    passwords: IPasswordField[]
  ) => {
    whenOptions.forEach((whenOption) => {
      handlePasswordFieldOptions(whenOption.fields, option, passwords);
    });
  };

  const handlePasswordFields = (
    data: IUploadedDocument[]
  ): IPasswordField[] => {
    const passwords: IPasswordField[] = [];

    data.forEach((option) => {
      if (option.type === "radio" && option.when) {
        handlePasswordWhenOptions(option.when, option, passwords);
      }
    });

    return passwords;
  };

  const handleMergedDocuments = (
    data1: IUploadedDocument[],
    data2: IPasswordField[]
  ) => {
    const mergedData: IUploadedDocument[] = [];

    data1.forEach((document: IUploadedDocument) => {
      const matchingPassword = data2.find((password: IPasswordField) =>
        password.name.startsWith(document?.group ?? "")
      );

      mergedData.push({
        ...document,
        ...(matchingPassword && { password: matchingPassword.value }),
      });
    });

    return mergedData;
  };

  const uploadedDocsData = handleDocumentFields(uploadedDocuments);
  const passwordFieldData = handlePasswordFields(uploadedDocuments);
  const uploadedDocumentFields = handleMergedDocuments(
    uploadedDocsData,
    passwordFieldData
  );

  useEffect(() => {
    setGetPartnerName(partnersList[0]?.value);
  }, [partnersList]);

  const handlePartnerChange = (value: string) => {
    setGetPartnerName(value);
  };

  const handleGetAllPartnersWithFinalOffer = (data: ILoansById) => {
    const finalOfferPartners: { label: string; value: string }[] = [];

    const filterFinalOffersWithLoanDocs =
      data?.offers?.filter(
        (document) =>
          document?.offerType === "FINAL_OFFER" &&
          document?.loanDocuments?.length > 0
      ) ?? [];

    const onlyPartnerList = filterFinalOffersWithLoanDocs?.map((item: any) => ({
      label: item?.partner?.name ?? "",
      value: item?.partner?.name ?? "",
    }));

    finalOfferPartners?.push(...onlyPartnerList);

    setPartnersList(finalOfferPartners);
  };

  useEffect(() => {
    handleGetAllPartnersWithFinalOffer(loansById);
  }, [loansById]);

  const loanDocumentList = (data: ILoansById) => {
    const newLoanDocuments = [];

    const filterFinalOffers =
      data?.offers?.filter(
        (document) =>
          document?.offerType === "FINAL_OFFER" &&
          document?.partner?.name === getPartnerName
      ) ?? [];

    for (const item of filterFinalOffers) {
      const loanDocumentWithPartner = item?.loanDocuments?.map((doc: any) => ({
        ...doc,
        partner: item?.partner?.name,
        offerId: item?.id,
      }));
      newLoanDocuments?.push(...loanDocumentWithPartner);
    }

    return newLoanDocuments;
  };

  const renderUploadedDocuments = () => {
    const reorderDocuments = (documents: IUploadedDocument[]): any[] => {
      return documents.sort((a, b) => {
        if (a.fileUrl && !b.fileUrl) return -1;
        if (!a.fileUrl && b.fileUrl) return 1;
        return 0;
      });
    };

    const requiredFields = reorderDocuments(uploadedDocumentFields)?.filter(
      (item: any) => item.required
    );

    const optionalFields = reorderDocuments(uploadedDocumentFields)?.filter(
      (item: any) => !item.required
    );

    const finalOfferWithLoanDocs = loansById?.offers?.filter(
      (document: any) =>
        document?.offerType === "FINAL_OFFER" &&
        document?.loanDocuments &&
        document?.loanDocuments?.length > 0
    );

    return (
      <LoanDocsAccordion>
        <Collapse
          accordion
          defaultActiveKey={["1"]}
          expandIconPosition="end"
          bordered={false}
          className="site-collapse-custom-collapse"
          style={{ background: "white" }}
        >
          <Panel
            header={
              <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                Required documents ({requiredFields?.length})
              </div>
            }
            key="1"
            className="site-collapse-custom-panel"
          >
            <UploadDocCard
              docuFields={requiredFields}
              id={id}
              documentList={documentList}
              loansById={loansById}
              loanDocs={[]}
              loanRequiredDocument={uploadedDocuments}
              loanCustomsDoc={[]}
              loanPpiDoc={[]}
              loanValuationDoc={[]}
              equityDoc={[]}
              type="required"
            />
          </Panel>
          <Panel
            header={
              <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                Optional documents ({optionalFields?.length})
              </div>
            }
            key="2"
            className="site-collapse-custom-panel"
          >
            <UploadDocCard
              docuFields={optionalFields}
              id={id}
              documentList={documentList}
              loansById={loansById}
              loanDocs={[]}
              loanRequiredDocument={uploadedDocuments}
              loanCustomsDoc={[]}
              loanPpiDoc={[]}
              loanValuationDoc={[]}
              equityDoc={[]}
              type="optional"
            />
          </Panel>
          {finalOfferWithLoanDocs?.length > 0 && (
            <Panel
              header={
                <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                  Loan documents ({loanDocumentList(loansById)?.length})
                </div>
              }
              key="3"
              className="site-collapse-custom-panel"
            >
              <div className="flex flex-col gap-5">
                <PartnerSelect className="flex justify-start md:justify-end">
                  <Select
                    className="w-[200px]"
                    placeholder={t2("Select bank partner")}
                    onChange={handlePartnerChange}
                    value={getPartnerName}
                    disabled={!!loansById?.salesOppId?.trim()}
                    options={partnersList}
                  />
                </PartnerSelect>

                {loanDocumentList(loansById)?.length > 0 ? (
                  <UploadDocCard
                    docuFields={[]}
                    id={id}
                    documentList={[]}
                    loansById={loansById}
                    loanDocs={loanDocumentList(loansById)}
                    loanRequiredDocument={[]}
                    loanCustomsDoc={[]}
                    loanPpiDoc={[]}
                    loanValuationDoc={[]}
                    equityDoc={[]}
                    type="loan"
                  />
                ) : (
                  <div className="bg-white min-h-[200px] flex items-center justify-center">
                    <img
                      src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                      alt="empty-page"
                    />
                  </div>
                )}
              </div>
            </Panel>
          )}

          {loansById?.productType !== "Dealer WhiteLabel" && (
            <>
              {customsDocument?.length > 0 && (
                <Panel
                  header={
                    <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                      Customs documents ({customsDocument?.length})
                    </div>
                  }
                  key="4"
                  className="site-collapse-custom-panel"
                >
                  <UploadDocCard
                    docuFields={[]}
                    id={id}
                    documentList={[]}
                    loansById={loansById}
                    loanDocs={[]}
                    loanRequiredDocument={[]}
                    loanCustomsDoc={customsDocument}
                    loanPpiDoc={[]}
                    loanValuationDoc={[]}
                    equityDoc={[]}
                    type="customs"
                  />
                </Panel>
              )}
              {Object.keys(ppiReportObj)?.length > 0 && (
                <Panel
                  header={
                    <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                      PPI documents ({ppiReport?.length})
                    </div>
                  }
                  key="5"
                  className="site-collapse-custom-panel"
                >
                  <UploadDocCard
                    docuFields={[]}
                    id={id}
                    documentList={[]}
                    loansById={loansById}
                    loanDocs={[]}
                    loanRequiredDocument={[]}
                    loanCustomsDoc={[]}
                    loanPpiDoc={ppiReport}
                    loanValuationDoc={[]}
                    equityDoc={[]}
                    type="ppi"
                  />
                </Panel>
              )}
              {valuationReport?.length > 0 && (
                <Panel
                  header={
                    <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                      Valuation documents ({valuationReport?.length})
                    </div>
                  }
                  key="6"
                  className="site-collapse-custom-panel"
                >
                  <UploadDocCard
                    docuFields={[]}
                    id={id}
                    documentList={[]}
                    loansById={loansById}
                    loanDocs={[]}
                    loanRequiredDocument={[]}
                    loanCustomsDoc={[]}
                    loanPpiDoc={[]}
                    loanValuationDoc={valuationReport}
                    equityDoc={[]}
                    type="valuation"
                  />
                </Panel>
              )}
              {getEquityReport?.length > 0 && (
                <Panel
                  header={
                    <div className="text-sm md:text-[15px] text-[#30345e] font-semibold">
                      Upfront payment documents ({getEquityReport?.length})
                    </div>
                  }
                  key="7"
                  className="site-collapse-custom-panel"
                >
                  <UploadDocCard
                    docuFields={[]}
                    id={id}
                    documentList={[]}
                    loansById={loansById}
                    loanDocs={[]}
                    loanRequiredDocument={[]}
                    loanCustomsDoc={[]}
                    loanPpiDoc={[]}
                    loanValuationDoc={[]}
                    equityDoc={getEquityReport}
                    type="equity"
                  />
                </Panel>
              )}
            </>
          )}
        </Collapse>
      </LoanDocsAccordion>
    );
  };

  const childrenComponent = () => {
    if (
      loansById?.status === "APPLICATION_COMPLETED" &&
      loansById?.offers?.length === 0
    ) {
      return <NoLoanOffers />;
    } else if (loansById?.status === "APPLICATION_NOT_COMPLETED") {
      return <ResumeLoanApplication loansById={loansById} />;
    } else {
      return (
        <AllLoanOffers
          id={id}
          loansById={loansById}
          initialOffer={conditionalOfferILoans}
          finalOffer={finalOfferILoans}
        />
      );
    }
  };

  const toUploadDocuments = () => {
    navigate(`/applications/upload-documents?loan_id=${id}`);
  };

  const renderUploadDocument = () => {
    return (
      <button
        onClick={toUploadDocuments}
        className="w-fit px-4 py-1.5 bg-[#ffb619] text-[#30345e] hover:text-[#30345e] text-sm font-bold rounded-[60px] border-none disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
        disabled={!!loansById?.salesOppId?.trim() || !!isAssumedUser}
        style={{ height: "fit-content" }}
      >
        {t("upload-documents")}
      </button>
    );
  };

  useEffect(() => {
    if (
      (!isAccountManager && loansById?.source === "accountManager") ||
      (!isFranchise && loansById?.source === "FRANCHISE") ||
      (!isDsaAgent && loansById?.source === "DSA") ||
      (!isDealerDsa && loansById?.source === "dealerDsa")
    ) {
      message.error("Unauthorized: Role not supported");
      navigate(`/`, { replace: true });
    }
  }, [
    isAccountManager,
    isDealerDsa,
    isDsaAgent,
    isFranchise,
    loansById?.source,
    navigate,
  ]);

  if (loansByIdLoading || loadUploadedDocs) {
    return (
      <TableContainer>
        <div className="filter-mobile-loader-wrapper">
          <Loader variant={"secondary"} />
        </div>
      </TableContainer>
    );
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t(`loan-details`),
      children: childrenComponent(),
    },
    {
      key: "2",
      label: t(`application-stage`),
      children: <LoanSteps loansById={loansById} id={id} />,
    },
    // {
    //   key: "3",
    //   label: "Fulfillment stage",
    //   children: <LoanFulfillment loansById={loansById} id={id} />,
    // },
  ];

  const LOAN_STATUSES = [
    "APPLICATION_COMPLETED",
    "FINAL_OFFER_RECEIVED",
    "CONDITIONAL_OFFER_RECEIVED",
  ];

  const renderDownloadLoanStatement = () => {
    if (DISBURSED_LOAN_STATUSES.includes(loansById?.status)) {
      if (isAccountManager || isFranchise) {
        return (
          <div className="flex items-center mb-3 gap-1.5 bg-white px-3 py-4 rounded-md">
            <p className="text-sm md:text-base font-semibold flex-1">
              Download Loan Statement
            </p>
            <DownloadLoanStatement loanId={id} />
          </div>
        );
      }
    }
  };

  return (
    <Main>
      <div className="flex items-center justify-between bg-white px-[12px] py-[16px] md:p-[16px] rounded-lg border-b-[1px] border-[#e5e7eb]">
        <button
          className="flex items-center gap-2 font-semibold text-[#30345e] text-[16px] md:text-[20px]"
          onClick={() => navigate(-1)}
        >
          <img
            src="https://media.autochek.africa/file/publicAssets/arrow-narrow-left.svg"
            alt="arrow-back"
          />{" "}
          <div className="name">
            {`${loansById?.firstName} ${loansById?.lastName}'s`}{" "}
            {t("loan-offer")}
          </div>
        </button>
      </div>

      {LOAN_STATUSES?.includes(loansById?.status as string) && (
        <LoanDateHeader>
          <SmartNotificationLayout status={loansById?.status} />
        </LoanDateHeader>
      )}

      <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-2 bg-white p-4 border border-solid border-[#e5e7eb] rounded-lg">
        <div className="text-sm md:text-base text-[#30345e] font-semibold">
          {t("date-created")}:{" "}
          {loansById?.createdAt
            ? moment(loansById?.createdAt).format("DD-MM-YYYY, h:mm a")
            : "---"}{" "}
        </div>
        {loansById?.salesOppId?.trim() && (
          <div>
            <div className="mb-1 flex justify-start font-semibold">
              <Tag color="#30345e">{t2("Assigned to Sales Ops")}</Tag>
            </div>
            <div className="text-sm md:text-base text-[#30345e] font-semibold">
              Sales Ops:{" "}
              <span className="text-sm md:text-base text-[#30345e] font-semibold">
                {userById?.firstname} {userById?.lastname}
              </span>
            </div>
          </div>
        )}
      </div>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={7}>
          {isMobile ? (
            <CollapseContainer>
              {renderInspectionOptions()}
              {renderDownloadLoanStatement()}

              <Collapse
                defaultActiveKey={["0"]}
                bordered={false}
                expandIconPosition="end"
              >
                <Panel header={t("car-info")} key="1">
                  <CustomerCarInfo>
                    <div className="car-info">
                      <div style={{ marginBottom: "10px" }}>
                        <img
                          src={
                            loansById?.car?.imageUrl ||
                            `https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg`
                          }
                          alt="car"
                          width="404px"
                          height="246px"
                          style={{ borderRadius: "7px", objectFit: "contain" }}
                        />
                      </div>
                      <div>
                        <div className="detail">
                          <div>{t("car-name")}:</div>
                          <div>
                            {loansById?.car?.model?.make?.name
                              ? `${loansById?.car?.model?.make?.name} ${loansById?.car?.model?.name} ${loansById?.car?.year}`
                              : "---"}
                          </div>
                        </div>
                        <div className="detail">
                          <div>{t("vin")}:</div>
                          <div>{loansById?.car?.vin || "---"}</div>
                        </div>
                        <div className="detail">
                          <div>{t("rating")}:</div>
                          <div>
                            {loansById?.car?.gradeScore?.toFixed(1) || "---"}
                          </div>
                        </div>
                        <div className="detail">{renderCarPrice()}</div>
                      </div>
                    </div>
                  </CustomerCarInfo>
                </Panel>
                <Panel header={t("customer-info")} key="2">
                  <CustomerCarInfo>
                    <div className="customer-info-container">
                      <div>
                        <ul>
                          <li>
                            {t("name")}:{" "}
                            {loansById?.firstName
                              ? `${loansById?.firstName} ${loansById?.lastName}`
                              : "---"}
                          </li>
                          <li>
                            {t("phone")}: {loansById?.phone || "---"}
                          </li>
                        </ul>
                      </div>
                      <div>
                        <a
                          aria-label="Chat on WhatsApp"
                          href={`https://wa.me/${loansById?.phone}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="https://media.autochek.africa/file/publicAssets/logos_whatsapp.svg"
                            alt="chat on whatsapp"
                            style={{ textAlign: "center", margin: "0 auto" }}
                          />
                        </a>
                        <div className="whatsapp">{t("send-a-message")}</div>
                      </div>
                    </div>
                  </CustomerCarInfo>
                </Panel>
                {isAccountManager && (
                  <Panel header={t("application-info")} key="3">
                    <CustomerCarInfo>
                      <div className="customer-info-container">
                        <ul>
                          <li>
                            {t("lead-manager")}: {getLeadOwnerInfo()}
                          </li>
                          <li>
                            {t("assessor")}:{" "}
                            {loansById?.assignedAssessorEmail || "---"}
                          </li>
                        </ul>
                      </div>
                    </CustomerCarInfo>
                  </Panel>
                )}
              </Collapse>
            </CollapseContainer>
          ) : (
            <>
              {renderInspectionOptions()}
              {renderDownloadLoanStatement()}

              <CustomerCarInfo>
                <div className="car-info">
                  <div style={{ marginBottom: "10px" }}>
                    <img
                      src={
                        loansById?.car?.imageUrl ||
                        `https://ik.imagekit.io/eo0k4ohmt/Marketplace/Pages/Cars_for_cash/car-placeholder.svg`
                      }
                      alt="car"
                      width="404px"
                      height="246px"
                      className={
                        !loansById?.car?.imageUrl
                          ? "object-contain p-[40px]"
                          : ""
                      }
                      style={{
                        borderRadius: "7px",
                        width: "100%",
                        height: "246px",
                      }}
                    />
                  </div>
                  <div className="detail">
                    <div>{t("car-name")}</div>
                    <div>
                      {loansById?.car?.model?.make?.name
                        ? `${loansById?.car?.model?.make?.name} ${loansById?.car?.model?.name} ${loansById?.car?.year}`
                        : "---"}
                    </div>
                  </div>
                  <div className="detail">
                    <div>{t("vin")}</div>
                    <div>{loansById?.car?.vin || "---"}</div>
                  </div>
                  <div className="detail">
                    <div>{t("rating")}</div>
                    <div>{loansById?.car?.gradeScore?.toFixed(1) || "---"}</div>
                  </div>
                </div>
                <div className="car-price">{renderCarPrice()}</div>
                <div className="customer-info">{t("customer-info")}:</div>
                <div className="customer-info-container">
                  <div>
                    <ul>
                      <li>
                        {t("name")}:{" "}
                        {`${loansById?.firstName} ${loansById?.lastName}`}
                      </li>
                      <li>
                        {t("phone")}: {loansById?.phone || "---"}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <a
                      aria-label="Chat on WhatsApp"
                      href={`https://wa.me/${loansById?.phone}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://media.autochek.africa/file/publicAssets/logos_whatsapp.svg"
                        alt="chat on whatsapp"
                        style={{ textAlign: "center", margin: "0 auto" }}
                      />
                    </a>
                    <div className="whatsapp">{t("send-a-message")}</div>
                  </div>
                </div>
                {isAccountManager && (
                  <div>
                    <div className="customer-info">
                      {t("application-info")}:
                    </div>
                    <div className="customer-info-container">
                      <div>
                        <ul>
                          <li>
                            {t("lead-manager")}: {getLeadOwnerInfo()}
                          </li>
                          <li>
                            {t("assessor")}:{" "}
                            {loansById?.assignedAssessorEmail || "---"}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </CustomerCarInfo>
            </>
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={17}>
          <CustomerLoanInfo>
            <AppTabContainer
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              {loansById?.version === 2 ? (
                <Tabs
                  defaultActiveKey={"1"}
                  items={items}
                  tabBarExtraContent={
                    loansById?.productType === "Decision Engine Loan" && (
                      <Button
                        onClick={() =>
                          navigate(`/applications/car-list?loan_id=${id}`)
                        }
                        className="change-car"
                        disabled={
                          !!loansById?.salesOppId?.trim() || !!isAssumedUser
                        }
                      >
                        {t("change-car")}
                      </Button>
                    )
                  }
                />
              ) : (
                <OldLoansLayout loansById={loansById} id={id} />
              )}
            </AppTabContainer>
          </CustomerLoanInfo>

          <CustomerDocument>
            <div className="border-[1px] border-[rgb(229, 231, 235)] px-[0px] md:px-[12px] py-[16px] rounded">
              <header>
                <div className="customer-header">{t("customer-documents")}</div>
                {loansById?.status === "CONDITIONAL_OFFER_RECEIVED" &&
                  renderUploadDocument()}

                <button
                  onClick={showDocUpload}
                  className="hidden px-4 py-1.5 bg-[#ffb619] text-[#30345e] text-sm font-bold rounded-[60px] w-fit border-none disabled:border disabled:border-solid disabled:border-[#d9d9d9] disabled:text-[#00000040] disabled:bg-[#0000000a] disabled:cursor-not-allowed"
                  disabled={
                    !!loansById?.salesOppId?.trim() ||
                    documentList?.length === 0 ||
                    !!isAssumedUser
                  }
                  style={{ height: "fit-content" }}
                >
                  {isMobile
                    ? t("upload-additional-docs")
                    : t("upload-additional-document")}
                </button>
              </header>
              <div>
                {uploadedDocumentFields?.length === 0 ? (
                  <div className="min-h-[200px] flex items-center justify-center">
                    <img
                      src="https://media.autochek.africa/file/publicAssets/Frame-1.svg"
                      alt="empty-page"
                    />
                  </div>
                ) : (
                  <div className="">{renderUploadedDocuments()}</div>
                )}
              </div>
            </div>
          </CustomerDocument>
        </Col>
      </Row>

      <AdditionalDocModal
        handleOk={handleOpenDoc}
        handleCancel={handleCloseDoc}
        isModalOpen={docUploadOpen}
        id={id}
        customerDocs={uploadedDocuments}
      />
    </Main>
  );
};

export default LoanDetails;
