const handleDownloadBase64toPdf = (url: string) => {
  // Replace with your actual Base64 PDF string
  const base64PDF = url; // Example Base64 string
  const pdfBlob = base64ToBlob(base64PDF, "application/pdf");

  // Create a download link
  const blobUrl = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = "document.pdf"; // Name for the downloaded file
  link.click();

  // Cleanup: revoke the object URL after the download
  URL.revokeObjectURL(blobUrl);
};

const base64ToBlob = (base64: string, mimeType: string): Blob => {
  const binaryString = Uint8Array.from(atobSafe(base64), (char) =>
    char.charCodeAt(0)
  );
  return new Blob([binaryString], { type: mimeType });
};

const atobSafe = (data: string): string => {
  if (typeof window !== "undefined" && window.atob) {
    return window.atob(data);
  } else {
    // Use Buffer in Node.js
    return Buffer.from(data, "base64").toString("binary");
  }
};

export default handleDownloadBase64toPdf;
