import InputConfig from "@/components/formElements/mui-form/input-config";
import { FormSectionProps } from "@/components/formElements/mui-form/types";
import useCarFormStore from "@/states/create-car";
import { getSection } from "./helper";
import SectionWrapper from "./section-wrapper";

const CarFeatureSection: React.FC<FormSectionProps> = ({
  config,
  data,
  methods,
  dependencies,
}) => {
  const sectionStep = 4;

  const { currentStep, completedSteps, setAlert } = useCarFormStore();
  if (currentStep !== sectionStep) return null;

  const section = getSection("carFeatures", config);
  const isCompleted = completedSteps.includes(sectionStep);

  const featuresItem = config?.sections
    .find((section) => section.name === "carFeatures")
    ?.items.find((item) => item.name === "features");

  if (featuresItem) {
    featuresItem.subItems = data.features;
  }

  return (
    <SectionWrapper step={sectionStep} section={section} backable>
      <InputConfig
        step={sectionStep}
        section={section}
        setAlert={setAlert}
        data={data}
        methods={methods}
        dependencies={dependencies}
        isCompleted={isCompleted}
      />
    </SectionWrapper>
  );
};

export default CarFeatureSection;
