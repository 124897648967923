import create from "zustand";
import { fetchUserAccount } from "@/hooks/use-api";
import { IUserAccount } from "@/types";

interface UserAccountStore {
  userAccountLoading: boolean;
  userAccount: IUserAccount | undefined;
  fetchUserAccounts: (id: string) => Promise<void>;
}

const useUserAccountStore = create<UserAccountStore>((set) => ({
  userAccountLoading: false,
  userAccount: undefined,
  fetchUserAccounts: async (userId: string) => {
    try {
      set({ userAccountLoading: true });
      const response = await fetchUserAccount(userId);
      set({
        userAccount: JSON.parse(response?.data),
        userAccountLoading: false,
      });
    } catch (err: any) {
      set({ userAccountLoading: false });
    }
  },
}));

export default useUserAccountStore;
