import { Button, Modal } from "antd";
import {
  cancelStyle,
  confirmStyle,
  paragraphStyle,
  titleStyle,
} from "@/layouts/application-layouts/styles";
import { useNavigate } from "react-router-dom";

interface IModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  loanId: string;
  userId: string;
}

const EquityApprovalModal: React.FC<IModal> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  loanId,
  userId,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      title={<div style={titleStyle}>Customer Direct Debit Authorization</div>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      zIndex={1007}
      footer={[
        <Button key={1} onClick={handleCancel} style={cancelStyle}>
          Cancel
        </Button>,
        <Button
          onClick={() =>
            navigate(
              `/applications/direct-debit?loan_id=${loanId}&user_id=${userId}`
            )
          }
          key={2}
          type="primary"
          style={confirmStyle}
        >
          Proceed
        </Button>,
      ]}
    >
      <p style={paragraphStyle}>
        You're about to activate a direct debit authorization for this customer.
        By confirming, you&apos;re allowing automatic payments to be deducted
        from the customer&apos;s account as agreed.
      </p>
    </Modal>
  );
};

export default EquityApprovalModal;
